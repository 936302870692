@import '~shared-modules/styles/constants';

.container {
  user-select: none;
  padding: 12px 10px;
  border-radius: 10px;
  background-color: var(--color-bg-card);
  color: var(--color-text-lightgray);
}

.disabled {
  cursor: default;

  &:hover {
    background-color: var(--color-bg-card);
  }
}

.tag {
  padding: 0 10px;
}

.informationArea {
  width: 100%;
  height: 215px;
}

.buttonArea {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  .button {
    margin: 0 10px;

    i {
      font-size: 18px;
      padding-right: 5px;
    }
  }
}

.columns {
  display: flex;
  height: 100%;
  width: 570px;
  padding-left: 10px;
}

.meter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountInfoDisabled {
  font-weight: bold;
  align-items: center;
  display: flex;
  width: 55%;
  margin: auto;
  line-height: 25px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10px;
  width: 40%;
}

.sub {
  padding: 0 10px;
  width: 30%;
}

.upperContainer {
  height: 200px;
}

.leftAlignRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0;
}

.rightAlignRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 0;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
}

.margin {
  font-weight: 900;
  font-size: 20px;
  line-height: 31px;
}

.symbol {
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
}

.leftAlignRowSub {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0 2px;
}

.rightAlignRowSub {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2px 0 6px;
}

.labelSub {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

.priceSub,
.marginSub {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
}

.symbolSub {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

.link {
  font-weight: bold;
  color: $webDarkWhite;
  text-decoration: underline;
  cursor: pointer;
  position: relative;

  &:hover {
    color: var(--color-text-normal);
  }
}

.badgeContainer {
  position: relative;
  height: 100%;
  user-select: none;

  .badge {
    display: flex;
    align-items: center;
    position: absolute;
    top: -5px;
    left: calc(50% - 19px);
    border-radius: 8px;
    padding: 0 6px;
    background-color: var(--color-badge-background);
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    height: 14px;
    color: var(--color-text-normal);
    width: 38px;
  }
}
