.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(90vh - var(--app-header-height));
  width: 100%;
}

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.chartArea {
  width: 100%;
}

.footerArea {
  padding-top: 10px;
}
