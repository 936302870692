.tabs {
  background-color: var(--color-table-header);
}

.headerRow {
  display: flex;
  align-items: center;
  margin: 2rem 4rem 2rem 0;
  width: 100%;
}

.card {
  border-radius: 10px;
  display: inline-block;
  padding: 1.4rem 2rem;
  font-weight: 300;
  background: #2a2a2a;
}

.title {
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
}

.portfolios {
  grid-gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.over {
  height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.emptyDataMock {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 54px;
}

.emptyDataTitle {
  font-weight: bold;
}
