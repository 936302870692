@import '~shared-modules/styles/constants';

@mixin disabled {
  cursor: default;
  opacity: 0.6;
  background-color: #313131;

  div {
    color: #ccc !important;
    font-weight: 300;
  }

  &:hover,
  &:focus {
    background-color: #313131;
  }
}

.container {
  display: flex;
  width: max-content;
  cursor: default;
  border-radius: 4px;
  background-color: #313131;
  user-select: none !important;

  &:focus:not(&.disabled),
  &:hover:not(&.disabled) {
    background-color: var(--color-hover);
  }

  .first {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .buttonWrapper {
    user-select: none;

    &.selected {
      &:focus,
      &:hover {
        background-color: #313131;
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 12px;
      cursor: pointer;
      background-color: #313131;
      outline: none;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 21px;
      color: #ccc;

      &.selected {
        color: #313131;
        background-color: $webWhite;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 21px;
      }

      &:focus:not(.selected),
      &:hover:not(.selected) {
        background-color: var(--color-hover);
      }

      &.disabled {
        @include disabled;
      }

      &.isVisuallyDisabled {
        @include disabled;

        cursor: pointer;
      }
    }
  }
}

.selectorsWithLock {
  display: flex;
  align-items: center;

  .lockIcon {
    margin-right: 9px;
    font-size: 20px;
    color: var(--color-text-gray);
  }
}
