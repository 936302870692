@import '~shared-modules/styles/constants';

.wrapper {
  font-family: var(--font-family-ascii);
  color: $webWhite;
  font-weight: 800;
  font-size: 11px;
  text-align: right;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.label {
  font-family: var(--font-family-default);
  color: var(--color-text-gray);
  padding-right: 4px;
}
