@import '~shared-modules/styles/constants';

.title {
  margin-top: 8px;
  font-size: 13px;
  font-weight: bold;
  color: $webWhite;
}

.text {
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.5;
  color: $webWhite;
}

.openAgreementButton {
  margin: 24px auto 0;
}

.actionButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 312px;
  margin: 32px auto 8px;
}

.iframe {
  background-color: $webDarkWhite;
  border: 1px solid $webWarmGrey;
}

.acceptAgreementButton {
  margin: 16px auto;
}
