@import '~shared-modules/styles/constants';

.wrapper {
  // background-color: $webBackgroundBlack;
  // height: 100%;
}

.inputWrapper {
  position: relative;
  width: 100%; // override auto
}

.input {
  height: 32px;
}

.inputChanged {
  background-color: $webDarkOrange;
}

.inputControlBlock {
  height: 16px;

  &.inputControlBlockDecrement {
    top: 16px;
  }
}

.inputIconArrow {
  font-size: 15px;

  &.inputIconArrowIncrement {
    margin-top: 3px;
  }

  &.inputIconArrowDecrement {
    margin-top: -3px;
  }
}

.inputPips {
  width: 36px;
  height: 32px;
}

.inputerrorTooltip {
  position: absolute !important;
  top: 32px !important;
  left: 0 !important;
}

.counnterWrapper {
  display: flex;
  align-items: center;
}

.counnterLabeledSwitchWrapper {
  margin-right: 20px;
}

.counterVauleLabel {
  margin-right: 4px;
}
