$input-padding: 10px 10px;

.container {
  position: relative;

  .input {
    position: relative;
    height: 80px;
    padding: $input-padding;
    background-color: var(--color-bg-card);
    border: 1px solid var(--color-bg-card);
    border-radius: var(--border-radius-default);

    &:focus {
      padding: $input-padding;
      border: 1px solid var(--color-border-focus);
      outline: none;
    }
  }

  .count {
    position: absolute;
    right: 0;
    padding: 5px;
  }
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: var(--color-text-normal);
}
