.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    min-height: 40px;
    background-color: var(--color-table-header);

    .link {
      padding: 0 10px;
    }
  }

  .contentContainer {
    height: 100%;
    padding: 20px;

    .content {
      padding: 20px;
      height: 100%;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 60px;
    background-color: #2d2d2d;

    .button {
      margin-right: 10px;
    }
  }
}
