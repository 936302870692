@import '~shared-modules/styles/constants';

@keyframes disappearing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.downArrow {
  position: relative;
  right: -5px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transform: rotate(315deg);
  animation: disappearing 2s;
}

.upArrow {
  position: relative;
  right: -5px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transform: rotate(45deg);
  animation: disappearing 2s;
}

.differentPriceLabel {
  font-size: 11px;
  color: $webGreyBlue;
}

.topArrowLabel {
  position: absolute;
  top: -4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 17px;
}

.priceValue {
  display: inline-block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
}

.highlightFont {
  font-size: 24px;
  font-weight: bold;
}
