.container {
  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-normal);
  }

  .progress {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;

    .dot {
      padding: 0 3px;
      font-size: 10px;
      color: #ccc;
    }

    .active {
      color: var(--color-text-primary);
    }
  }
}
