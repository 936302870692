.content {
  position: relative;
  display: flex;
  flex-direction: column;
}

.message {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: var(--color-text-normal);
}

.buttonArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 10px;
  width: 100%;
}

.button {
  width: 47%;
}
