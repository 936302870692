@import './variable';

.container {
  position: relative;
  display: grid;
  grid-template-areas: 'side-menu content';
  grid-template-columns: $side-menu-width 1fr;
  height: 100%;
  color: var(--color-text-normal);
  background-color: var(--color-background);
}

.sideMenu {
  grid-area: side-menu;
  overflow: hidden;
  border-right: 1px solid var(--color-border-normal);
  width: 100%;
  height: 100%;
}

.content {
  grid-area: content;
  position: relative;
  height: 100%;
  width: 100%;
}
