.container {
  user-select: none;

  .icon {
    cursor: pointer;
    color: var(--color-text-normal);

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
      color: #fff;
    }
  }
}
