.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-text-normal);

  .title {
    height: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }

  .icon {
    padding: 10px 0;
  }

  .buttonArea {
    padding: 20px;
  }
}
