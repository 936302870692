@import '~shared-modules/styles/constants';

.row {
  display: grid;
  grid-template-columns: 1.5fr repeat(6, 1fr);
  align-items: center;
  justify-items: center;
  height: 40px;
  user-select: none;
  background-color: $webBackgroundBlack;
  border-bottom: 1px solid $webPureBlack;
  outline: none;

  &:hover {
    background-color: $webDarkBlueGrey;
  }
}

.cellItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  font-size: 11px;

  &.isClosed {
    color: $webWarmGrey;
  }
}

.brandCell {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: bold;

  &.isClosed {
    color: $webWarmGrey;
  }
}
