@import '~shared-modules/styles/constants';

@keyframes disappearing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 2px;
}

.right {
  justify-content: flex-end;
  padding-right: 2px;
}

.labelContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 11px;
  padding-bottom: 11px;
}

.label {
  font-size: 11px;
  color: $webGreyBlue;
}

.valueContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.downArrow {
  position: absolute;
  top: -17px;
  right: -12px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transform: rotate(315deg);
  animation: disappearing 2s;
}

.upArrow {
  position: absolute;
  top: -17px;
  right: -12px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transform: rotate(45deg);
  animation: disappearing 2s;
}
