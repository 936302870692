@import '~shared-modules/styles/constants';

@keyframes disappearing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.priceValue {
  position: relative;
  display: inline-block;
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
}

.topArrowLabel {
  top: -17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 11px;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.isReversed {
    align-items: flex-end;

    .topArrowLabel {
      width: 80%;
    }

    .priceValue {
      margin-right: 17px;
    }
  }
}

.downArrow {
  position: absolute;
  top: -17px;
  right: -17px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transform: rotate(315deg);
  animation: disappearing 2s;
}

.upArrow {
  position: absolute;
  top: -17px;
  right: -17px;
  width: 17px;
  height: 17px;
  opacity: 0;
  transform: rotate(45deg);
  animation: disappearing 2s;
}

.differentPriceLabel {
  margin-bottom: 11px;
  font-size: 11px;
  color: $webGreyBlue;
}

.calculatedDifference {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 14px;
  font-size: 11px;
  color: $webWhite;
  background-color: $webGrey;
  border-radius: 8px;
}
