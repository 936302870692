.container {
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  padding: 8px 0 32px;
  min-height: 200px;
}

.image {
  height: 312px;
  width: 584px;
}

.description {
  padding: 0 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text-normal);
}
