@import '~shared-modules/styles/constants';


.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 45px;

  .dot {
    padding: 0 3px;
    font-size: 10px;
    color: #ccc;
  }

  .active {
    padding: 0 3px;
    font-size: 10px;
    color: var(--color-text-primary);
  }
}

.modalComponent {
  padding: 15px 10px;
  margin: 0 auto;
  min-height: 662px;
  height: auto;

  .leftComponent {
    margin: 0 auto;
    padding-top: 50px;
    width: 45%;
    float: left;
    text-align: center;

    .titleArea {
      margin: auto;
      font-style: normal;
      height: 100px;
    }

    .title {
      font-weight: 300;
      font-size: 12px;
      line-height: 9px;
      color: #dcdcdc;
      padding: 5px 0 20px;
    }

    .mainTitle {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      padding-left: 15px;
    }

    .subTitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
      color: #dcdcdc;
    }

    .topTitle {
      line-height: 48px;
      padding-top: 50px;
      color: #fff;
      font-weight: 600;
      font-size: 32px;
    }

    .imageArea {
      width: 465px;
      min-height: 220px;

      .img {
        max-width: 340px;
        max-height: 250px;
      }

      .imgSmall {
        padding-top: 40px;
        padding-right: 5px;
        max-width: 236px;
        max-height: 169px;
      }
    }

    .buttonArea {
      width: 65%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      margin-top: 60px;

      .button {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .topButton {
        margin-top: 25px;
      }
    }
  }

  .rightComponent {
    width: 55%;
    padding-right: 45px;
    padding-left: 55px;
    float: right;

    .contentsArea {
      padding-top: 60px;
    }
  }
}



