@import '~shared-modules/styles/constants';

@mixin cellContainer {
  align-items: center;
  border-right: 1px solid $webPureBlack;
  color: $webWhite;
  display: flex !important; // stylelint-disable-line
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0 8px;
  position: relative;
  text-align: center;
  width: 100%;

  &:last-child {
    border-right: 0;
  }
}

.resizer {
  display: inline-block;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.cellWrapper {
  display: -webkit-box; // stylelint-disable-line
  display: -moz-box; // stylelint-disable-line
  overflow: hidden;
  -webkit-box-orient: vertical; // stylelint-disable-line
  -webkit-line-clamp: 2;

  &.headerCell {
    overflow: unset;
  }

  &.isNumber {
    text-align: end;
  }

  &.visibleTips {
    overflow: visible;
  }
}

.stickyColumn {
  border-right: unset;
  border-left: 1px solid $webPureBlack;

  > .cellWrapper {
    overflow: unset;
  }
}

.table {
  background-color: $webBackgroundBlack;
  scrollbar-color: $webGrey $webBackgroundBlack;
  scrollbar-face-color: $webGrey;
  scrollbar-track-color: $webBackgroundBlack;
  max-width: 100%;
  height: calc(100% - 96px);

  > div {
    &::-webkit-scrollbar {
      height: 8px;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $webGrey;
      border-radius: 20px;
      opacity: 0.6;
    }
  }

  .tableInner {
    position: relative;
    width: 100%;
    overflow: auto;
    height: 100%;
    direction: ltr;
  }

  &.sticky {
    .header {
      position: sticky;
      z-index: 4;
      top: 0;
    }

    [data-sticky-td='true'] > .resizer {
      width: 0;
    }
  }
}

.body {
  position: relative;
  scrollbar-width: none;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.th {
  @include cellContainer;

  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  background-color: $webBackgroundBlack;
  font-size: 12px;
  user-select: none;

  &.sortedDesc {
    border-bottom: 2px solid $webIconWhite;
  }

  &.isSortedAsc {
    border-top: 2px solid $webIconWhite;
  }
}

.td {
  @include cellContainer;

  background-color: $webBackgroundBlack;
  font-size: 13px;

  &.isNumber {
    justify-content: flex-end;
  }

  &:last-child {
    border-right: none;
  }
}

.tr {
  border-bottom: 1px solid $webPureBlack;
  height: 40px;

  :last-child {
    .td {
      border-bottom: 0;
    }
  }
}

.emptyText {
  color: $webDarkWhite;
  font-size: 14px;
  margin-top: 24px;
  text-align: center;
}

.loaderContainer {
  height: 82px;
  position: relative;
  width: 100%;
}

.loader {
  color: $webWhite;
  left: calc(50% - 16px);
  position: absolute;
  top: calc(50% - 16px);
}
