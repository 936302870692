.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.description {
  padding: 20px 20px 0 0;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-normal);
  overflow-wrap: break-word;
  word-break: break-word;
}
