.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  outline: none;
  border-radius: 10px;
  width: 18px;
  height: 18px;

  &.disabled {
    cursor: default;
    background-color: #161616;
  }

  &.checked {
    background-color: var(--color-btn-primary-normal);

    &.disabled {
      background-color: var(--color-btn-primary-disabled);
    }
  }

  background-color: #161616;

  &:focus,
  &:hover {
    background-color: #161616;

    &.checked {
      background-color: var(--color-btn-primary-pressed);

      &.disabled {
        background-color: var(--color-btn-primary-disabled);
      }
    }

    &.noChange {
      pointer-events: none;
    }

    &.disabled {
      background-color: #161616;
    }
  }

  .icon {
    font-size: 12px;
    color: #161616;

    &.checked {
      color: #fff;
    }
  }
}
