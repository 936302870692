.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
