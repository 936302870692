@import '~shared-modules/styles/constants';

.bodyText {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.5;
  color: $webDarkWhite;
  text-align: left;
  white-space: pre-line;
}

.inputStyle {
  margin-bottom: 30px;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
}

.backButton {
  margin-right: 16px;
}
