.buySellButton {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  width: 64px;
  user-select: none;

  .buy {
    color: var(--color-text-ask);
  }

  .sell {
    color: var(--color-text-bid);
  }
}
