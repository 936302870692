.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background: #000;

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.chartAreaWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.chartContainer {
  height: 100%;
  width: 100%;
}

.displayNone {
  display: none;
}
