.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.6fr 0.4fr;
  grid-template-areas: 'overlay' 'halfModal';
  z-index: 1051;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(#1d1d1d, 0.5);
}

.overlay {
  grid-area: overlay;
  height: 100vh;
  cursor: pointer;
  transition: height 0.1s ease-out 0.1s;

  &.visible {
    height: 60vh;
  }
}

.halfModal {
  grid-area: halfModal;
  height: 40vh;
  margin: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: var(--color-table-header);

  &.hide {
    overflow: hidden;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    height: 24px;

    .close {
      width: 16px;
      height: 16px;
      color: var(--color-text-gray);
      user-select: none;

      &:focus-visible {
        outline: none;
      }

      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }

  .body {
    overflow: auto;
    height: 100%;
    width: 100%;
    color: var(--color-text-normal);
  }
}

.icon {
  color: var(--color-text-normal);
  user-select: none;
  width: 16px;
  height: 20px;
  font-size: 18px;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  &:hover,
  &:focus {
    color: #fff;
  }
}

.imgInfoWrapper {
  padding: 30px 15px;
  justify-content: center;
  display: flex;
  min-width: 576px;
}

.imgInfoTextWrapper {
  display: flex;

  // flex: 1;
  flex-direction: column;
  margin-left: 24px;
  width: 40%;
}

.imgInfoImage {
  max-width: 350px;
  max-height: 200px;
}

.imgInfoTitle {
  font-size: 20px;
  font-weight: bold;
  border-bottom: solid 1px #fff;
}

.imgInfoDesc {
  width: auto;
  font-size: 14px;
  margin-top: 13px;
}
