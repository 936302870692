.container {
  position: relative;
  height: fit-content;
  width: fit-content;
  padding: 0;
  margin: 0;
}

.overlay {
  position: absolute;
  z-index: 1056;
  background-color: #2d2d2d;
  border-radius: var(--border-radius-default);
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear, top 0.3s linear, left 0.3s linear;
  pointer-events: none;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    visibility: visible;
    opacity: 1;
  }

  .content {
    padding: 10px;
    width: 200px;
    height: fit-content;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-text-normal);
  }
}

.target {
  width: fit-content;
  height: fit-content;
}
