.container {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.circle {
  display: inline-block;
  border-radius: 50%;
  height: 16px;
  margin-right: 8px;
  width: 16px;
  background-color: var(--color-black);
}

.item {
  display: flex;
  justify-content: space-between;
  min-width: 150px;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-lightgray);
}

.valueArea {
  display: flex;
  align-items: center;
}

.value {
  font-family: var(--font-family-ascii);
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-text-normal);
}

.symbol {
  padding-left: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-normal);
}
