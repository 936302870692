.marginArea {
  padding: 0 30px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.labelContainer {
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 21px;
  color: var(--color-text-lightgray);
}

.value {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
