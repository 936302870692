.button {
  position: relative;
  margin-right: 4px;
  margin-left: 4px;
}

.first {
  position: relative;
  margin-right: 4px;
  margin-left: 0;
}

.last {
  position: relative;
  margin-right: 0;
  margin-left: 4px;
}
