$height: 48px;

@mixin text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: var(--color-text-normal);
}

.container {
  position: relative;
}

.text {
  @include text;
}

.select {
  height: $height;

  ::-webkit-scrollbar {
    display: none;
  }

  > span:first-child,
  > span:nth-child(2) {
    z-index: 1050;
  }

  :global {
    .editable-select__control {
      height: $height;
      background-color: var(--color-bg-card);
      border-radius: var(--border-radius-default);
      border: 1px solid transparent;
      box-shadow: none;
      cursor: pointer;

      &.editable-select__control--is-focused,
      &:hover,
      &:active,
      &:focus {
        border: 1px solid #fff;
      }

      .editable-select__value-container {
        padding: 2px 12px;
        /* stylelint-disable max-nesting-depth */
        .editable-select__placeholder {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          color: var(--color-text-gray);
          user-select: none;
        }

        .editable-select__input-container {
          @include text;
        }
        /* stylelint-enable max-nesting-depth */
      }

      .editable-select__single-value {
        @include text;
      }
    }

    .editable-select__menu {
      margin: 0;
      background-color: var(--color-bg-card);
      border-radius: var(--border-radius-default);
      z-index: 2;
      min-width: unset;

      .editable-select__menu-list {
        padding: 0;
        -ms-overflow-style: none;
        scrollbar-width: none;
        /* stylelint-disable max-nesting-depth */
        .editable-select__option {
          display: flex;
          align-items: center;
          box-shadow: inset 0 -1px 0 0 #0003;
          cursor: pointer;
          background-color: var(--color-bg-card);

          &:first-child {
            border-top-left-radius: var(--border-radius-default);
            border-top-right-radius: var(--border-radius-default);
          }

          &:last-child {
            border-bottom-left-radius: var(--border-radius-default);
            border-bottom-right-radius: var(--border-radius-default);
          }

          &:focus,
          &:hover,
          &:active,
          &.editable-select__option--is-focused {
            background-color: var(--color-hover);
          }

          // &.editable-select__option--is-selected {
          //   background-color: var(--color-hover);
          // }
          @include text;
        }
        /* stylelint-enable max-nesting-depth */
      }
    }
  }
}
