.container {
  display: flex;
  align-items: center;
  height: 100%;
  user-select: none;
}

.star {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  color: var(--color-btn-secondary-normal);
}

.fill {
  color: #e7cd50;
}

.iconContainer {
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.icon {
  font-size: 16px;
}

.score {
  font-family: var(--font-family-ascii);
  padding-left: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #e7cd50;
  min-width: 12px;
}

.hyphen {
  font-family: var(--font-family-ascii);
  color: var(--color-btn-secondary-normal);
}
