.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--app-header-height));
  min-height: 643px;
  cursor: default;
  background-color: var(--color-table-header);
}

.loader {
  position: absolute;
  color: var(--color-white);
}

.form {
  visibility: hidden;
}
