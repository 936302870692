@import '~shared-modules/styles/constants';

.brandClickableArea {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 4px;

  &.isDisable {
    cursor: default;
  }
}

.firstFlag {
  width: 24px;
  height: 16px;
  margin-right: 4px;
}

.secondFlag {
  width: 24px;
  height: 16px;
  margin-right: 8px;
}

.etfFlag {
  margin-right: 8px;
}

.cfdFlag {
  width: 32px;
  height: 24px;
  margin-right: 8px;
}
