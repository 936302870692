.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text-normal);

  .symbol {
    padding-left: 4px;
  }

  .icon {
    width: 36px;
    height: 24px;
    margin-right: 4px;
  }

  .sep {
    padding: 0 8px;
  }
}
