@import '~shared-modules/styles/constants';

.icon {
  padding: 5px;
  font-size: 20px;
  color: $webIconWhite;
  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus {
    background-color: $webSideMenuHover;
    outline: none;
  }
}
