.switch {
  &.focused,
  &:hover,
  &:active,
  &:focus {
    > :first-child {
      box-shadow: 0 0 0 1px #fff;
    }
  }
}
