@import '~shared-modules/styles/constants';

.table {
  height: 240px;
}

.divider {
  height: 2px;
  background-color: $webDarkThree;
  border-radius: 0.5px;
}
