.container {
  gap: 8px;

  table {
    width: 100%;
    table-layout: fixed;
  }

  table,
  th,
  td {
    border-collapse: collapse;
    border: 0.5px solid #2d2d2d;
  }

  thead>tr {
    background: #2a2a2a;
    color: #fff;
  }

  td {
    height: 59px;
    background: #010101;
    color: #fff;
    padding: 8px 4px;
  }

  th {
    padding: 4px 0 6px;
  }


  .month {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }



  .saturday {
    width: 25px;
    background: #0d2945;
    padding: 8px 0;
  }

  th.saturday {
    background: #15436f;
  }

  .sunday {
    background: #451c0d;
    width: 25px;
    padding: 8px 0;
  }

  th.sunday {
    background: #6f2d15;
  }
}

.price {
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}

.badge {
  background-color: var(--color-btn-primary-normal);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: -2px;
  margin-bottom: -1px;
  line-height: 24px;
}
