@import '~shared-modules/styles/constants';

.radioLabel {
  margin-left: 8px;
  font-size: 12px;
  color: $webWhite;
  width: 100%;
}

.radioImage {
  font-size: 16px;
  color: $webWhite;

  &.isChecked {
    color: $webWhite;
  }
}

.radioElement {
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: 0;
  cursor: pointer;
  user-select: none;
  outline: none;

  &:focus > .radioImage,
  &:hover > .radioImage {
    color: $webGrey;
  }
}
