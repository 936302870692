.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-text-normal);

  &:first-child {
    margin-top: 8px;
  }
}

.marginLabel {
  display: flex;
  align-items: center;
  line-height: 21px;

  .infoWrapper {
    margin-left: 4px;

    .infoIcon {
      font-size: 21px;
      color: var(--color-text-gray);
    }
  }
}

.addButton {
  margin-top: 24px;
}

.marginValue {
  font-family: var(--font-family-ascii);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text-normal);
}
