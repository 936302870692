.container {
  border-radius: 4px;
  background-color: var(--color-white);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  // align-items: center;
  padding: 4px 6px;

  // height: 40px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-black);
  width: 100%;
}

.icon {
  cursor: pointer;
  font-size: 22px;
  color: var(--color-black);
  user-select: none;
}
