.tab {
  .badgeContainer {
    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 0 6px;
      height: 17px;
      min-width: 24px;
      margin-left: 6px;
      background-color: var(--color-white);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: var(--color-text-dark);

      &.active {
        color: var(--color-text-primary);
        background-color: var(--color-white);
      }
    }
  }

  &:focus:not(.active),
  &:hover:not(.active) .badge {
    color: var(--color-white);
    background-color: var(--color-text-dark);
  }
}
