.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - var(--app-header-height)); // routing component の grid の指定がおかしい為の措置
  color: var(--color-white);
  background-color: var(--color-table-header);
}

.text {
  width: 375px;
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  color: var(--color-white);
}

.button {
  margin-top: 24px;
}
