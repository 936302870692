@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
  padding: 5px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $webSideMenuHover;
    outline: none;
  }
}
