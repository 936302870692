.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 200px;
  width: 100%;
  padding: 0 10px;
}

.content {
  height: 100%;
  width: 100%;
}

.card {
  height: 100%;
  max-height: 700px;
  width: 100%;
  padding: 10px 40px;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-default);
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.title {
  padding: 8px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.list {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: var(--color-text-normal);

  li {
    padding: 10px 0;
  }
}

.name {
  display: inline-block;
  vertical-align: middle;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.link {
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-normal);
}
