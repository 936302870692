@import '~shared-modules/styles/constants';

.wrapper {
  display: grid;
  grid-template-areas: 'help-info app-info' 'help-info swap-info' 'margin-info placeholder';
  grid-template-rows: 80px repeat(2, max-content);
  grid-template-columns: 264px 1fr;
  gap: 32px;
  padding: 0 24px 70px;
  color: $webWhite;
}

.helpBlock {
  grid-area: help-info;
}

.appBlock {
  grid-area: app-info;
}

.swapBlock {
  grid-area: swap-info;
}

.marginBlock {
  grid-area: margin-info;
}

.title {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: bold;
}

.row {
  display: flex;
  align-items: center;
  width: 256px;
  height: 48px;
  padding-left: 8px;
  font-size: 14px;
  color: $webWhite;
  text-decoration: none;
  cursor: pointer;

  &.isFirst {
    margin-top: 3px;
    text-decoration: none;
  }

  &:hover {
    color: $webWhite;
    text-decoration: none;
    background-color: $webBackgroundGrey;
  }
}

.logoContainer {
  display: flex;
  margin-top: 16px;
}

.logo {
  cursor: pointer;

  &.isFirst {
    margin-right: 16px;
  }
}
