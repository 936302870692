.symbol {
  font-size: 14px;
  line-height: 1.3;
}

.selectedMonth {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  width: 151px;
}

.changeMonthBtn {
  padding: 4px 0;
  width: 32px;
  height: 32px;
}

.thisMonthBtn {
  padding: 6px 0;
  width: 72px;
  height: 32px;
}

.calendarBtn {
  border: none;
  padding: 2px;
}

.plTotal {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
}

.cardValue {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.cardLabel {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

.cellValue {
  font-size: 13px;
  font-weight: 400;
}

.plByInstrumentTable {
  min-height: 216px;
}

.modalTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin: 8px;
}

.modal {
  z-index: 1155;
}

.backdrop {
  z-index: 1150;
}

.tableHeaderCol {
  font-size: 13px;
  font-weight: 300;
  line-height: initial;
  background-color: #2d2d2d;

  &.name {
    justify-content: center;
  }

  &.number {
    justify-content: end;
  }
}

.leftContainer {
  width: 524px;
}

.calendarContainer {
  width: 550px;
  height: 394px;
}

.tableContainer {
  height: 194px;
}

.titleSide {
  width: 110px;
}

.gap24 {
  gap: 24px;
}

.gap10 {
  gap: 10px;
}

.gap8 {
  gap: 8px;
}

.smallItem {
  width: 225px;
}

.tableTitle {
  font-size: 1.6rem;
  text-align: left;
  font-weight: 600;
}

.cardLayout {
  padding: 24px;
}

.iconBorder {
  border: 2px solid #575757;
}
