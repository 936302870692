@import '../../../../components/Tabs/variables';
@import '../../variable';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - var(--app-header-height) - $tab-height - $side-menu-header-height);
  min-height: 430px;
  background-color: var(--color-bg-normal);
}

.listContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 4px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.list {
  position: relative;
  padding: 4px;
  height: 100%;
  width: 100%;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: var(--color-white);
}
