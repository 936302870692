@import '~shared-modules/styles/constants';

.wrapper {
  font-size: 13px;
  color: $webWhite;
}

.checkboxRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 17px;
}

.checkboxLabel {
  font-size: 13px;
}

.button {
  margin-top: 34px;
}
