.tag {
  text-align: center;
  border-radius: 10px;
  padding: 1px 8px;
  width: fit-content;
  min-width: 68px;
  height: 20px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-white);
}

.fx {
  background: var(--color-fx);
}

.etf {
  background: var(--color-etf);
}

.cfd {
  background: var(--color-cfd);
}

.other {
  background: #42dd96;
}
