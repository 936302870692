@import './variables';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--color-text-normal);
  background-color: var(--color-bg-normal);
}

.border {
  position: relative;
  bottom: 2px;
  border-bottom: 1px solid var(--color-btn-primary-normal);
}

.lineBorder {
  border-bottom: 1px solid #8c8787;
}

.tabs {
  position: relative;
  height: $tab-height;
}

.tabsLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.activeLine {
  position: absolute;
  bottom: 1px;
  height: 3px;
  background-color: var(--color-btn-primary-normal);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
}

.card {
  gap: 0 2px;
}

.lineContainer {
  height: 100%;
}

.tabWrapper {
  height: 100%;
}

.cardTabWrapper {
  padding-top: 7px;
}

.componentWrapper {
  position: relative;
  width: 100%;
  height: calc(100% - #{$tab-height});
}
