@import '~shared-modules/styles/constants';

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  color: $webSellBlue;

  &.isBuy {
    color: $webBuyRed;
  }
}
