.title {
  padding: 10px 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}


.buttonArea {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.link {
  padding: 4px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-text-primary);
}
