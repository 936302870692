.wrapper {
  padding: 4px 0 8px;
}

.errorMessageText {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #f00 !important;
  white-space: pre-line;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: var(--color-text-normal);
}

.link {
  font-weight: bold;
  color: #f00 !important;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--color-text-normal);
  }
}
