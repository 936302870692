.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 18px;
  width: 354px;
  height: 69px;
  background-color: #2d2d2d;
  margin: 12px 0;
}

.rowAlignLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text {
  font-weight: 600;
  font-size: 14px;
}

.rowAlignRight {
  display: flex;
  justify-content: flex-end;
}

.forwardLink {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
