@import '~shared-modules/styles/constants';

.checkboxImage {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 24px;
  color: $webWhite;

  &.isChecked {
    color: $webCheckRed;
  }

  &.isDisabled {
    color: $webGrey;
  }

  &.hasError {
    color: $webInputError;
  }
}

.isReversedImage {
  left: unset;
}

.checkboxLabel {
  margin-left: 4px;
  color: $webWhite;

  &.isReversed {
    margin-right: 4px;
    margin-left: 0;
  }

  &.hasError {
    color: $webInputError;
  }
}

.checkboxElement {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  margin: 0;
  cursor: pointer;
  user-select: none;
  outline: none;

  &.isReversed {
    flex-direction: row-reverse;
  }

  &.isDisabled {
    cursor: default;
  }

  &.isHidden {
    display: none;
  }

  &:focus > .checkboxImage,
  &:hover > .checkboxImage {
    color: $webCheckRed;

    &.isDisabled {
      color: $webGrey;
    }
  }

  &:focus > .checkboxLabel,
  &:hover > .checkboxLabel {
    color: $webWhite;
  }
}

.input {
  display: none;
}

.placeholderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.placeholder {
  width: 16px;
  height: 16px;
  background-color: var(--color-white);
}

.checkedPlaceholder {
  background-color: transparent;
}
