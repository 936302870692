.container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 20px;
}

.noMessage {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-gray);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
