@import '~shared-modules/styles/constants';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 18px;
}

.iconButton {
  margin-left: 4px;
  padding: 0;
  height: 18px;
}

.content {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.boldLine {
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
  display: block;
}

.divider {
  border: 0;
  border-top: 2px solid $webGrey;
}
