@import '~shared-modules/styles/constants';

.checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  cursor: pointer;

  &:hover {
    background-color: $webBackgroundGrey;
  }
}

.labelClassName {
  margin-left: 24px;
  font-size: 13px;
}
