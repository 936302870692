@import '~shared-modules/styles/constants';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-text-normal);

  &:first-child {
    margin-top: 8px;
  }
}

.orderInfo {
  display: block;
  white-space: nowrap;
}

.failText {
  color: $webWarningText;
}

.orderName {
  word-break: break-all;
  white-space: normal;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 45px;
}

.closeButton {
  margin-right: 15px;
}
