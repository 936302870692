.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 14px 0;
  height: 100%;
}

.header {
  margin-bottom: 20px;
}

.content {
  display: grid;
}

.meter {
  border-bottom: 0.5px solid #818181;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.graph {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  padding: 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text-normal);
}

@media screen and (min-width: 1280px) {
  .content {
    grid-template-columns: 4fr 7fr;
  }

  .meter {
    border-right: 0.5px solid #818181;
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
