@import '~shared-modules/styles/constants';

@mixin row($margin-top: 14px) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $margin-top;
  font-size: 13px;
  color: $webWhite;
}

.nameRow {
  @include row(0);
}

.nameValue {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  text-overflow: ellipsis;
  text-align: right;
  max-height: 35px;
}

.currencyRow {
  @include row;
}

.paymentMethodRow {
  @include row;
}

.buySellRow {
  @include row;
}

.orderTermsRow {
  @include row;
}

.countRow {
  @include row;
}

.countValue {
  font-family: var(--font-family-ascii);
  font-weight: bold;
}

.priceRow {
  @include row;
}

.designatedPriceRow {
  @include row;
}

.reverseDesignatedPriceRow {
  @include row;
}

.expirationTypeRow {
  @include row;
}

.checkboxRow {
  @include row;

  width: 100%;
}

.checkboxLabel {
  font-size: 13px;
}

.button {
  margin-top: 32px;
}

.number {
  font-family: var(--font-family-ascii);
}
