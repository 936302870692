@import '~shared-modules/styles/constants';

@mixin row($margin-top: 16px) {
  align-items: center;
  color: $webWhite;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  margin-top: $margin-top;
}

.nameRow {
  @include row(0);
}

.rowValue {
  overflow: hidden;
  text-align: right;
  -webkit-line-clamp: 2;
  width: 180px;
  text-overflow: ellipsis;
  max-height: 35px;
}

.instrumentRow {
  @include row(12px);
}

.transactionPriceRow {
  @include row(12px);
}

.countRow {
  @include row(12px);
}

.datePickerRow {
  @include row;
}

.timePickerRow {
  @include row;
}

.profitLossRow {
  @include row(12px);
}

.profitLossValue {
  color: $webGreen;
  font-weight: bold;
}

.buySellPrices {
  margin-top: 16px;
}

.paymentMethodRow {
  @include row;
}

.settlementBuySellRow {
  @include row;
}

.itemClassName {
  padding: 0 !important; // stylelint-disable-line
  width: 56px;
}

.orderMethodRow {
  @include row;
}

.closeOrderCountRow {
  @include row;
}

.priceRow {
  @include row;
}

.limitPriceRow {
  @include row;
}

.stopPriceRow {
  @include row;
}

.settlementExpirationTypeRow {
  @include row;
}

.checkboxRow {
  @include row(20px);

  width: 100%;
}

.checkboxLabel {
  font-size: 13px;
}

.button {
  margin-top: 32px;
}

.number {
  font-family: var(--font-family-ascii);
}
