@import '../common';
@import '~shared-modules/styles/constants';

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: $padding-horizontal;
  background-color: #2b2b2b;
}

.content {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-top: 6px;
  width: 100%;
  height: 100%;
}

.tabArea {
  position: relative;
  width: 100%;
}

.tabs {
  margin-left: $padding-horizontal;
}

.toolbar {
  padding-right: 6px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #2d2d2d;
  padding: 0 14px;
  height: 60px;
  width: 100%;

  button {
    margin-left: 20px;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text-normal);
}

.help {
  padding-right: 6px;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}
