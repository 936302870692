@import '~shared-modules/styles/constants';

.buttonWrapper {
  background-color: var(--color-bg-card);
  display: flex;
  justify-content: space-between;
  height: 120px;
  width: 430px;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
}

.buttonText {
  margin: auto auto auto 0;
  text-align: start;
}

.buttonTitle {
  font-size: 17px;
  font-weight: bold;
}

.buttonDescription {
  font-size: 15px;
}

.buttonRemarks {
  font-size: 13px;
}

.buttonLeftIcon {
  margin: auto 16px;
}

.transfer {
  margin: auto 12px auto 4px;
}

.buttonRightIcon {
  font-size: 30px;
  margin: auto 12px;
}

.date {
  font-family: var(--font-family-ascii);
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
}

.dateLoader {
  margin: 10px 67px;
}

.infoRow {
  display: flex;
  margin: 20px 0 0;
  height: 135px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.priceBlock {
  margin-right: 20px;
}

.priceInfo {
  display: flex;
}

.infoLoader {
  margin-left: 320px;
}

.mainMenuRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  :hover {
    background-color: var(--color-hover);
    cursor: pointer;
  }
}

.logo {
  margin: 10px 0;
}

.priceLabel {
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}

.price {
  font-family: var(--font-family-ascii);
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}

.refreshRow {
  align-items: center;
  display: flex;
  margin: 0 10px;
}

.refreshButtonWrapper {
  display: flex;
}

.refreshButton {
  margin-left: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-weight: normal;
  font-style: normal;
  padding: 0;

  .icon {
    color: $webIconWhite;
    cursor: pointer;
    font-size: 20px;
    padding-right: 5px;
    user-select: none;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}
