@import '~shared-modules/styles/constants';

.formatItem {
  margin-left: 16px;
}

.dataSeparator {
  margin-left: 16px;
  font-size: 14px;
  font-weight: bold;
  color: $webNotificationText;
  letter-spacing: 0.16px;
}

.searchButton {
  margin-left: 24px;
}
