@import '~shared-modules/styles/constants';

.startUpGuideSection {
  padding-top: 15px;
  display: flex;
  align-items: center;
  border-bottom: 0.001rem solid;
  border-bottom-color: #666;
}

.startUpGuideTitle {
  text-align: left;
  align-items: center;
  padding-top: 5px;

  h2 {
    line-height: 30px;
  }
}

.linkToMedia {
  font-size: 12px;
  color: #fff;
  margin-left: 15px;
  transition: color 0.35s ease;
  text-decoration: none;
}

.linkToMedia:selection {
  background-color: #143a87;
}
