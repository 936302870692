@import '~shared-modules/styles/constants';

.wrapper {
  background-color: $webBackgroundBlack;
  color: $webWhite;
  height: calc(100vh - 57px);
  overflow-y: auto;
  margin-left: 0;
  padding-left: 0;
}

.footer {
  height: 40px;
  width: 100%;
}

.home {
  display: flex;
  flex-wrap: wrap;
}

.h1 {
  font-size: 1.563rem;
  font-weight: bold;
  padding: 1rem;
  padding-left: 30px;
  margin: 0;
}

.h2 {
  font-size: 1.25rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-left: 10px;
  margin-top: 1.5rem;
}

.h3 {
  font-size: 1rem;
  color: #d1d4dce4;
  margin-top: 0.5rem;
  margin-left: 10px;
}

.grey {
  color: #d1d4dc;
}

.postTime {
  font-size: 0.8rem;
  padding: 0.5rem 0;
}


.categoryLink {
  font-size: 0.8rem;
  color: #fff;
  margin-left: 15px;
  transition: color 0.35s ease;
  text-decoration: none;
}

.categoryLink:selection {
  background-color: #143a87;
}

.dividedLine {
  background-color: $webPureBlack;
  height: 1px;
}

.article {
  overflow: visible;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  position: relative;
  width: 100%;
  word-break: break-word;
  cursor: pointer;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 8px;
}

.article:hover {
  background-color: #2a2e39;
}

.article:hover .path {
  fill: #f00;
}

.articleTitle {
  font-size: 15px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.grid {
  --grid-item-min-width: 280px;
  --grid-row-gap: 24px;

  column-gap: var(--grid-col-gap);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-item-min-width), 1fr));
  row-gap: var(--grid-row-gap);

  @media screen and (min-width: 2560px) {
    --grid-item-min-width: 312px;
  }

  @media screen and (min-width: 1280px) {
    --grid-item-min-width: 304px;
    --grid-col-gap: 32px;
    --grid-row-gap: 32px;
  }

  @media screen and (min-width: 1024px) {
    --grid-item-min-width: 312px;
  }

  @media screen and (min-width: 568px) {
    --grid-col-gap: 16px;
    --grid-row-gap: 16px;
  }
}

.thumbnail {
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-radius: 8px;

  @supports not (aspect-ratio: 16 / 9) {
    height: 100%;
    position: absolute;
  }
}

.items {
  margin: 10px 20px;
}

.imageContainer {
  width: 100%;

  @supports not (aspect-ratio: 16 / 9) {
    position: relative;
    padding-bottom: 56.25%;
  }
}

.article:hover svg>path:first-child {
  fill: #f00;
}

.loader {
  color: $webWhite;
  left: calc(50% - 16px);
  position: absolute;
  top: calc(50% - 37px);
}

.tags {
  display: flex;
  margin-left: 4px;

  div:not(:last-child) {
    margin-right: 4px;
  }
}

.serviceTag {
  min-width: 43px;
  height: 19px;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 17px;
  color: var(--color-white);
}

.youtubeButtonHome {
  top: 35%;
}
