@import '~shared-modules/styles/constants';

@mixin row($margin-top: 13px) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $margin-top;
}

.headerRow {
  font-weight: bold;
}

.brandRow {
  @include row;
}

.orderTypeRow {
  @include row;
}

.buySellRow {
  @include row;
}

.countRow {
  @include row;
}

.boldText {
  font-weight: bold;
}

.calculatingMarginRow {
  @include row;
}

.priceRow {
  @include row;
}

.expirationTypeRow {
  @include row;
}
