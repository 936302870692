@import '~shared-modules/styles/constants';

.outerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32.5px;
  user-select: none;
  padding: 0 1px;
  border-radius: 16.5px;
  background-color: #2b2b2b;
  border: 1px solid #2b2b2b;

  &.focused,
  &:hover,
  &:focus,
  &:active {
    // border: 1px solid #3b3b3b;
    border: 1px solid var(--color-border-focus);
  }

  &.disabled {
    border: 1px solid #2b2b2b;
  }

  &.isError {
    border: 1px solid $webInputError;

    &.isNumberInput {
      text-align: center;
    }
  }
}

.wrapper {
  position: relative;
}

.input {
  background-color: #2b2b2b;
  border: 1px solid #2b2b2b;
  font-size: 12px;
  color: $webWhite;
  height: 28px;
  padding: 0 8px;
  position: relative;

  &.isNumberInput {
    border-radius: 2px;
    text-align: center;
  }

  &.disabled {
    opacity: 0.5;
  }

  // remove default arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none; // TODO
    -webkit-appearance: none; // stylelint-disable-line
    margin: 0;
  }

  &[type='number'] {
    appearance: textfield; // TODO
    -moz-appearance: textfield; // stylelint-disable-line
  }

  &:focus {
    border: none;
    outline: none;

    &.isNumberInput {
      text-align: center;
    }
  }

  &.isError {
    border: 2px solid $webInputError;
    padding: 0 8px;

    &.isNumberInput {
      text-align: center;
    }
  }

  &::placeholder {
    color: $webInputPlaceholder;
  }

  &:invalid {
    box-shadow: none;
  }
}

.error {
  color: $webInputError;
  font-size: 12px;
  margin-top: 4px;
}

.handle {
  width: 29px;
  height: 29px;
  background-color: var(--color-btn-secondary-normal);
  border-radius: 14.5px;
  color: var(--color-text-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #5f5f5f;
    background-color: #fff;
    outline: none;
  }

  &.disabled {
    color: var(--color-text-disabled);
    pointer-events: none;
    background-color: var(--color-btn-secondary-disabled);
  }
}

.tooltip {
  display: block;
  width: 168px;
  word-break: break-all;
  overflow-wrap: break-word;
}
