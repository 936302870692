.card {
  display: inline-block;
  font-weight: 300;
  background: #2a2a2a;
  border-radius: 4px;
  padding: 12px;
  width: 25.8rem;

  &:hover {
    background: var(--color-hover);
    cursor: pointer;
  }
}

.groupName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 1rem;
  height: 40px;
  text-align: left;
  word-wrap: break-word;
}

.symbolName {
  text-align: left;
}

.spacer {
  height: 53px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.3;
  color: var(--color-text-lightgray);
}

.value {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
}

.symbol {
  font-size: 13px;
  line-height: 1.3;
}
