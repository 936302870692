@import '~shared-modules/styles/constants';

.modalComponent {
  padding: 0 20px 10px;
  margin: 0 auto;

  .leftComponent {
    margin: 0 auto;
    width: 50%;
    float: left;

    .img {
      width: 440px;
      position: relative;
    }
  }

  .rightComponent {
    width: 50%;
    padding: 0 15px 0 0;
    float: right;

    .topTitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: #dcdcdc;
    }

    .subTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      color: #dcdcdc;
    }

    .sentenceBlock {
      font-size: 16px;
      margin-top: 16px;

      .bold {
        font-weight: 600;
        line-height: 20px;
      }
    }

    .imageBlock {
      margin-top: 24px;

      .instrumentImagesArea {
        min-height: 75px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 430px;
      }

      .instrumentImage {
        width: 200px;
        height: 70px;
      }
    }

    .footer {
      display: flex;
      padding: 30px 0 10px;
      justify-content: center;

      .button {
        width: 160px;
      }
    }
  }
}
