@import '~shared-modules/styles/constants';

.wrapper {
  ::-webkit-scrollbar {
    display: none;
  }

  > span:first-child,
  > span:nth-child(2) {
    z-index: 1050;
  }
}

.isFullWidth {
  width: 100%;
}

.errorMessage {
  margin-top: 8px;
  font-size: 12px;
  color: $webInputError;
}

.isDisabledOption {
  cursor: default;
  opacity: 0.5;
}

.tooltip {
  display: block;
  width: 168px;
  word-break: break-all;
  overflow-wrap: break-word;
}
