@import 'shared-modules/styles/constants';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: table;
  min-height: 293px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 $webShadowColor;
  background-color: #ff0;
}

.tabs {
  background-color: var(--color-table-header);
}
