@import '~shared-modules/styles/constants';

.pageWrapper {
  align-items: flex-start;
  color: $webWhite;
  display: flex;
  justify-content: flex-start;
  min-height: 100%;
  flex-direction: column;
  padding: 20px 40px;
}

.pageTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 3;
}

.button {
  margin: 0 16px;
  border-radius: 10px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 40px;
}

.description {
  margin-bottom: 20px;
}

