.currencyPairIcon {
  margin-top: 24px;
  position: relative;
}

.tags {
  margin-right: 4px;
}

.tag {
  min-width: 76px;
  margin-bottom: 2px;
}

.status {
  position: absolute;

  // (80 - 56) / 2 = 12px
  left: 12px;
  bottom: -2px;
}

.rangeOut {
  position: absolute;

  // (80 - 88) / 2 = -4px
  left: -4px;
  bottom: -2px;
}

.roundBadge {
  border-radius: 10px;
  padding: 2px 4px;
  min-width: 76px;
  background: #3a3a3a;
  text-align: center;
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  color: var(--color-text-normal);
}

.imageArea {
  position: relative;
}

.groupIcon {
  direction: rtl;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1rem;
}
