.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--color-white);
}

.linkButton {
  width: 160px;
  height: 36px;
}

.linkCaption {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIcon {
  margin-left: 2px;
  font-size: 14px;
  color: var(--color-white);
}

.disabledLinkIcon {
  color: var(--color-text-gray);
}

.buttonArea {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-left: 10px;
  }
}

.container {
  display: flex;
  width: 100%;
  padding: 8px 16px 8px 12px;
}

.content {
  flex-grow: 1;
  min-width: 0;
  padding-left: 8px;
  margin: auto 0;
  word-break: break-all;
}

.image {
  margin: 0;
}

.name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-normal);
}

.description {
  padding-top: 4px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-lightgray);
}

.rangeOutAlert {
  display: flex;
  align-items: center;
  padding: 4px 0 0;
}

.icon {
  padding: 0 6px 0 0;
}

.alertText {
  padding-top: 1px;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 17px;
  color: var(--color-text-danger);
}
