.container {
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  font-size: 1.4rem;
  color: var(--color-text-normal);
  background: var(--color-background);
  padding: 2.8rem;

  h1,
  h2,
  h3 {
    margin-bottom: 1rem;
    line-height: 1.1;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.3;
  }

  p {
    margin-top: 0;
  }
}

.tabs {
  background-color: var(--color-table-header);
}

.customScrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.tabContent {
  min-height: 480px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.3;
  color: var(--color-text-lightgray);
}

.value {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
}

.symbol {
  font-size: 13px;
  line-height: 1.3;
}

.loaderContainer {
  width: 100%;
  text-align: center;
  padding-top: 40px;
}

.dangerLevelNotificationArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 4px;
  column-gap: 12px;
}
