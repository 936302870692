@import '~shared-modules/styles/constants';

.text {
  font-size: 14px;
  color: $webWhite;
  white-space: pre-line;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 560px;
  margin-top: 30px;
}
