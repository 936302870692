@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
}

.label {
  color: $webDarkWhite;
  font-size: 12px;
  margin-bottom: 16px;

  &.disabled {
    opacity: 0.5;
  }
}

.input {
  background-color: var(--color-bg-card);
  border: 1px solid var(--color-bg-card);
  border-radius: var(--border-radius-default);
  color: var(--color-text-normal);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  height: 40px;
  padding: 0 8px;
  position: relative;

  &.isSearchInput {
    padding-left: 42px;
  }

  &.disabled {
    opacity: 0.5;
  }

  &:focus {
    border: 1px solid $webWhite;
    outline: none;
    padding: 0 8px;

    &.isSearchInput {
      padding-left: 42px;
    }
  }

  &.isError {
    border: 2px solid $webInputError;
    padding: 0 8px;

    &.isSearchInput {
      padding-left: 41px;
    }
  }

  &::placeholder {
    color: $webInputPlaceholder;
  }

  &:invalid {
    box-shadow: none;
  }
}

.error {
  color: $webInputError;
  font-size: 12px;
  margin-top: 4px;
}

.searchIcon {
  position: absolute;
  top: 8px;
  left: 12px;
  color: #5f5f5f;
  font-size: 24px;
  user-select: none;

  &.withLabel {
    top: 44px;
  }

  &.disabled {
    opacity: 0.3;
  }
}

.tooltip {
  display: block;
  overflow-wrap: break-word;
  width: 168px;
  word-break: break-all;
}
