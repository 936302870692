@import '~shared-modules/styles/constants';

.wrapper {
  background-color: $webBlack;
  display: grid;
  grid-template-rows: 56px 1fr;
  height: 100%;
}

.menuIcon {
  align-self: center;
  color: $webWhite !important; // stylelint-disable-line
  font-size: 24px !important; // stylelint-disable-line
  justify-self: center;
}
