@import '~shared-modules/styles/constants';

.button {
  margin-right: 32px;
  border-radius: 10px;
  height: 44px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
}

.colTitle {
  align-items: flex-start;
  background-color: var(--color-table-contents);
  width: 40%;
}

.colData {
  align-items: flex-start;
  width: auto;
}

.description {
  margin: auto 0;
}

.table {
  border-collapse: collapse;
  margin: 20px 0;
  width: 600px;

  * {
    border: 1px solid $webBlack;
    padding: 15px 0 15px 30px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 3;
}

.titleRow {
  display: flex;
  margin: 0 40px;
}
