@import '~shared-modules/styles/constants';

.buttonInnerStyle {
  margin: 0 16px;
  border-radius: 8px;
}

.buttonGroup {
  margin: 10px auto;
}
