@import '~shared-modules/styles/constants';

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 24px;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  color: $webWhite;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: #313131;
  border: none;
  border-radius: 2px;

  &.isOutlined {
    padding: 0 14px;
    background-color: transparent;
    border: 2px solid $webWhite;
  }

  &.isSmall {
    height: 24px;
    padding: 0 10px;
    font-size: 12px;
  }

  &.isLightGrey {
    background-color: $webGrey;
  }

  &.isGreen {
    background-color: var(--color-btn-primary-normal);
  }

  &.isWhite {
    color: $webBackgroundGrey;
    background-color: $webWhite;
  }

  &.isBlue {
    background-color: $webBlue;
  }

  &.isDarkSkyBlue {
    background-color: $webDarkSkyBlue;
  }

  &.isRed {
    background-color: var(--color-btn-primary-normal);
  }

  &.isTransparent {
    padding: 0;
    font-size: 13px;
    background-color: transparent;
    border-radius: 0;
  }

  &.isDisabled {
    cursor: auto;
    opacity: 0.5;

    &.isTransparent {
      opacity: 0.6;
    }

    &.lookDisabledOnly {
      cursor: pointer;
    }
  }

  &.isHighlighted {
    color: $webBackgroundGrey;
    background-color: $webWhite;
  }

  &:focus,
  &:hover:not(.isDisabled) {
    background-color: $webBackgroundGreyHover;
    outline: none;

    &.isLightGrey {
      background-color: $webGreyHover;
    }

    &.isGreen {
      background-color: var(--color-btn-primary-pressed);
    }

    &.isWhite {
      background-color: $webWhiteHover;
    }

    &.isHighlighted {
      background-color: $webWhiteHover;
    }

    &.isBlue {
      background-color: $webBlueHover;
    }

    &.isDarkSkyBlue {
      background-color: $webDarkSkyBlueHover;
    }

    &.isRed {
      background-color: #df4f60;
    }

    &.isTransparent {
      background-color: transparent;

      .transparentChildrenWrapper {
        border-top: 2px solid transparent;
        border-bottom: 2px solid $webWhite;
      }
    }

    &.isOutlined {
      color: $webBackgroundBlack;
      background-color: $webWhite;
    }
  }
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;

  &.isSmall {
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    width: 1rem;
    height: 1rem;
  }

  &.isBlack {
    color: $webBlack;
  }
}
