.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-default);
  height: 100%;
  cursor: pointer;

  .nameArea {
    padding: 0 10px;

    .name {
      padding: 4px 0;
      overflow-wrap: break-word;
      word-break: break-word;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-text-normal);
    }

    .symbol {
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 15px;
      color: var(--color-text-normal);
    }
  }

  &.disabled {
    cursor: auto;
    opacity: 0.5;

    &.isTransparent {
      opacity: 0.6;
    }

    &.lookDisabledOnly {
      cursor: pointer;
    }
  }
}

.nameWrapper {
  display: flex;
  align-items: center;
  height: 67px;
  font-size: 14px;
  overflow-wrap: break-word;
}

.row {
  align-items: center;
  padding-top: 2px;
}
