.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 19px;
  background-color: #585858;
  padding: 0 6px;
  margin-right: 4px;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-white);
}
