@import '~shared-modules/styles/constants';

.container {
  font-weight: bold;
  align-items: center;
  display: flex;
  margin: auto;
  line-height: 25px;
  font-size: 14px;
  padding: 20px;
}

.link {
  font-weight: bold;
  color: $webDarkWhite;
  text-decoration: underline;
  cursor: pointer;
  position: relative;

  &:hover {
    color: var(--color-text-normal);
  }
}
