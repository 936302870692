.container {
  position: relative;
  display: grid;
  gap: 0;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-template-columns: 28px 1fr 28px;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  height: 100%;
}

.scrollArea {
  position: relative;
  display: grid;
  gap: 0;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  justify-content: left;
  align-items: center;
  background-color: transparent;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrow {
  padding: 0;
  margin: auto;
  cursor: pointer;
  user-select: none;
  color: var(--color-text-normal);
  font-size: 22px;

  &.active {
    color: var(--color-white);
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus:not(.disabled, .active),
  &:hover:not(.disabled, .active) {
    outline: none;
    color: var(--color-white);
  }
}
