@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
  display: flex;
  height: 54px;
  margin: -1px;
  overflow: hidden;
  border-radius: 7px;
}

.largePrice {
  padding-top: 4px;
  font-size: 18px;
}

.spread {
  font-family: var(--font-family-ascii);
  position: absolute;
  bottom: 8px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 16px;
  padding: 0 6px;
  font-size: 10px;
  font-weight: bold;
  color: $webWhite;
  pointer-events: none;
  background-color: #313131;
  border-radius: 4px;
  transform: translateX(-50%);
}
