@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-left: 7px;
  cursor: default;
  background-color: $webBackgroundBlack;
}

.header {
  height: 100%;
  width: 100%;
  background-color: #2d2d2d;
}

.title {
  overflow: hidden;
  font-size: 13px;
  color: $webWhite;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.newsRow {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover > .title {
    text-decoration: underline;
  }
}

.icon {
  margin-right: 9px;
}

.label {
  margin-right: 8px;
  font-size: 13px;
  font-weight: bold;
  color: $webWhite;
}

.settings {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 40px;
  border-left: 1px solid $webPureBlack;
}
