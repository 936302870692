.lineContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 4px;
  height: 100%;

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: var(--color-bg-dark);;
  }

  &.lineRecommendation .mask {
    border-bottom: 1px solid var(--color-border-light);
  }

  .line {
    width: 100%;
    height: 100%;
    border-radius: 4px 0 0 4px;
    background-color: var(--color-text-primary);
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 6px;
  border-radius: 4px;
  background-color: var(--color-bg-dark);
  cursor: pointer;

  &.recommendation {
    padding: 24px 5px 7px;
    border: 1px solid var(--color-border-light);
  }

  &:hover {
    background-color: var(--color-hover);

    .mask {
      background-color: var(--color-hover);
    }
  }
}

.lineRecommendation {
  top: 0.8px;
  left: -0.8px;
}

.recommendedTagContainer {
  position: absolute;
  top: -1px;
  left: -0.8px;
  width: fit-content;
  height: 21px;
  background-color: var(--color-border-light);
  border-radius: 4px 0 3px;
  padding: 2px 6px 1px;

  &.selected {
    background-color: var(--color-text-primary);
  }

  .recommendedTag {
    font-size: 11px;
    font-weight: 300;
    line-height: 17px;
    color: var(--color-white);
    text-align: left;
  }
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 4px;
}

$image-size: 40px;

.details {
  height: 100%;
  width: calc(100% - $image-size);
  min-width: 0;
  padding-left: 6px;
}

.image {
  margin-top: 4px;
  width: $image-size;
  height: $image-size;

  > div {
    width: $image-size;
    height: $image-size;
  }
}

.tags {
  display: flex;
  margin-left: 4px;

  div:not(:last-child) {
    margin-right: 4px;
  }
}

.serviceTag {
  min-width: 43px;
  height: 19px;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 17px;
  color: var(--color-white);
}

.headline {
  display: flex;
  flex-direction: column;
}

.upperRow {
  display: flex;
  justify-content: space-between;
  padding: 1px 0;
  height: 100%;
}

.lowerRow {
  display: flex;
  padding: 3px 0;
  height: 100%;
  min-height: 23px;
}

.nameContainer {
  display: flex;
  align-items: flex-start;
  padding-top: 1px;
}

.nameWithIcon {
  display: flex;
}

.icon {
  align-items: center;
  padding: 1px 8px 0 6px;
  height: 18px;
}

.name {
  height: fit-content;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: var(--color-lightgray);
}

.stats {
  display: grid;
  grid-template-columns: 0.9fr 0.7fr 1.1fr;
  grid-template-rows: 1fr;
  gap: 8px;
  padding-top: 4px;
  padding-bottom: 2px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: var(--color-text-lightgray);
}

.value {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.symbol {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 17px;
  color: var(--color-text-normal);
}

.percent {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding-left: 1px;
}

.featureTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
}

.featureTagsArea {
  height: 23px;
}
