@mixin text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: var(--color-text-normal);
}

.container {
  position: relative;
}

.icon {
  font-size: 16px;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-text-normal);

  .icon {
    font-size: 18px;
    color: var(--color-text-gray);
  }

  &:hover,
  &:active {
    color: var(--color-white);

    .icon {
      color: var(--color-white);
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.menuWrapper {
  position: fixed;
  z-index: 2;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-default);
  padding: 0;
  margin: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #696969;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }

  &:focus-visible {
    outline: none;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  list-style: none;
  padding: 0;
  margin: 0;
  width: max-content;

  &:focus-visible {
    outline: none;
  }
}

.menuItem {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--color-bg-card);
  padding: 10px 20px;
  box-shadow: inset 0 -1px 0 0 #0003;
  width: 100%;

  &:first-child {
    border-top: none;
    border-top-left-radius: var(--border-radius-default);
    border-top-right-radius: var(--border-radius-default);
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
  }

  &:active,
  &:hover,
  &:focus {
    background-color: var(--color-hover);
  }

  &:focus-visible {
    outline: none;
  }
}

.menuItemLabel {
  display: inline-block;
  margin-right: 14px;
  width: 100%;
  @include text;
}

.filler {
  width: 16px;
}
