@import 'shared-modules/styles/constants';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: table;
  min-height: 293px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 $webShadowColor;
}

.orderTypeHeader {
  text-align: right;
}

.table {
  flex: 1;
  width: 100%;
  height: 100%;
}
