.container {
  text-align: center;
  width: 56px;
  height: 17px;
  border-radius: 10px;
  padding: 2px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.3;
  color: var(--color-white);
}

.active {
  background-color: var(--color-btn-primary-normal);
}

.inactive {
  background-color: var(--color-btn-secondary-normal);
}
