.filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  line-height: 17px;
  font-weight: 300px;
  color: var(--color-text-normal);

  &:focus-visible {
    outline: none;
  }

  .icon {
    font-size: 20px;
    color: var(--color-text-gray);

    &.filtered {
      color: var(--color-text-primary);
    }
  }

  &:hover,
  &:active {
    color: var(--color-white);

    .icon {
      color: var(--color-white);

      &.filtered {
        color: var(--color-text-primary);
      }
    }
  }
}

.content {
  padding: 0;
}

.item {
  padding: 4px 0;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.checks {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
  padding: 8px 0;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px;
  column-gap: 16px;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.filtered {
  color: var(--color-text-primary);
}

.borderLine {
  height: 1px;
  background-color: #414141;
  margin: 4px 0 10px;
}

.rangeOut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0 12px;
}

.rangeOutText {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: var(--color-text-lightgray);
  padding: 2px 0;
}

.itemWidth3 {
  width: 215px;
}

.itemWidth4 {
  width: 159.5px;
}

.itemWidth5 {
  width: 126px;
}
