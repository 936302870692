.container {
  position: relative;
  height: calc(100vh - var(--app-header-height));
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.sideMenu {
  position: relative;
  overflow: hidden;
  background-color: var(--color-bg-normal);
  width: 100%;
  height: 100%;
}
