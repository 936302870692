$sorted-color: #eee;
$empty-text-color: #d8d8d8;

@mixin cellContainer {
  align-items: center;
  display: flex !important; // stylelint-disable-line
  height: 100%;
  margin: 0;
  padding: 0 8px;
  position: relative;
  text-align: center;
  width: 100%;
}

.resizer {
  display: inline-block;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}

.cellWrapper {
  cursor: default !important;
  display: -webkit-box; // stylelint-disable-line
  display: -moz-box; // stylelint-disable-line
  overflow: hidden;
  -webkit-box-orient: vertical; // stylelint-disable-line
  -webkit-line-clamp: 2;

  &.headerCell {
    overflow: unset;
  }

  &.isNumber {
    font-family: var(--font-family-ascii);
    text-align: end;
  }
}

.stickyColumn {
  border-right: unset;

  > .cellWrapper {
    overflow: unset;
  }
}

.table {
  background-color: var(--color-table-contents);
  max-width: 100%;
  height: 100%;

  > div > div {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-corner {
      display: none;
    }
  }

  &.sticky {
    .header {
      position: sticky;
      z-index: 4;
      top: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-td='true'] > .resizer {
      width: 0;
    }
  }
}

.body {
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.th {
  @include cellContainer;

  justify-content: space-between;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  border-right: 1px solid var(--color-black);
  background-color: var(--color-table-header);
  user-select: none;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: var(--color-text-normal);

  &.stickyColumn {
    justify-content: center;
  }

  &.sortedDesc {
    border-bottom: 2px solid $sorted-color;
  }

  &.isSortedAsc {
    border-top: 2px solid $sorted-color;
  }

  &:last-child {
    border-right: 0;
  }
}

.td {
  @include cellContainer;

  justify-content: center;
  background-color: var(--color-table-contents);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-normal);

  &.isNumber {
    justify-content: flex-end;
  }

  &.isOrderName {
    justify-content: flex-start;
  }

  &:last-child {
    border-right: none;
  }
}

.tr {
  border-bottom: 1px solid var(--color-black);
  height: 32px;

  :last-child {
    .td {
      border-bottom: 0;
    }
  }
}

.footer {
  justify-content: end;
}

.emptyText {
  color: $empty-text-color;
  font-size: 14px;
  margin-top: 24px;
  text-align: center;
}

.loaderContainer {
  height: calc(100% - 40px);
  position: relative;
  width: 100%;

  &.hasMorePages {
    height: 50px;
  }
}

.loader {
  color: var(--color-white);
  left: calc(50% - 16px);
  position: absolute;
  top: calc(50% - 16px);
}
