.thumbnailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 157px;
  aspect-ratio: 16/9;
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
}

.thumbnail {
  height: 100%;
  aspect-ratio: 16/9;
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
}

.container {
  position: relative;
  border-radius: var(--border-radius-default);
  background-color: #f3f3f3;
  cursor: pointer;

  &:hover {
    background-color: #cfcfcf; // 10% darken

    .thumbnail {
      filter: brightness(86%);
    }
  }
}

.info {
  justify-content: space-between;
  align-items: center;
  width: 279px;
  padding: 12px 16px 16px;
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
}

.titleArea {
  max-width: 279px;
  height: 100%;
}

.dateArea {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 5px;
}

.unreadArea {
  margin-left: auto;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #5f5f5f;
}

.date {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #5f5f5f;
}

.iconArea {
  margin: 0;
  padding: 0;
}

.icon {
  font-size: 12px;
  color: var(--color-text-gray);
  user-select: none;
}
