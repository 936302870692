.itemClassName {
  display: inline-block;
  min-height: 42px;
  max-height: 42px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 0.8rem 2rem;
  font-weight: 300;
}
