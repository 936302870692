@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: $webDarkBlueGrey;
  border-radius: 50%;

  &.isSmall {
    width: 48px;
    height: 48px;
  }

  &.isMini {
    width: 40px;
    height: 40px;
  }

  &.isTiny {
    width: 32px;
    height: 32px;
  }
}

.firstFlag {
  position: absolute;
  top: 18px;
  left: 10px;
  z-index: 2;
  width: 36px;
  height: 28px;

  &.isSmall {
    top: 11px;
    left: 6px;
    width: 20px;
    height: 15px;
  }

  &.isMini {
    top: 8px;
    left: 5px;
    width: 18px;
    height: 14px;
  }

  &.isTiny {
    top: 6px;
    left: 4px;
    width: 13px;
    height: 10px;
  }
}

.secondFlag {
  position: absolute;
  right: 10px;
  bottom: 18px;
  width: 36px;
  height: 28px;

  &.isSmall {
    right: 6px;
    bottom: 11px;
    width: 20px;
    height: 15px;
  }

  &.isMini {
    right: 5px;
    bottom: 8px;
    width: 18px;
    height: 14px;
  }


  &.isTiny {
    right: 4px;
    bottom: 6px;
    width: 13px;
    height: 10px;
  }
}

.etfFlag {
  &.isTiny {
    width: 20px;
    height: 15px;
  }


  &.isMini {
    width: 24px;
    height: 19px;
  }

  &.isSmall {
    width: 36px;
    height: 36px;
  }
}

.cfdFlag {
  &.isTiny {
    width: 20px;
    height: 15px;
  }

  &.isMini {
    width: 24px;
    height: 24px;
  }

  &.isSmall {
    width: 36px;
    height: 36px;
  }
}
