@import '~shared-modules/styles/constants';

.wrapper {
  align-items: center;
  background-color: #171717;
  color: $webWhite;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}

.labelWrapper {
  align-items: center;
  color: $webWhite;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  height: 30px;
  justify-content: center;
  margin-left: 10px;

  &.isDisabled {
    color: $webGreyHover;
  }
}

.area {
  border-radius: 50%;
  height: 14px;
  margin: 5px;
  width: 14px;

  &.isDisabled {
    background-color: $webGreyHover !important; // stylelint-disable-line
  }
}

.line {
  height: 2px;
  margin: 5px;
  width: 14px;

  &.isDisabled {
    background-color: $webGreyHover !important; // stylelint-disable-line
  }
}

.legendWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.tooltipWrapper {
  background-color: $webBackgroundGrey;
  border: none;
  margin: 0;
  padding: 10px;
  white-space: nowrap;
}

.tooltipLabel {
  margin: 0;
}

.tooltipItem {
  margin: 5px 0 0;
}

.emptyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
