@import '~shared-modules/styles/constants';

.wrapper {
  display: grid;
  grid-template-areas: 'card chart' 'table table';
  grid-template-rows: 310px auto;
  grid-template-columns: 256px 696px;
  gap: 24px;
  color: var(--color-text-normal);
}
