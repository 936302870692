.one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.two-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.symbol {
  font-weight: bold;
}

/* Tooltips */

.tooltip--help {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.tooltip--info {
  margin-left: 0.5rem;
  font-size: 1.6rem;
  vertical-align: middle;
}

.tooltip-content {
  padding: 0.8rem;
  font-size: 1.6rem;
  border-radius: 5px;
  text-align: left;
}

.info-tooltip {
  width: 200px;
  font-size: 15px;
  background-color: #161616;
  color: #fff;
  padding: 10px;
}

.custom-tooltip {
  width: 200px;
  background-color: var(--color-hover);
  font-size: 15px;
  color: #fff;
  padding: 10px;
}

/* override bootstrap tooltip style */
.dropdown-toggle {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  border: 1px solid #8c8787;
  border-radius: 0;
  background: var(--color-background);
  min-width: 180px;

  &.btn-dark {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #8c8787;

    &:focus,
    &:hover,
    &:active {
      background-color: var(--color-hover);
    }
  }
}

.dropdown-chevron::after {
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  margin-bottom: 3px;
}

.dropdown-item,
.dropdown-menu {
  &:focus-visible {
    outline: none;
  }

  background: var(--color-bg-chart);
  min-width: 180px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: var(--color-text-normal);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-dropdown-link-hover-bg);
}

.nav-tabs .nav-item .nav-link {
  border: 0;
  border-bottom: 1px solid var(--color-text-primary);
  background: #313131;
  color: var(--color-text-normal);
}

.nav-tabs .nav-item .nav-link.active {
  background-color: var(--color-text-primary);
  color: #fff;
}

.nav-tabs {
  border-bottom: 1px solid var(--color-text-primary);
  column-gap: 1px;
}

.spinner-border {
  width: 30px;
  height: 30px;
}

.tooltip {
  font-size: 14px;
}

/* Cards */

.card {
  border-radius: 10px;
  display: inline-block;
  padding: 1.4rem 2rem;
  font-weight: 300;
  background: #2a2a2a;
}

.card__row {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: space-between;
  margin-bottom: 8px;
}

.price-label-column {
  display: block;
  font-size: 11px;
}

.card--clickable:hover {
  background: var(--color-hover);
  cursor: pointer;
}

.card__row__label {
  color: var(--color-light);
  display: flex;
  align-items: center;
}

.card--small {
  border-radius: 4px;
  padding: 12px;
  width: 25.8rem;
}

.cardGrid {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, 25.8rem);
  margin-top: 15px;
}

.card--small .one-line {
  width: 23.2rem;
}

.card .value {
  font-weight: bold;
  color: var(--color-text-normal);
  text-align: right;
}

.rank-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #414141;
  height: 57px;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: var(--color-light);
  padding: 9px 12px;
}

.rank-block__title {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #dbdbdb;
  text-align: center;
}

.rank-block__icon {
  font-size: 32px;
}

.rank-block__value {
  font-family: var(--font-family-ascii);
  font-size: 13px;
}

/* Modals */
.popup {
  background-color: #1d1d1d;
  font-size: 14px;
  color: var(--color-text-normal);
}

.popup--large {
  width: 1230px;
}

.popup--fit-content {
  width: fit-content;
}

@media screen and (min-width: 1280px) {
  .popup--fit-content {
    width: 100%;
  }
}

.popup__header {
  border-bottom: 0;
}

.popup__body {
  text-align: center;
  padding: 0 2rem 2rem;
}

/* Links */
a {
  text-decoration: none;
}

/* Grids */
.grid {
  display: grid;
}

.grid--1x3--fixed {
  grid-template-columns: repeat(3, 1fr);
}

.grid--1x2 {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 7px;
}

.gridx4 {
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (min-width: 1280px) {
  .grid--1x3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.grid--2x2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid--card-auto-fit-row {
  grid-template-rows: repeat(auto-fit, minmax(30rem, 1fr));
}

@media screen and (min-width: 1280px) {
  .grid--2x2 {
    display: flex;
  }
}

/* Navbar */
.nav__item {
  color: var(--color-text-normal);
  font-size: 13px;
  min-width: 68px;
  max-width: 131px;
  padding: 2px 10px;
  text-align: center;
  white-space: nowrap;
  font-weight: 300;
  cursor: pointer;
}

.nav__item--active {
  color: #000;
  background: #ccc;
}

/* Colors */
.plus {
  color: #42dd96;
}

.minus {
  color: #df5656;
}
