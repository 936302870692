@import '~shared-modules/styles/constants';

.content {
  padding-top: 20px;
  height: 480px;
  font-weight: 300;
  letter-spacing: 0;
  text-align: left;
  margin: auto 0;
  color: #fff;
  display: table;
}

.topContent {
  font-weight: 300;
  text-align: left;
  padding-bottom: 42px;

  .innerContent {
    margin-bottom: 25px;
  }
}

.mainContent {
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;

  p {
    line-height: 25px;
  }
}

.subContent {
  font-size: 12px;
  line-height: 18px;
}

.stepContainer {
  border-radius: 4px;
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;
  background-color: #2b2b2b;
  color: #dcdcdc;

  .step {
    font-weight: 800;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    margin-right: 13px;
    color: #fff;
  }

  .subTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    color: #fff;
  }
}

.bold {
  font-weight: 600;
}

.topTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  align-items: center;
  color: #dcdcdc;
  padding-top: 10px;
}

