img,
svg {
  vertical-align: initial;
}

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;

  --filmstrip-container-horizontal-offset: var(--adaptive-mf-container-padding);

  margin: 0 calc(var(--filmstrip-container-horizontal-offset) * (-1));
  max-width: calc(100% + var(--filmstrip-container-horizontal-offset) * 2);
  width: calc(100% + var(--filmstrip-container-horizontal-offset) * 2);

  --filmstrip-visible-items: 2;
  --filmstrip-item-min-width: 272px;
  --filmstrip-gap: 16px;
  --filmstrip-scroll-btn-top: calc((100vw - var(--adaptive-mf-container-padding) - var(--filmstrip-gap) * (var(--filmstrip-visible-items) - 1)) / var(--filmstrip-visible-items) / 2 / 2 + 8px);

  @media screen and (min-width: 1920px) {
    --adaptive-mf-container-padding: 100px;
  }

  @media screen and (min-width: 1440px) {
    --adaptive-mf-container-padding: 40px;
  }

  @media screen and (min-width: 1024px) {
    --adaptive-mf-container-padding: 32px;
  }

  @media screen and (min-width: 1280px) {
    --filmstrip-container-horizontal-offset: unset;
  }

  @media screen and (min-width: 2560px) {
    --filmstrip-visible-items: 6;
    --filmstrip-scroll-btn-top: calc((var(--adaptive-mf-container-max-width) - var(--adaptive-mf-container-padding) - var(--filmstrip-gap) * (var(--filmstrip-visible-items) - 1)) / var(--filmstrip-visible-items) / 2 / 2 + 8px);
  }

  @media screen and (min-width: 1920px) {
    --filmstrip-visible-items: 5;
  }

  @media screen and (min-width: 1440px) {
    --filmstrip-visible-items: 4;
    --filmstrip-item-min-width: auto;
  }

  @media screen and (min-width: 1280px) {
    --filmstrip-gap: 32px;
  }

  @media screen and (min-width: 768px) {
    --filmstrip-visible-items: 3;
  }

  @media screen and (min-width: 1920px) {
    --filmstrip-item-min-width: 312px;
  }

  @media screen and (min-width: 1280px) {
    --filmstrip-item-min-width: 280px;
    --filmstrip-scroll-btn-top: calc(var(--filmstrip-item-min-width) / 4 + 8px);
  }
}

.offsetZone {
  flex: none;
  width: var(--filmstrip-container-horizontal-offset);
}

.scrollBtnWrapper {
  display: none;

  @media screen and (any-pointer: fine) {
    display: block;
  }
}

.scrollButtonCircleLeft,
.scrollButtonCircleRight {
  line-height: 1;
  fill: #131722;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 4px #0003;
  box-sizing: border-box;
  cursor: pointer;
  display: none;
  height: 50px;
  opacity: 0.8;
  padding: 17px 20px;
  position: absolute;
  transition: opacity 0.35s ease, fill 0.35s ease;
  width: 50px;
}

.scrollButtonCircleLeft {
  fill: #b2b5be;
  background-color: #2a2e39;
  box-shadow: 0 2px 4px #0006;
  display: block;
  left: calc(-16px + var(--filmstrip-container-horizontal-offset, 0px));
  opacity: 1;
  top: 32%;
  z-index: 15;
  transform: translateY(-50%);
}

.scrollButtonCircleRight {
  fill: #b2b5be;
  background-color: #2a2e39;
  box-shadow: 0 2px 4px #0006;
  display: block;
  right: calc(-16px + var(--filmstrip-container-horizontal-offset, 0px));
  opacity: 1;
  top: 32%;
  z-index: 1;
  transform: translateY(-50%);
}

.scrollButtonCircleLeft:hover,
.scrollButtonCircleRight:hover {
  fill: #2962ff;
}

.filmStripItems {
  display: flex;
  overflow: scroll;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  margin: 0 -8px;
  padding: 6px 0;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    display: none;
  }
}

.cardContainer {
  display: flex;
  position: relative;
  flex-grow: 1;
}

.card {
  cursor: pointer;

  --ui-lib-image-card-preview-border-radius: 8px;

  display: flex;
  flex-flow: column nowrap;
  position: relative;
  word-break: break-word;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 8px;
  color: #d1d4dc;
  flex-shrink: 0;
  min-width: var(--filmstrip-item-min-width);
  width: calc((60% - var(--filmstrip-gap) * (var(--filmstrip-visible-items) - 1)) / var(--filmstrip-visible-items));
  overflow: visible;
  height: auto;

  --filmstrip-visible-items: 2;
  --filmstrip-item-min-width: 272px;
  --filmstrip-gap: 16px;
  --filmstrip-scroll-btn-top: calc((100vw - var(--adaptive-mf-container-padding) - var(--filmstrip-gap) * (var(--filmstrip-visible-items) - 1)) / var(--filmstrip-visible-items) / 2 / 2 + 8px);

  @media screen and (min-width: 2560px) {
    --filmstrip-item-min-width: auto;
    --filmstrip-visible-items: 6;
    --filmstrip-scroll-btn-top: calc((var(--adaptive-mf-container-max-width) - var(--adaptive-mf-container-padding) - var(--filmstrip-gap) * (var(--filmstrip-visible-items) - 1)) / var(--filmstrip-visible-items) / 2 / 2 + 8px);
  }

  @media screen and (max-width: 2559px) and (min-width: 1920px) {
    --filmstrip-item-min-width: auto;
    --filmstrip-visible-items: 4;
    --filmstrip-gap: 32px;
  }

  @media screen and (max-width: 1919px) and (min-width: 1440px) {
    --filmstrip-item-min-width: auto;
    --filmstrip-visible-items: 3;
    --filmstrip-gap: 32px;
  }

  @media screen and (max-width: 1439px) and (min-width: 1280px) {
    --filmstrip-item-min-width: auto;
    --filmstrip-visible-items: 2.5;
    --filmstrip-gap: 32px;
  }

  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    --filmstrip-item-min-width: auto;
    --filmstrip-visible-items: 2;
    --filmstrip-gap: 32px;
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    --filmstrip-item-min-width: auto;
    --filmstrip-visible-items: 2;
    --filmstrip-gap: 32px;
  }

  @media screen and (max-width: 767px) and (min-width: 200px) {
    --filmstrip-item-min-width: auto;
  }
}

.card:hover {
  background-color: #2a2e39;
}

.card:hover svg>path:first-child {
  fill: #f00;
}
