@import '~shared-modules/styles/constants';

.wrapper {
  margin-right: 8px;
}

.flag {
  width: 24px;
  height: 18px;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}
