@import '~shared-modules/styles/constants';

.wrapper {
  margin: 4px 0 8px;
  font-size: 14px;
  line-height: 1.5;
  color: $webWhite;
}

.mobileWrapper {
  font-size: 16px;
  color: $webWhite;
}
