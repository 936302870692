.container {
  position: relative;
  background-color: var(--color-bg-normal);
  width: 100%;
  height: 100%;
}

.toolArea {
  display: flex;
  flex-direction: column;
  height: 100%;
}

$item-buttons-area-height: 40px;

.itemButtonsArea {
  padding: 0;
  height: $item-buttons-area-height;
}

.itemButtons {
  height: 40px;
}

.noScrollItemButtons {
  padding: 0 6px;
}

.tabArea {
  position: relative;
  height: 100%;
  padding-top: 10px;
}

$search-condition-height: 58px;

.searchCondition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-normal);
  user-select: none;
  height: $search-condition-height;
}

.tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  min-width: 155px;
}

.sort {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-normal);

  &:focus-visible {
    outline: none;
  }

  &:active,
  &:focus {
    color: var(--color-white);
  }

  div > i {
    font-size: 20px !important;
  }
}

.innerLabelWrapper {
  flex-direction: column;
}

.selectionArea {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow: auto;
  padding: 0 8px 6px;
  background-color: var(--color-bg-normal);
  height: 100%;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 37px);
  left: calc(50% - 16px);
  color: var(--color-white);
}

.noResultsText {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: var(--color-text-normal);
}
