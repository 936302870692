@import '~shared-modules/styles/constants';

.pageWrapper {
  align-items: flex-start;
  color: $webWhite;
  display: flex;
  justify-content: flex-start;
  min-height: 100%;
  flex-direction: column;
  padding: 20px 40px;
}

.pageTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 3;
}

.wrapper {
  width: auto;
  text-align: center;
}

.button {
  margin: 20px auto;
  border-radius: 10px;
}

.buttonRow {
  margin: 20px 40px;
}

.description {
  margin: 20px 30px;
}

.paragraph {
  color: $mobileWhite;
  margin: 5px 30px;
  text-decoration: underline;
  font-weight: 700;
}

.paragraphNoLine {
  color: $mobileWhite;
  margin: 5px 30px;
  font-weight: 700;
}
