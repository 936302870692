@import '~shared-modules/styles/constants';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 13px;
  color: $webWhite;

  &:first-child {
    margin-top: 8px;
  }
}

.marginLabel {
  display: flex;
}

.infoIcon {
  font-size: 17px;
  color: $webWhite;
}

.infoWrapper {
  margin-left: 6px;
}

.addButton {
  margin-top: 24px;
}

.marginValue {
  font-family: var(--font-family-ascii);
  font-weight: bold;
}
