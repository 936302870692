@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
  height: 24px;
  width: 24px;

  &.isBig {
    height: 40px;
    width: 40px;
  }
}

.loader {
  color: $webWhite;
  height: 24px;
  width: 24px;
}

.icon {
  color: $webWhite;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  height: 24px;
  width: 24px;

  &:focus,
  &:hover {
    background-color: var(--color-hover);
    outline: none;
  }

  &.isGrey {
    background-color: $webGrey;

    &:focus,
    &:hover {
      background-color: $webGreyHover;
      outline: none;
    }
  }

  &.isBig {
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
  }

  &.isLoading {
    cursor: default;
    opacity: 0.5;
  }
}

.itemList {
  background-color: $webGrey;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 3px 0 $webPureBlack;
  margin: 0;
  min-width: unset;
  padding: 0;

  &.inTable {
    transform: translate(-26px, -4px) !important; // stylelint-disable-line
  }

  &.isNonBordered {
    min-width: 188px;
  }
}

.item {
  align-items: center;
  box-shadow: inset 0 -1px 0 0 $webShadowLabelColor;
  color: $webWhite;
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: flex-end;
  padding: 0 24px;
  width: 100%;

  &:last-child {
    box-shadow: none;
  }

  &:focus,
  &:hover {
    background-color: $webGreyHover;
    color: $webWhite;
    outline: none;
    text-decoration: none;
  }

  &.isBig {
    border-radius: 2px;
    box-shadow: 0 -1px 4px 2px $webShadowColor;
    height: 40px;
  }

  &.isNonBordered {
    padding: 0 16px;
    justify-content: flex-start;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 20%);

    &:last-child {
      box-shadow: none;
    }
  }

  &.isDisabled {
    color: $webWarmGrey;
  }
}
