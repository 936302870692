@import '~shared-modules/styles/constants';

.wrapper {
  display: grid;
  grid-template-areas: 'sidebar header' 'sidebar content';
  grid-template-rows: var(--app-header-height) 1fr;
  grid-template-columns: 56px 1fr;
  min-width: 1065px;
  min-height: 500px;

  &.withoutSidebar {
    grid-template-areas: 'header header' 'content content';
  }
}

.loginWapper {
  min-width: 1065px;
  min-height: 500px;
  height: 100%;

  &.withoutSidebar {
    grid-template-areas: 'header header' 'content content';
  }
}

.sidebar {
  position: sticky;
  top: 0;
  z-index: 10;
  grid-area: sidebar;
  height: 100vh;

  // width: 60px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  grid-area: header;
  border-bottom: 1px solid $webPureBlack;
}

.content {
  grid-area: content;
  height: 100%;
  border-left: 1px solid $webPureBlack;

  &.withoutSidebar {
    border-left: none;
  }

  > div {
    min-height: 500px;
  }
}
