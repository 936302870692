.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.svg {
  transform: rotate(270deg);
}

.bgCircle {
  stroke: var(--color-text-gray);
}

.circle {
  transition: stroke-dasharray 600ms ease;
}

.text {
  position: absolute;
  top: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
