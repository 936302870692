@import '../common';

$maxHeight: calc(100vh - var(--app-header-height) - 40px - 55px - 130px - 60px - 40px - 80px);
$minHeight: 200px;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 130px;

    .title {
      padding: 10px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-text-normal);
    }

    .annotation {
      padding: 4px 0;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: var(--color-text-normal);
    }

    .link {
      display: inline-block;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      padding: 6px 0;
    }
  }

  .contentContainer {
    width: 100%;
    max-height: $maxHeight;
    min-height: $minHeight;
    margin: auto 0;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      width: 100%;
      height: 100%;
    }
  }
  @extend %error;
}

.gridWrapper {
  width: 100%;
  height: 100%;

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 320px);
    gap: 24px;
    justify-content: center;
    align-items: center;
    align-content: start;
    max-height: $maxHeight;
    min-height: $minHeight;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-corner {
      display: none;
    }
  }
}
