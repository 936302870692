.container {
  border-radius: 10px;
  background-color: #313131;
  padding: 10px 30px 20px;
  min-height: 160px;
  color: var(--color-text-gray);
}

.grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
  justify-content: space-around;
}

.item {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin-right: 40px;
  height: 60px;
  margin-top: 10px;
}

.row {
  display: flex;
  align-items: center;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-text-lightgray);
}

.value {
  padding-top: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
}

.symbol {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
}
