.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  width: 100%;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-gray);
}

.bold {
  font-weight: 600;
}

.link {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 8px 0;
  width: 100%;
}

.withHelp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.help {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.helpText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.margin {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.marginRate {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
}

.marginRateSymbol {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.labelContainer {
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 21px;
  color: var(--color-text-lightgray);
}
