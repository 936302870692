@import '~shared-modules/styles/constants';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: card;
  align-items: center;
  padding: 24px 16px 16px;
  color: $webWhite;
  text-align: center;
  cursor: default;
  background-color: $webBackgroundGrey;
  border-radius: 2px;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.currencyPairIcon {
  position: relative;
  margin-bottom: 22px;
}

.priceDifferenceRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 9px;

  &.etf {
    display: grid;
    grid-template-columns: 1fr 33px 1fr;
    align-items: flex-end;
    width: 100%;
    padding: 0 8px;
  }

  &.cfd {
    display: grid;
    grid-template-columns: 1fr 33px 1fr;
    align-items: flex-end;
    column-gap: 6px;
    width: 100%;
    padding: 0 4px;
  }
}

.calculatedDifference {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 14px;
  margin: 0 18px 0 8px;
  font-size: 11px;
  color: $webWhite;
  background-color: $webGrey;
  border-radius: 8px;

  &.etf {
    margin: 0;
  }

  &.cfd {
    margin: 0;
  }
}

.cardValueContainer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  gap: 0 10px;
  margin-top: 15px;
}

.detailContainer {
  position: relative;
  flex: 1;
  width: 208px;
  margin: 0 8px;
  font-size: 13px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.details {
  margin-bottom: 16px;
}

.valueStyle {
  font-size: 13px;
  font-weight: bold;
}

.itemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-text-lightgray);
}

.value {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
}

.symbol {
  font-size: 15px;
  line-height: 1.3;
}
