@keyframes pulseMotion {
  0% {
    transform: translate(-50%, -50%) scale(1, 1);
    background-color: #fb4b5366;
  }

  100% {
    transform: translate(-50%, -50%) scale(4, 4);
    background-color: #fb4b5300;
  }
}

.marker {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: background-color cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
  background-color: #fb4b53;
  z-index: 1;
}

.marker::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: opacity linear 0.4s;
  animation: pulseMotion 1.4s linear infinite;
  z-index: 1;
  content: '';
}
