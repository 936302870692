.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 13px;
  color: var(--color-white);

  &:first-child {
    margin-top: 8px;
  }
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text-normal);
}

.buttonArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headline {
  padding: 10px 0;
}

.assetDetail {
  margin: 8px 0;
}

.marginArea {
  padding: 0 6px 20px;
}
