@import '~shared-modules/styles/constants';

@keyframes disappearing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.wrapper {
  font-family: var(--font-family-ascii);
  padding-right: 16px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  justify-self: flex-end;
  position: relative;
  text-shadow: 0 2px 0 $webShadowLabelColor;
}

.isClosed {
  color: $webWarmGrey;
}

.arrowIcon {
  animation: disappearing 2s;
  opacity: 1;
  position: absolute;
  right: 0;
  bottom: 2px;
  transform: rotate(-45deg);

  // stylelint-disable-next-line
  path[class='arrowIndicator'] {
    fill: $webDarkSkyBlue;
  }

  &.red {
    transform: rotate(45deg);

    // stylelint-disable-next-line
    path[class='arrowIndicator'] {
      fill: $webPinkishOrange;
    }
  }
}
