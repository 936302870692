.container {
  display: flex;
  align-items: center;
  height: 40px;
}

.title {
  padding: 0 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-text-lightgray);
}

.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;

  > div {
    &:first-child {
      padding-left: 10px;
    }

    &:not(:first-child) {
      padding-left: 40px;
    }
  }
}

.button {
  height: 18px;
  min-width: 52px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ccc;
}

.active {
  border-radius: 10px;
  background-color: #ccc;
  color: var(--color-black);
}
