@import '~shared-modules/styles/constants';

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 12px 0 16px;
  font-size: 11px;
  color: $webWhite;
  cursor: pointer;
  background-color: $webBackgroundBlack;
  outline: none;
  box-shadow: inset 0 -1px 0 0 $webPureBlack;
  transition: 0.3s;

  &.isActive {
    box-shadow: none;
  }

  &:hover,
  &:focus {
    background-color: $webBackgroundGrey;
  }
}

.cardBody {
  padding: 16px;
  font-size: 12px;
  color: $webWhite;
  text-align: left;
  background-color: $webBackgroundBlack;
  box-shadow: inset 0 -1px 0 0 $webPureBlack;
}

.arrowIcon {
  font-size: 16px;
  color: $webWhite;
  transition: 0.3s;

  &.isActive {
    transform: rotate(180deg);
  }
}
