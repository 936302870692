.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.marginRight {
  margin-right: 10px;
}
