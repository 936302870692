@import '~shared-modules/styles/constants';

.wrapper {
  cursor: default;
  max-width: 800px;
  transform: unset !important; // stylelint-disable-line
  transition: unset !important; // stylelint-disable-line
  user-select: none;
  width: max-content;

  > div {
    background-color: transparent;
    border: none;
  }
}

.backdrop {
  opacity: 0.5 !important; // stylelint-disable-line
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
}

.closeIcon {
  color: $webWhite;
  cursor: pointer;
  font-size: 20px;
  outline: none;
}

.body {
  max-height: 600px;
  width: auto;

  &.withPopupUrl {
    cursor: pointer;
  }
}
