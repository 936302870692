$meter-area-width: 320px;
$meter-title-height: 32px;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--color-table-header);
  overflow: hidden;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.headerRowGroup {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border-bottom: 1px solid var(--color-bg-normal);
}

.termSelectArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-normal);
}

.minItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-right: 1px solid #000;
  height: 100%;
  min-width: 190px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-right: 1px solid #000;
  height: 100%;
  min-width: 220px;
}

.notes {
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
  height: 100%;
}

.label {
  padding-right: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: var(--color-text-lightgray);
}

.labelContainer {
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: var(--color-text-lightgray);
}

.tooltip {
  margin-left: 1px;
}

.content {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid var(--color-bg-normal);
}

.chart {
  position: relative;
  overflow: hidden;
  width: calc(100% - $meter-area-width);
  height: 100%;
  padding: 0 12px;
  background-color: var(--color-bg-chart);
}

.meter {
  width: $meter-area-width;
  height: 100%;
  border-left: 2px solid var(--color-black);
}

.meterTitle {
  padding: 8px 10px;
  height: $meter-title-height;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.5;
  color: var(--color-text-normal);
}

.tabs {
  height: calc(100% - $meter-title-height);
  background-color: var(--color-table-header);
}

.emptyValues {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.value {
  font-weight: 800;
  font-size: 13px;
  line-height: 19px;
  color: var(--color-text-normal);
}

.risk {
  font-weight: 800;
  font-size: 13px;
  line-height: 19px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.termSelectTitle {
  min-width: 150px;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: var(--color-white);
}
