.wrapper {
  display: grid;
  grid-template-areas: 'card chart' 'table table';
  grid-template-rows: 382px 293px;
  grid-template-columns: 256px auto;
  gap: 12px;
  color: var(--color-text-normal);
}

.chartTabs {
  grid-area: chart;
}

.tabs {
  background-color: var(--color-table-header);
}
