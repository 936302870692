@import '~shared-modules/styles/constants';

.bodyWrapper {
  margin: 0 20px;
  color: $webWhite;
}

.header {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 16px;
  font-size: 13px;
  font-weight: bold;
}

.title {
  margin-top: 8px;
  font-size: 21px;
  line-height: 2.5;
}

.text {
  font-size: 14px;
  line-height: 1.5;
}

.divider {
  border: 0;
  border-top: 2px solid $webGrey;
}

.secondHeader {
  margin-top: 22px;
  font-size: 13px;
  font-weight: bold;
}

.button {
  margin: 32px auto 0;
}
