@import 'shared-modules/styles/constants';

.wrapper {
  position: relative;
  display: grid;
  grid-template-areas: 'side-menu content';
  grid-template-columns: 344px 1fr;
  height: calc(100vh - var(--app-header-height));

  // min-height: 643px;
  background-color: $webPureBlack;
}

.sideMenu {
  grid-area: side-menu;
  overflow: hidden;
  position: relative;
  border-right: 1px solid $webPureBlack;
  width: 100%;
  height: 100%;
}

.content {
  grid-area: content;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.upperPane {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.newsRow {
  border-bottom: 1px solid $webPureBlack;
  width: 100%;
}

.chartRow {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.currencyPair {
  border-right: 2px solid $webPureBlack;
  width: 325px;
}

.chart {
  position: relative;
  width: 100%;
  height: 100%;
}

.border {
  height: 5px;
  cursor: row-resize;
  background-color: $webBackgroundGrey;
}

.table {
  position: relative;
  overflow: hidden;
  border-top: 1px solid $webPureBlack;
  width: 100%;
  height: 100%;
}
