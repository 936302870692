@import '~shared-modules/styles/constants';

.sideMenuWrapper {
  display: grid;
  height: 100%;
}

.tabContainer {
  height: none;
}

.topWrapper {
  padding: 16px;
  margin-top: 24px;
}

.currencyWrapper {
  display: flex;
  justify-content: space-between;
}

.speedOrderButton {
  padding: 0 10px 0 4px;
}

.speedOrderButtonTextContainer {
  display: flex;
  align-items: center;
}

.speedOrderText {
  margin-left: 2px;
  font-size: 13px;
}

.sellBuyPrices {
  margin-top: 16px;
}

.containerClassName {
  grid-auto-columns: auto;
  height: 30px;
  margin-top: 12px;
  margin-bottom: 30px;
}

.itemClassName {
  width: 62.4px;
  padding: 0;
  font-size: 13px;
}

.middleBlockWrapper {
  padding-bottom: 20px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.bottomWrapper {
  padding: 16px;
  box-shadow: inset 0 1px 0 0 $webWhiteOpacity30;
}

.isBuySellPriceDisabled {
  opacity: 0.5;
}

.serviceTabsContainer {
  height: 30px;
}

.markerOrderPricePanel {
  padding: 16px;
}
