@import '~shared-modules/styles/constants';

.wrapper {
  align-items: center;
  background-color: $webBackgroundBlack;
  cursor: default;
  display: flex;
  height: calc(100vh - 57px);
  justify-content: center;
  min-height: 643px;
  position: relative;
}

.loader {
  color: $webWhite;
  position: absolute;
}
