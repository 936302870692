@import '~shared-modules/styles/constants';

.container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 20px;
}

.noMessage {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-gray);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.accordionTitle {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $webWhite;
}

.accordionDate {
  display: flex;
  flex-grow: 0;
  margin-right: 16px;
  line-height: 1.27;
  color: var(--color-text-lightgray);
  font-size: 14px;
  font-weight: 300;
}

.unread {
  width: 48px;
  margin-right: 24px;
}

.title {
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.27;
  color: var(--color-text-normal);
}

.accordionBody {
  font-size: 14px;
  color: var(--color-text-normal);
  font-weight: 300;
}

.bottomLink {
  margin-top: 16px;
}

.linkButton {
  color: $webSellBlue;
  text-decoration: underline;
  cursor: pointer;
}

.linkButton:hover {
  color: var(--bs-link-hover-color);;
}
