@import '~shared-modules/styles/constants';

.highlightValue {
  color: $webGreen;

  &.isRed {
    color: $webPinkishOrange;
  }

  &.isColorless {
    color: unset;
  }
}
