$duration: 0.4s;

.container {
  position: relative;
  justify-content: center;
  align-items: center;
}

.meter {
  transform: rotate(210deg);
  border-radius: 50%;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meterAnimation {
  transition: stroke-dasharray $duration ease, stroke-dashoffset $duration ease;
}

.handleAnimation {
  transition: stroke-dashoffset $duration ease;
}
