@import '~shared-modules/styles/constants';

.emptyImage {
  position: relative;
  width: 80px;
  height: 45px;
}

.circleFrame {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 63px;
  margin: 0 auto;
  overflow: hidden;
  background-color: $webDarkBlueGrey;
  border-radius: 50%;
  object-fit: contain;
}

.imageWrapper {
  position: relative;
  width: 80px;
}

.imageStyle {
  width: 100%;
  user-select: none;
}

.circleFrameShare {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 63px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  object-fit: contain;
}
