.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-left: 4px;
  font-size: 18px;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-white);
}

.description {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-text-normal);
  white-space: pre-line;
}
