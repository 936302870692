.card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  min-width: 80px;
  height: 33px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-text-gray);
  text-align: center;
  white-space: pre-line;
  cursor: pointer;
  background-color: #313131;
  border-radius: 4px 4px 0 0;
  user-select: none;

  &.disabled {
    cursor: default;
    outline: none;
    opacity: 0.5;
  }

  &.disabledVisually {
    cursor: pointer;
  }

  &.active {
    color: var(--color-text-normal);
    background-color: var(--color-btn-primary-normal);
  }

  &:focus-visible {
    outline: none;
  }

  &:focus:not(.disabled, .active),
  &:hover:not(.disabled, .active) {
    outline: none;
    background-color: var(--color-white);
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 80px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-text-gray);
  text-align: center;
  white-space: pre-line;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: default;
    outline: none;
    opacity: 0.5;
  }

  &.disabledVisually {
    cursor: pointer;
  }

  &.active {
    color: var(--color-text-normal);
  }

  &:focus-visible {
    outline: none;
  }

  &:focus:not(.disabled, .active),
  &:hover:not(.disabled, .active) {
    outline: none;
    color: var(--color-text-normal);
  }
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.badgeContainer {
  position: relative;
  height: 100%;
  user-select: none;

  .badge {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1px;
    border-radius: 8px;
    padding: 0 6px;
    height: 12px;
    background-color: var(--color-badge-background);
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    color: var(--color-text-normal);
  }
}
