@import '../common';

.chartArea {
  height: 100%;
  width: 100%;
  padding-bottom: 8px;
}

.itemButtonsContainer {
  overflow: hidden;
  height: 50px;
  padding: 0 $padding-horizontal;
}

.borderWrapper {
  width: 100%;
  padding: 0 $padding-horizontal 2px $padding-horizontal;
}

.border {
  width: 100%;
  border-top: 1px solid var(--color-border-normal);
  height: 6px;
}

.chartContainer {
  position: relative;
  height: calc(100% - 60px);
  padding: 0 $padding-horizontal;
}
