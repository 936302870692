.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerWrapper {
  position: relative;
}

.meter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend {
  padding: 0 11px;
  width: max-content;
}

.legendRow {
  display: flex;
  align-items: center;
  padding: 2px 0;
}

.legendText {
  padding-left: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
}

.futureColor {
  color: var(--color-future);
}

.nowColor {
  color: var(--color-text-normal);
}

.futureFill {
  fill: var(--color-future);
}

.nowFill {
  fill: var(--color-text-normal);
}

.text {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 11px;
  color: var(--color-text-normal);
}

.attention {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.badge {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20.79px;
}

.badgeWarning {
  font-size: 22px;
  line-height: 33px;
}

.overview {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.iconWrapper {
  padding-bottom: 6px;
}

.tooltip {
  max-width: 190px;
}

.marginZeroContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  backdrop-filter: blur(4px);
  z-index: 1;
}

.marginZero {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cashButton {
  width: 100px !important;
  font-size: 14px !important;
  margin: auto;
  height: 32px !important;
  margin-top: 10px;
}

.isMarginZero {
  position: relative;
}
