@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
  grid-area: demo;

  // min-height: 350px;
  text-align: left;
}

.demoWrapper {
  display: flex;
  padding: 8px 0 20px;
}

.demo {
  height: 352px;
}

.demoTextWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 24px;
  color: $webWhite;
  text-align: left;
}

.demoTitle {
  font-size: 13px;
  font-weight: bold;
}

.demoDesc {
  margin-top: 13px;
  font-size: 14px;
}

.divider {
  height: 2px;
  background-color: $webDarkThree;
  border-radius: 0.5px;
}

.bottomText {
  padding: 14px 0;
  font-size: 12px;
  color: $webWhite;
  text-align: left;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}
