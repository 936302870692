$control-area-height: 60px;

.chartSettings {
  width: 100%;
  height: 100%;
}

.tabContent {
  background-color: var(--color-table-header);
}

.marginCounter {
  margin-left: 8px;
  font-weight: bold;
}

.cartIcon {
  margin-right: 10px;
  font-size: 20px;
}

.cartButton {
  margin-right: 16px;
}

.changeEditModeButton {
  margin-right: 16px;
}

.dropdownStyle {
  overflow: visible;
}

.center {
  text-align: center;
}
