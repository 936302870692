.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  position: relative;
  top: 1px;
  padding-right: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-text-normal);
}

.icon {
  font-size: 18px;
  color: var(--color-text-normal);
  cursor: pointer;
  user-select: none;

  &:focus-visible {
    outline: none;
  }

  &:hover,
  &:focus {
    color: var(--color-white);
  }
}
