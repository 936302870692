@import '~shared-modules/styles/constants';

.wrapper {
  color: $webWhite;
}

.tabContainer {
  position: relative;
  min-height: 440px;
  padding: 32px 32px 0;
  background-color: $webBackgroundGrey;
}

.settingsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  &.isOnNextRow {
    flex-direction: column;
    align-items: flex-start;
  }
}

.results {
  position: relative;
  min-height: 340px;
  margin-top: 24px;
}

.emptyResultsText {
  margin-top: 8px;
  font-size: 14px;
  color: $webDarkWhite;
  text-align: center;
}

.resultsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 14px 0 16px;
  font-size: 14px;
  font-weight: bold;
  color: $webWhite;
  cursor: pointer;
  background-color: $webGrey;
  outline: none;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover,
  &:focus {
    color: $webWhite;
    text-decoration: none;
    background-color: $webBackgroundGreyHover;
  }
}

.downloadIcon {
  font-size: 20px;
  color: $webWhite;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.fileLoader {
  width: 20px;
  height: 20px;
  color: $webWhite;
}

.downloadLink {
  display: none;
}

.warningText {
  margin-top: 8px;
  font-size: 12px;
  line-height: 2;
  color: $webWarningText;
}

.tabs {
  background-color: var(--color-table-header);
}
