@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 1280px;
  min-height: 820px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: $webWhite;
  overflow: hidden;
}

@media screen and (height < 820px) {
  .wrapper {
    height: 820px;
  }
}

.container {
  min-height: 820px;
  display: flex;
  flex-direction: row;
  max-width: 1380px;
  padding: 25px;
}

.containerLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50vw;
  min-height: 780px;
  min-width: 680px;
}

.containerPicture {
  height: 90%;
}

.mainPicture {
  width: 100%;
  height: 93%;
  position: relative;
}

.containerLeftSmall {
  display: flex;
  justify-content: space-around;
  gap: 5px;
  flex-direction: row;
  padding-bottom: 25px;
  max-width: 50vw;
  min-width: 640px;
}

.loader {
  position: absolute;
  color: $webWhite;
}

.containerRight {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-left: 5vw;
}

.containerLogin {
  padding-left: 30px;
}

.loginForm {
  position: relative;
  margin-top: 10px;
}

.loginTitle {
  max-height: 150px;
}

.title {
  font-size: 42px;
  color: $webBlack;
}

.subTitle {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: $webWarmGrey;
}

.loginInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}

.input {
  position: relative;
  margin-top: -5px;
  color: $webBlack;
  width: 500px !important;
  height: 50px;
  font-weight: bold;
  border-radius: 0%;
  border: 1px solid $webInactiveGrey;
  background-color: $webNotificationText;
  z-index: 0 !important;
  padding-left: 44px !important;

  &:focus {
    outline: none;
    border: 1px solid $webInactiveGrey;
  }

  &::placeholder {
    color: $webWarmGrey;
    opacity: 0.5 !important;
    position: relative;
    font-weight: bold;
    letter-spacing: 2px;
    z-index: 1 !important;
  }
}

.personIconWrapper {
  position: absolute;
  left: 15px;
}

.personIcon {
  color: $webWarmGrey;
  opacity: 0.8 !important;
}

.idError {
  position: absolute;
  top: 140px;
  font-size: 14px;
  color: $webInputError;
}

.pwError {
  position: absolute;
  top: 75px;
  font-size: 14px;
  color: $webInputError;
}

.lockIconWrapper {
  position: absolute;
  left: 15px;
}

.lockIcon {
  color: $webWarmGrey;
  opacity: 0.8 !important;
}

.eyeIconWrapper {
  position: absolute;
  left: 455px !important;
}

.eyeIcon {
  color: $webWarmGrey;
  opacity: 0.8 !important;
  cursor: pointer;
}

.emergencyText {
  position: relative;
  top: 30px;
  max-width: 500px;
  color: $red !important;
  text-align: left !important;
  font-size: 14px;
}

.emergencyTextLink {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.imsEmergencyText {
  position: relative;
  top: 50px;
  max-width: 500px;
  text-align: left !important;
  font-size: 14px;
  color: #f00 !important;
}

.maintenanceText {
  position: relative;
  margin: 10px 0 0 40px;
  max-width: 450px;
  color: $red !important;
  font-size: 18px;
  padding: initial;
}

.DisabledConbutton {
  margin-top: 60px;
  height: 50px;
  width: 200px;
  font-size: 25px;
  border-radius: 30px;
  color: $webWhite;
  background-color: #ff007d !important;
}

.conbutton {
  margin-top: 60px;
  height: 50px;
  width: 200px;
  font-size: 25px;
  border-radius: 30px;
  color: $webWhite;
  background-color: #ff007d !important;

  &:hover {
    cursor: pointer;
    background-color: #ff007d;
    box-shadow: 3px 3px 3px 3px $webShadowColor;
  }

  &:active {
    cursor: pointer;
    border: 2px solid $black;
    background-color: #ff007d;
  }
}

.containerlink {
  display: flex;
  flex-direction: row;
  gap: 110px;
  padding-left: 20px;
}

.maintenanceLink {
  position: relative;
  top: 33px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #ff007d;
  cursor: pointer;

  &:hover {
    opacity: 0.6 !important;
  }
}

.linkInfo {
  position: relative;
  bottom: 40px;
}

.link {
  font-size: 17px;
  font-weight: bold;
  top: 50px;
  color: $black;
  cursor: pointer;

  &:hover {
    opacity: 0.6 !important;
  }
}

.link:first-child {
  margin-bottom: 50px;
}

.containerRightSmall {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 16%;
  gap: 40px;
}

.rightSmallItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:last-child {
    border-right: 0;
  }
}

.rightSmallItem>img {
  width: 14vw;
  min-width: 230px;
}

.iconsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.snsIcons {
  position: relative;
  cursor: pointer;
}

.xIcon {
  width: 36px;
  height: 36px;
}

// todo: temp, need remove
.temp {
  margin-top: 16px;
}

@media screen and (max-height: 760px) {
  .containerRight {
    justify-content: initial;
    margin-top: 10px;
  }
}
