@import '~shared-modules/styles/constants';

@keyframes disappearing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.wrapper {
  font-family: var(--font-family-ascii);
  align-self: flex-end;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  justify-self: flex-end;
  position: relative;
  text-shadow: 0 2px 0 $webShadowLabelColor;
}

.isClosed {
  color: $webWarmGrey;
}

.arrowIcon {
  animation: disappearing 2s;
  opacity: 0;
  position: absolute;
  right: -4px;
  top: -12px;
  transform: rotate(-45deg);

  // stylelint-disable-line
  path[class='arrowIndicator'] {
    fill: $webDarkSkyBlue;
  }

  &.isRed {
    transform: rotate(45deg);

    // stylelint-disable-line
    path[class='arrowIndicator'] {
      fill: $webPinkishOrange;
    }
  }
}
