@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
  align-items: center;
  width: 256px;
  height: 48px;
  padding-left: 8px;
  font-size: 14px;
  color: $webWhite;
  text-decoration: none;
  cursor: pointer;
}

.link {
  &:hover {
    color: $webWhite;
    text-decoration: none;
    background-color: $webBackgroundGrey;
  }
}

.inline {
  width: unset;
  padding: 0 10px 0 6px;
}

.icon {
  margin-right: 8px;
  font-size: 20px;
  color: $webGrey;
}
