.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-text-lightgray);
}

.value {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
}

.symbol {
  font-size: 15px;
  line-height: 1.3;
}
