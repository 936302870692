.container {
  position: relative;
  width: 100%;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
}

.path {
  fill: var(--color-text-danger);
}
