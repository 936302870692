@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
  align-items: center;
  height: 48px;
  background-color: $webBackgroundGrey;
}

.expandWrapper {
  display: flex;
  align-items: center;
  height: 48px;
}

.right {
  display: flex;
  margin: 0 20px 0 auto;
  font-size: 30px;
}

.title {
  padding: 12px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.label {
  right: 100px;
  position: absolute;
}
