@import '~shared-modules/styles/constants';

.flag {
  position: absolute;

  &:first-child {
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:last-child {
    right: 0;
    bottom: 0;
  }
}

.fxIconCanvas {
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $webDarkBlueGrey;
  border-radius: 50%;

  &.medium {
    width: 80px;
    height: 80px;

    &.fx {
      padding: 22px 14px;
    }

    .flag {
      width: 30px;
      height: 22px;
    }
  }

  &.small {
    width: 48px;
    height: 48px;

    &.fx {
      padding: 11px 6px;
    }

    &.etf {
      padding: 6px;
    }

    // TODO CFD 暫定で ETF のコピー
    &.cfd {
      padding: 6px;
    }

    .flag {
      width: 20px;
      height: 15px;
    }
  }

  &.littleTiny {
    width: 42px;
    height: 42px;

    &.fx {
      padding: 9px 5px;
    }

    &.etf {
      padding: 6px;
    }

    // TODO CFD 暫定で ETF のコピー
    &.cfd {
      padding: 6px;
    }

    .flag {
      width: 19px;
      height: 15px;
    }
  }

  &.tiny {
    width: 32px;
    height: 32px;

    &.fx {
      padding: 6px 4px;
    }

    .flag {
      width: 13px;
      height: 10px;
    }
  }
}
