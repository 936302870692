@import '~shared-modules/styles/constants';

$header-height: 46px;

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--color-black);
}

.headerBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0 12px;

  .itemButtonArea {
    button {
      font-size: 14px;
    }
  }
}

.tableWrapper {
  position: relative;
  height: calc(100% - $header-height);
}

.toolbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 10px;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: #2d2d2d;
  border: none;
  border-radius: 2px;

  &:disabled {
    background-color: #2d2d2d;
    color: #fff;
    opacity: 0.5;
  }
}

.itemButton {
  min-width: 80px;
}

.linkButton {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

.datePicker {
  display: flex;
  padding-right: 30px;
}

.datePickerText {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  line-height: 32px;
}
