@import '~shared-modules/styles/constants';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: card;
  align-items: center;
  padding: 18px 16px;
  color: var(--color-text-normal);
  text-align: center;
  cursor: default;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-default);
  width: 280px;
  min-height: 350px;
}

.avatarWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  height: 100px;
}

.currencyIcon {
  position: relative;
  margin-bottom: 15px;
}

.typeRow {
  position: absolute;
  top: 88px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
}

.typeItemWrapper {
  display: flex;
  width: 50%;
  margin-left: 4px;

  &.isReversed {
    justify-content: flex-end;
    margin-right: 4px;
    margin-left: 0;
  }
}

.typeItem {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 5px;
  font-size: 14px;
  background-color: $webGrey;
  border: 1px solid $webGrey;
}

.detailContainer {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 230px;
  font-size: 13px;
}

.buttonRow {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 6px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.numberStyle {
  font-family: var(--font-family-ascii);
  font-size: 13px;
  font-weight: bold;
}

.valueStyle {
  font-size: 13px;
  font-weight: bold;
}

.cartIcon {
  font-size: 24px;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.recentPerformanceIcon {
  height: 26px;
  margin-right: 5px;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.imageContainer {
  position: relative;
  width: fit-content;
}

.circleFrame {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  object-fit: cover;
}
