.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;
  min-height: 377px;
  padding: 30px 20px;
  margin: 0 24px;
  text-align: center;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-default);
}
