@import '~shared-modules/styles/constants';

.wrapper {
  cursor: default;
  transform: unset !important; // stylelint-disable-line
  transition: unset !important; // stylelint-disable-line
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 1140px;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    background-color: var(--color-table-header);
    border: none;
    border-radius: var(--border-radius-default);
  }

  &.isMobile {
    margin: 0 auto;

    > div {
      border-radius: 5px;
      background-color: $mobileComponentLightGrey;
    }
  }
}

$headerLeftRightPadding: 24px;

.header {
  color: $webWhite;

  > button {
    opacity: 1;
    outline: none;
    width: 24px;

    > span {
      color: $webWhite;
      display: block;
      font-size: 20px;
      font-weight: normal;
      height: 24px;
      line-height: 1;
      text-shadow: none;
    }

    &:hover,
    &:focus {
      background-color: $webSideMenuHover;
    }
  }
}

.body {
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  padding: 10px 24px;
  text-align: center;
  min-height: 512px;
  color: $webWhite;
  overflow: hidden;

  &.withoutPadding {
    padding: 0;
  }
}

.closeIcon {
  color: var(--color-text-normal);
  user-select: none;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 16px;
  width: 20px;
  height: 30px;
  font-size: 30px;
  margin-right: 4px;

  &:focus-visible {
    outline: none;
  }

  &:hover,
  &:focus {
    color: #fff;
  }
}

.title {
  padding: 20px 6px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-text-normal);
}

.nameWrapper {
  display: flex;
  line-height: 1.5;
  padding: 10px 24px;
  position: relative;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.name {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.bodyWrapper {
  display: grid;
  gap: 20px;
  grid-template-areas: 'labeledSwitch chart' 'card chart' 'table table' 'demo demo';
  grid-template-columns: 336px 800px;
  grid-template-rows: 15px 352px 278px auto;
}

.bodyWithoutSwitchWrapper {
  display: grid;
  gap: 10px;
  grid-template-areas: 'card chart' 'card chart' 'table table' 'demo demo';
  grid-template-columns: 280px 800px;
  grid-template-rows: 15px 342px 240px auto;
}

.equalSize {
  width: 168px;
}

.linkButton {
  position: absolute;
  right: 88px;
  top: 12px;
  width: unset !important;

  &.withoutCloseButton {
    right: 24px;
  }
}

.labeledSwitchWrapper {
  margin-bottom: 5px;
}

.statusBadgeWrapper {
  margin: auto 0;
}

.statusBadge {
  margin-left: auto;
  text-align: center;
  background-color: #f03;
  border-radius: 8px;
  color: $webWhite;
  font-size: 12px;
  height: 18px;
  width: 76px;

  &.isGreen {
    background-color: #3dcb89;
  }
}

.row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
