@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
  grid-area: chart;
  height: 100%;

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loader {
  color: $webWhite;
}

.chartAreaWrapper {
  position: relative;
  height: 100%;
}

.chartContainer {
  height: 100%;
}

.displayNone {
  display: none;
}
