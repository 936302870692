@import '../../variable';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-table-header);
  color: var(--color-white);
  width: 100%;
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: $side-menu-header-height;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
}
