@import '~shared-modules/styles/constants';

.react-datepicker-wrapper {
  align-self: center;
  overflow: hidden;
}

.react-datepicker__header {
  padding: 0;
  background-color: inherit;
  border: none;
  border-radius: 0;
}

.react-datepicker__month-container {
  width: 288px;
  height: 340px;
  padding: 3px 4px 9px;
  box-shadow: 0 0 5px -2px $webBlack;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day-name,
.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0;
  color: $webNotificationText;

  &.react-datepicker__day--outside-month {
    color: $webWhiteGrey;
  }
}

.react-datepicker__day {
  &.react-datepicker__day--outside-month {
    color: $webWhiteGrey;
  }

  &.react-datepicker__day--selected {
    color: $webDarkSkyBlue;
    background-color: inherit;
    border-radius: 0;

    > div > div:nth-child(2) {
      height: 4px;
      background-color: $webDarkSkyBlue;
    }
  }
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected {
  background-color: $webBackgroundGreyHover;
  border-radius: 0;

  &.isLighter {
    &.isLighter {
      background-color: $webGreyHover;
    }
  }
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  opacity: 0.5;

  &:hover,
  &:focus {
    background-color: $webBackgroundGrey;

    &.isLighter {
      background-color: $webGrey;
    }
  }
}
