.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88px;
  height: 17px;
  border-radius: 10px;
  padding: 2px 8px;
  background-color: var(--color-text-danger);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.3;
  color: var(--color-white);

  div {
    height: 100%;

    i {
      padding: 1px 0;
      font-size: 11px;
    }
  }
}

.tip {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-text-normal);
  white-space: pre-line;
}
