@import '~shared-modules/styles/constants';

.selectorsWrapper {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: default;
  border-radius: 4px;

  &:focus,
  &:hover {
    border-radius: 4px;
  }
}

.selectorsWithLock {
  display: flex;
  align-items: center;
}

.lockIcon {
  margin-right: 9px;
  font-size: 20px;
  color: $webWarmGrey;
}
