.button {
  align-items: center;
  background-color: var(--color-btn-primary-normal);
  border-radius: 10px;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
  justify-content: center;
  outline: 0;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 282px;

  &.secondary {
    background-color: var(--color-btn-secondary-normal);
    border: var(--color-btn-secondary-normal) solid 1px;

    &:active {
      background-color: var(--color-btn-secondary-pressed);
      border: var(--color-btn-secondary-pressed) solid 1px;
    }

    &.disabled {
      color: var(--color-text-gray);
      cursor: auto;
      background-color: var(--color-btn-secondary-disabled);
      border: var(--color-btn-secondary-disabled-border) solid 1px;
    }

    &:hover:not(.disabled, :active) {
      // border: var(--color-btn-secondary-normal) solid 1px;
      // background-color: transparent;
      // color: var(--color-btn-secondary-normal);
      background-color: var(--color-btn-secondary-pressed);
      border: var(--color-btn-secondary-pressed) solid 1px;
    }
  }

  &:active {
    background-color: var(--color-btn-primary-pressed);
    border: var(--color-btn-primary-pressed) solid 1px;
  }

  &.disabled {
    color: var(--color-text-gray);
    cursor: auto;
    background-color: var(--color-btn-primary-disabled);
    border: var(--color-btn-primary-disabled-border) solid 1px;
  }

  &:focus-visible {
    outline: none;
  }

  &:hover:not(.disabled, :active) {
    // border: var(--color-btn-primary-normal) solid 1px;
    // background-color: transparent;
    // color: var(--color-btn-primary-normal);
    background-color: var(--color-btn-primary-pressed);
    border: var(--color-btn-primary-pressed) solid 1px;
  }
}
