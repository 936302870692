@import '~shared-modules/styles/constants';

.selections {
  padding-top: 10px;
  margin-bottom: 10px;
}

.button {
  margin: auto;
  margin-top: 20px;
}

.description {
  color: var(--color-text-normal);
  font-size: 13px;
  font-weight: 300;
  text-align: center;
}

.image {
  width: 402px;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
