.text {
  font-family: var(--font-family-ascii);
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.symbol {
  font-family: var(--font-family-default);
  padding-left: 4px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-normal);
}
