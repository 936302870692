.search {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  min-width: 40px;
  font-size: 11px;
  line-height: 17px;
  font-weight: 300px;
  color: var(--color-text-normal);

  &:focus-visible {
    outline: none;
  }

  .icon {
    font-size: 20px;
    color: var(--color-text-gray);

    &.filtered {
      color: var(--color-text-primary);
    }
  }

  &:hover,
  &:active {
    color: var(--color-white);

    .icon {
      color: var(--color-white);

      &.filtered {
        color: var(--color-text-primary);
      }
    }
  }
}

.filtered {
  color: var(--color-text-primary);
}
