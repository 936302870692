@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
  height: 100%;
  background-color: $webPureBlack;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 7px;
  background-color: $webPureBlack;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.header {
  margin-left: 7px;
  font-size: 13px;
  font-weight: bold;
  color: $webWhite;
}

.tableHeaderRow {
  display: grid;
  grid-template-columns: 53px 81px 30px;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  padding: 0 35px;
}

.tableTitle {
  font-size: 11px;
  font-weight: bold;
  color: $webWhite;

  &.askTitle {
    justify-self: flex-end;
  }
}

.listContainer {
  height: calc(100% - 64px);
  overflow: auto;
  -ms-overflow-style: none;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}
