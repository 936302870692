.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;

    .icon {
      margin: 0 10px 0 0;
      position: relative;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: cover;
    }

    .title {
      text-align: left;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-text-normal);
    }
  }

  .content {
    padding: 0;
    width: 345px;
    height: 100%;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    .link {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: var(--color-text-danger);
      cursor: pointer;
    }
  }
}

.separator {
  background-color: #414141;
  height: 1px;
  width: 100%;
}

.label {
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-light);
}

.calculating {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.image {
  margin-left: 4px;
  margin-top: 10px;
}

.media {
  display: flex;
  text-align: left;
  width: 50%;
}

.body {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.rotateLeft45 {
  rotate: -45deg;
}

.rotate135 {
  rotate: 135deg;
}

.description {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  align-items: center;
  text-align: justify;
  color: var(--color-light);
}

.buttonArea {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin-top: 15px;
}

.detailServiceLabel {
  padding: 4px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-text-primary);
}

.nonAdmmitedBlock {
  margin-top: 10px;
  text-align: start;
  height: 57px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  color: var(--color-light);
}

.pointer {
  cursor: auto !important;
}

.riskHeight {
  height: auto;
}

.roi {
  font-family: var(--font-family-ascii);
  text-align: right;
}

.bodyTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  padding-bottom: 12px;
}
