.container {
  border-radius: 4px;
  background-color: var(--color-bg-card);
  padding: 8px 8px 0;
  margin-bottom: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  min-height: 27px;
}

.nameArea {
  display: flex;
  align-items: center;
  padding: 10px 0 2px;
}

.infoArea {
  padding-top: 12px;
}

.marginArea {
  padding: 4px 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.itemName {
  -webkit-box-orient: vertical; // stylelint-disable-line
  display: -webkit-box; // stylelint-disable-line
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  -webkit-line-clamp: 2;
  max-height: 35px;
  line-height: 1.5;
  width: 250px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  padding-left: 8px;
}

.noMaxHeight {
  max-height: none;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: var(--color-text-lightgray);
}

.value {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
}

.symbol {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.3;
}

.separator {
  background-color: #414141;
  height: 1px;
  width: 100%;
}

.button {
  border-radius: 8px;
  width: 72px;
  height: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag:not(:last-child) {
  padding-right: 6px;
}
