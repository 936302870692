@import '~shared-modules/styles/constants';

.wrapper {
  display: grid;
  padding-bottom: 20px;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  color: #8e8e8e;
  text-decoration: none;

  &.isExpanded {
    flex-direction: row;
    justify-content: start;
    padding: 12px 20px 12px 17px;
  }

  &.isActive {
    color: #fff;
    background-color: rgba(255, 255, 255, 20%);
  }

  &:focus,
  &:hover {
    color: $webWhite;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 20%);
    outline: none;
  }

  &.isDisabled {
    color: $webWarmGrey;
    cursor: default;
    user-select: none;
    background-color: transparent;
  }
}

.expandedIcon {
  font-size: 20px;
}

.text {
  padding-top: 3px;
  font-size: 10px;
  font-weight: 600;

  &.isExpanded {
    padding-top: unset;
    margin-left: 10px;
    font-size: 14px;
  }
}

.counter {
  position: absolute;
  top: 2px;
  left: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  font-size: 8px;
  background-color: $webRed;
  border-radius: 20px;
  color: $webWhite;
  font-weight: 600;

  &.isExpanded {
    top: 17px;
    right: 20px;
    left: unset;
    height: 20px;
    padding: 0 7px;
  }
}

.badge {
  position: absolute;
  top: -7px;
  right: -1px;
  z-index: 0;
  display: flex;
  align-items: center;
  padding: 0 4px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #fb4b53;
  border-radius: 8px;
}
