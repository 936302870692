.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;

  .icon {
    font-size: 25px;
    width: 18px;
  }

  .applyFilteringView {
    color: var(--color-text-primary);
  }

  &:hover,
  &:active {
    .icon {
      color: var(--color-white);
    }

    .applyFilteringView {
      color: var(--color-text-primary);
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.visible {
  color: var(--color-white);
}
