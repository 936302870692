.container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  background-color: #2d2d2d;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin: 0 8px;
  }
}
