.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: var(--color-text-normal);
  background-color: var(--color-bg-normal);

  .loader {
    position: absolute;
    top: calc(50% - 37px);
    left: calc(50% - 16px);
    color: #d9d9d9;
  }
}
