.containerWrapper {
  padding-top: 60px;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // margin: auto 0;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 39px;
      line-height: 58px;
      color: #fff;
    }

    .icon {
      padding: 10px 0;
    }

    .explanation {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #ccc;
    }

    .buttonArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .button {
        padding: 8px 0;
      }
    }

    .banner {
      padding: 20px 0 10px;

      &:focus-visible {
        outline: none;
      }
    }
  }
}
