.container {
  width: 60rem;
  height: 3.4rem;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  margin-bottom: 3rem;
}

.item {
  height: 100%;
}
