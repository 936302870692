@import '~shared-modules/styles/constants';

.wrapper {
  cursor: default;
  width: 400px;
  max-width: 400px;
  transform: unset !important; // stylelint-disable-line
  transition: unset !important; // stylelint-disable-line
  user-select: none;

  >div {
    background-color: var(--color-table-header);
    border: none;
  }
}

.backdrop {
  opacity: 0.5 !important; // stylelint-disable-line
}

.header {
  display: flex;
  flex-direction: column;
}

.headerClose {
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0 0;
}

.headerImage {
  text-align: center;

  img {
    margin-top: -24px;
    width: 79.55px;
    height: 64px;
  }
}

.headerTitle {
  text-align: center;
  padding: 24px;
  font-size: 16px;
  font-weight: bold;
}

.closeIcon {
  color: $webWhite;
  cursor: pointer;
  font-size: 24px;
  outline: none;
}

.message {
  font-size: 13px;
  line-height: 20px;
  font-weight: 300;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.bottomControl {
  display: flex;
  justify-content: center;
}

.button {
  width: 310px;
}
