@import-normalize;

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-future: #fffe48;
  --color-text-primary: #f45266;
  --color-text-normal: #dcdcdc;
  --color-text-disabled: #dcdcdc66;
  --color-text-ask: #fb6739;
  --color-text-bid: #3284ff;
  --color-text-plus: #42dd96;
  --color-text-minus: #df5656;
  --color-text-danger: #f00;
  --color-text-danger2: #ff0100;
  --color-badge-background: #f44b53;
  --color-text-safe: #34e279;
  --color-text-lightgray: #b8b8b8;
  --color-text-gray: #949393;
  --color-text-disabled-dark: #525252;
  --color-text-dark: #5f5f5f;
  --color-bg-normal: #010101;
  --color-bg-chart: #171717;
  --color-bg-card: #2a2a2a;
  --color-bg-dark: #2d2d2d;
  --color-table-header: #1d1d1d;
  --color-table-contents: #171717;
  --color-border-normal: #424242;
  --color-border-normal-lighten-20-per: #757575;
  --color-border-focus: #fff;
  --color-border-light: #585858;
  --color-hover: #434343;
  --color-btn-primary-normal: #f45266;
  --color-btn-primary-disabled: #f4526666;
  --color-btn-primary-disabled-border: #f4526610;
  --color-btn-primary-pressed: #c13546;
  --color-btn-secondary-normal: #575757;
  --color-btn-secondary-disabled: #57575766;
  --color-btn-secondary-disabled-dark: #3b3b3b66;
  --color-btn-secondary-disabled-border: #57575710;
  --color-btn-secondary-pressed: #373737;
  --border-radius-default: 6px;
  --app-header-height: 57px;
  --color-light: #949393;
  --color-background: #010101;
  --bs-body-font-size: 16px;
  --font-family-default: 'Hiragino Kaku Gothic Pro', 'Noto Sans JP', -apple-system, blinkmacsystemfont, sans-serif;
  --font-family-ascii: 'M PLUS 1p', -apple-system, blinkmacsystemfont, sans-serif;
  --color-fx: #ff4b1e;
  --color-etf: #ff007d;
  --color-cfd: #2b73df;
  --color-unread: #414141;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-display: swap;
  src: url('./assets/fonts/Noto_Sans_JP/NotoSansJP-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('M PLUS 1p'), url('./assets/fonts/M_PLUS_1p/MPLUS1p-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('M PLUS 1p'), url('./assets/fonts/M_PLUS_1p/MPLUS1p-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('M PLUS 1p'), url('./assets/fonts/M_PLUS_1p/MPLUS1p-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('M PLUS 1p'), url('./assets/fonts/M_PLUS_1p/MPLUS1p-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('M PLUS 1p'), url('./assets/fonts/M_PLUS_1p/MPLUS1p-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('M PLUS 1p'), url('./assets/fonts/M_PLUS_1p/MPLUS1p-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 1p';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('M PLUS 1p'), url('./assets/fonts/M_PLUS_1p/MPLUS1p-Black.ttf') format('truetype');
}

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: var(--font-family-default);
  height: 100%;
  overflow: hidden;
  color: var(--color-text-normal);
  background-color: var(--color-bg-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// stylelint-disable-next-line
#root {
  height: 100%;
  overflow: auto;
}

.three-line-legend {
  width: 96px;
  height: 70px;
  position: absolute;
  padding: 8px;
  font-size: 12px;
  color: #fff;
  text-align: left;
  z-index: 1000;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.long-text-tooltip {
  width: 200px !important;
}

.floating-tooltip-1 {
  width: 440px;
  height: 54px;
  position: absolute;
  padding: 8px;
  box-sizing: border-box;
  font-size: 12px;
  color: #000;
  background-color: rgb(255, 255, 255);
  text-align: left;
  z-index: 1000;
  left: 12px;
  pointer-events: none;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 10px;
}

.floating-tooltip-2 {
  font-family: 'Trebuchet MS', Roboto, Ubuntu, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  width: 150px;
  height: 60px;
  position: absolute;
  display: none;
  padding: 8px;
  box-sizing: border-box;
  font-size: 12px;
  color: #fff;
  background-color: #111;
  text-align: left;
  z-index: 1000;
  top: 12px;
  left: 12px;
  pointer-events: none;
  border: 1px solid rgb(21, 93, 66);
  border-radius: 2px;
}

.circle-pointer {
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: rgb(255, 255, 255);
  border: 10px solid rgba(64, 148, 232, 70%);
  z-index: 1000;
  pointer-events: none;
  border-radius: 16px;
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.core-range {
  position: absolute;
  z-index: 1;
  background-color: rgba(40, 143, 86, 30%);
}

.clickable {
  cursor: pointer;
  color: #000;
}

.default-bg {
  background-color: var(--color-bg-normal);
}

::placeholder {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}

.ytp-large-play-button {
  position: absolute;
  left: 50%;
  top: 30%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);

  @supports not (aspect-ratio: 16 / 9) {
    top: 50%;
  }
}

.ytp-large-play-button-bg {
  -webkit-transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  fill: #212121;
  fill-opacity: 80%;
}

.ytp-button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  text-align: inherit;
  font-size: 100%;
  font-family: inherit;
  cursor: default;
  line-height: inherit;
}

.ytp-button,
.ytp-button:focus {
  outline: 0;
}

.ytp-button:not([aria-disabled='true'], [disabled], [aria-hidden='true']) {
  cursor: pointer;
}

.loadingSection-VX3x4wdz {
  margin: 24px auto 0;

  @media screen and (min-width: 768px) {
    margin-top: 32px;
  }

  @media screen and (min-width: 480px) {
    min-width: 344px;
  }
}

.color-gray-YKkCvwjV.variant-secondary-YKkCvwjV {
  --ui-lib-button-default-color-content: #d1d4dc;
  --ui-lib-button-default-color-border: #434651;
  --ui-lib-button-default-color-bg: #0000;
}

.color-gray-YKkCvwjV {
  --ui-lib-button-default-color-focus-outline: #2962ffcc;
}

.stretch-YKkCvwjV {
  width: 100%;
}

.size-xlarge-YKkCvwjV {
  --ui-lib-button-default-padding-left: 31px;
  --ui-lib-button-default-padding-right: 31px;

  border-radius: 12px;
  height: 56px;
}

.button-YKkCvwjV {
  outline: none;
  overflow: visible;
  position: relative;
  align-items: center;
  background-color: var(--ui-lib-button-color-bg, var(--ui-lib-button-default-color-bg));
  border: 1px solid var(--ui-lib-button-color-border, var(--ui-lib-button-default-color-border));
  box-sizing: border-box;
  color: var(--ui-lib-button-color-content, var(--ui-lib-button-default-color-content));
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  padding-bottom: 0;
  padding-left: var(--ui-lib-button-padding-left, var(--ui-lib-button-default-padding-left));
  padding-right: var(--ui-lib-button-padding-right, var(--ui-lib-button-default-padding-right));
  padding-top: 0;
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
  text-transform: none;
  font: inherit;
  margin: 0;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0;
  text-shadow: none;
}

.size-xlarge-YKkCvwjV::after {
  border-radius: 16px;
}

.button-YKkCvwjV::after {
  border-color: var(--ui-lib-button-color-focus-outline, var(--ui-lib-button-default-color-focus-outline));
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  content: '';
  display: none;
  height: calc(100% + 10px);
  left: -5px;
  pointer-events: none;
  position: absolute;
  top: -5px;
  width: calc(100% + 10px);
  z-index: 1;
}

.content-YKkCvwjV {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.size-xlarge-YKkCvwjV .content-YKkCvwjV {
  font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.react-grid-item.react-grid-placeholder {
  background-color: #606060 !important;
  border-radius: 10px;
}
