@import '../common';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      padding: 10px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-text-normal);
    }
  }

  .contentContainer {
    .input {
      padding: 16px 0;
    }
  }
  @extend %error;
}
