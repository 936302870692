.wrapper {
  border-radius: 10px;
  padding: 1.4rem 2rem;
  font-weight: 300;
  background: #2a2a2a;
}

.modalHeader {
  border-bottom: 0;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%configCommon {
  background-color: #575757;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.detail {
  @extend %configCommon;

  width: 160px;
  height: 44px;
}

.config {
  @extend %configCommon;

  height: 30px;
  width: 140px;
}

%rowCommon {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.row {
  @extend %rowCommon;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #171717;
  padding: 20px 15px;
}

.inputRow {
  @extend %rowCommon;

  margin-bottom: 10px;
}

.label {
  color: var(--color-text-lightgray);
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

.value {
  color: #dcdcdc;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.paragraph {
  text-align: left;
  font-size: 1.5rem;
}

%firstLine {
  div:first-child {
    border-top-width: 1px;
    border-top-style: solid;
  }
}

.grid {
  display: inline-block;
  margin: 12px;

  @extend %firstLine;
}

.info {
  @extend %firstLine;
}

.button {
  margin: 20px auto 0;
}

.wrap {
  align-items: center;
  display: flex;
  justify-content: right;
  height: 40px;
}

%block {
  background: #2a2a2a;
  border-radius: 10px;
  font-weight: 300;
  padding: 0 10px 19px;
  align-items: center;
  margin: 8px;
}

.leftBlock {
  @extend %block;

  margin-left: 0;
  margin-bottom: 0;
}

.rightBlock {
  @extend %block;

  margin-right: 0;
}

.sub {
  padding: 15px;
  font-weight: 600;
  color: #fff;
  display: flex;
}

.backdrop {
  z-index: 1055;

  &.backdrop {
    opacity: 0.7;
  }
}

.apInfo {
  display: grid;
  grid-template-areas: 'card chart' 'table table';
  grid-template-rows: 382px 293px;
  grid-template-columns: 256px auto;
  gap: 12px;
  color: var(--color-text-normal);
}

.chartTabs {
  grid-area: chart;
}

.tabs {
  background-color: var(--color-table-header);
}

.modalBody {
  flex-direction: row;
  display: flex;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 690px;
}

.modalBody::-webkit-scrollbar {
  display: none;
}

.portfolios {
  grid-gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.over {
  height: 310px;
  overflow-x: scroll;
  text-align: left;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.toolbar {
  text-align: left;
}
