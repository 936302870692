@import '~shared-modules/styles/constants';

.bodyWrapper {
  color: $webWhite;
}

.header {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 16px;
  font-size: 13px;
  font-weight: bold;
}

.text {
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.5;
}

.secondHeader {
  margin-top: 22px;
  font-size: 13px;
  font-weight: bold;
}

.button {
  margin: 32px auto 0;
}
