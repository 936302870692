@import '~shared-modules/styles/constants';

.bodyText {
  margin-top: 4px;
  font-size: 14px;
  line-height: 1.5;
  color: $webWhite;
  text-align: left;
  white-space: pre-line;
}

.buttonRow {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
