.button {
  align-items: center;
  background-color: #2d2d2d;
  border-radius: 8px;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  width: 100%;
  justify-content: center;
  outline: 0;
  overflow: hidden;
  padding: 4px 12px;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  &:active {
    background-color: #585858;
  }

  &:focus-visible {
    outline: none;
  }

  &:hover:not(.disabled, :active) {
    background-color: #585858;
  }
}

.active {
  background-color: #585858;
}
