.modal {
  cursor: default;

  > div {
    background-color: var(--color-table-header);
    border: none;
    border-radius: var(--border-radius-default);
  }
}

.backdrop {
  &.backdrop {
    opacity: 0.7;
    z-index: 1055;
  }
}

.header {
  padding: 20px 24px 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .close {
    color: var(--color-text-normal);
    user-select: none;
    width: 20px;
    height: 30px;
    font-size: 30px;
    margin-right: 4px;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.body {
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  padding: 0 24px 24px;
  text-align: center;
  min-height: 512px;
  color: var(--color-text-normal);
  overflow: hidden;

  .title {
    font-size: 24px;
    padding-bottom: 10px;
  }

  .content {
    position: relative;

    .cropContainer {
      position: relative;
      min-height: 384px;
    }

    .zoomContainer {
      padding: 10px 0;

      > * input {
        width: 60%;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    > button {
      margin: 0 8px;
    }
  }
}
