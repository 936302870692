@import '../common';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      padding: 10px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-text-normal);
    }
  }

  .contentContainer {
    .content {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
      }

      .buttonArea {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
      }
    }
  }
  @extend %error;
}

.editArea {
  .input {
    padding: 16px 0;
  }
}

.imageArea {
  padding: 16px 70px 16px 0;

  .imageContainer {
    position: relative;

    &.disabled {
      opacity: 0.6;
      cursor: default;
    }

    .circleFrame {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
    }

    .change {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      background-color: #2b87dd;
    }
  }
}
