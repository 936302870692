@import '~shared-modules/styles/constants';

@mixin row($margin-top: 16px) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $margin-top;
}

.wrapper {
  font-size: 13px;
  color: $webWhite;

  &.isSettingsLoading {
    opacity: 0.5;
  }
}

.titleRow {
  font-weight: bold;
}

.orderTypeRow {
  @include row(13px);
}

.countRow {
  @include row;
}

.expirationTypeRow {
  @include row;
}

.button {
  margin-top: 22px;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.note {
  margin-top: 8px;
  font-size: 11px;
  text-align: right;
}
