$pressed-color: #cdcdcd;
$danger-color: #d60000;
$danger-color-disabled: #f009;
$pressed-danger-color: #ad0000;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  cursor: pointer;
  color: var(--color-text-normal);
  user-select: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  &.pressed {
    color: $pressed-color;

    > * {
      color: $pressed-color;
    }
  }

  &:focus-visible {
    outline: none;
  }

  &.disabled {
    color: var(--color-text-disabled);
    cursor: default;

    > * {
      color: var(--color-text-disabled);
      cursor: default;
    }
  }

  &:active {
    color: var(--color-text-normal);

    > * {
      color: var(--color-text-normal);
    }
  }

  &:hover,
  &:focus {
    color: var(--color-white);

    > * {
      color: var(--color-white);
    }

    &.pressed {
      color: $pressed-color;

      > * {
        color: $pressed-color;
      }
    }

    &.disabled {
      color: var(--color-text-disabled);
      cursor: default;

      > * {
        color: var(--color-text-disabled);
        cursor: default;
      }
    }
  }
}

.container.danger {
  color: $danger-color;

  &.pressed {
    color: $pressed-danger-color;

    > * {
      color: $pressed-danger-color;
    }
  }

  &.disabled {
    color: $danger-color-disabled;
    cursor: default;

    > * {
      color: $danger-color-disabled;
      cursor: default;
    }
  }

  &:active {
    color: $danger-color;

    > * {
      color: $danger-color;
    }
  }

  &:hover,
  &:focus {
    color: var(--color-text-danger);

    > * {
      color: var(--color-text-danger);
    }

    &.pressed {
      color: $pressed-danger-color;

      > * {
        color: $pressed-danger-color;
      }
    }

    &.disabled {
      color: $danger-color-disabled;
      cursor: default;

      > * {
        color: $danger-color-disabled;
        cursor: default;
      }
    }
  }
}

// primary と danger は同時に指定されない前提な為 primary に関しては lint 無効
/* stylelint-disable */
// primary lighten 4%
$color-primary-hover: #f56577;

.container.primary {
  color: var(--color-btn-primary-normal);

  &.pressed {
    color: var(--color-btn-primary-pressed);

    > * {
      color: var(--color-btn-primary-pressed);
    }
  }

  &.disabled {
    color: var(--color-btn-primary-disabled);;
    cursor: default;

    > * {
      color: var(--color-btn-primary-disabled);;
      cursor: default;
    }
  }

  &:active {
    color: var(--color-btn-primary-normal);

    > * {
      color: var(--color-btn-primary-normal);
    }
  }

  &:hover,
  &:focus {
    color: $color-primary-hover;

    > * {
      color: $color-primary-hover;
    }

    &.pressed {
      color: var(--color-btn-primary-pressed);

      > * {
        color: var(--color-btn-primary-pressed);
      }
    }

    &.disabled {
      color: var(--color-btn-primary-disabled);
      cursor: default;

      > * {
        color: var(--color-btn-primary-disabled);
        cursor: default;
      }
    }
  }
}
/* stylelint-enable */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
