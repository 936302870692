@import '../../../../components/Tabs/variables';
@import '../../common';

.container {
  height: calc(100vh - var(--app-header-height) - $header-height - $tab-height);
  overflow: hidden;
}

.scrollArea {
  position: relative;
  height: 100%;
  padding-bottom: 4px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.row:not(:first-child) {
  padding-top: 10px;
}

.emptyText {
  color: #d8d8d8;
  font-size: 14px;
  margin-top: 56px;
  text-align: center;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: var(--color-white);
}
