$icon-diameter: 120px;
$icon-border-width: 3px;

.modal {
  cursor: default;

  > div {
    background-color: var(--color-table-header);
    border: none;
    border-radius: var(--border-radius-default);
  }
}

.header {
  padding: 20px 24px 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .close {
    color: var(--color-text-normal);
    user-select: none;
    width: 20px;
    height: 30px;
    font-size: 30px;
    margin-right: 4px;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.body {
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  padding: 0 24px 24px;
  text-align: center;
  min-height: 512px;
  color: var(--color-text-normal);
  overflow: hidden;

  .title {
    font-size: 24px;
    padding-bottom: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;

    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, 120px);
      row-gap: 10px;
      column-gap: 30px;
      justify-content: center;
      align-items: center;
      align-content: start;
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-corner {
        display: none;
      }
    }
  }
}

.iconContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $icon-diameter + ($icon-border-width * 2);
  width: $icon-diameter + ($icon-border-width * 2);
  user-select: none;

  .iconImage {
    width: $icon-diameter;
    height: $icon-diameter;
    border-radius: 50%;
    object-fit: cover;

    &:hover:not(.upload),
    &:focus:not(.upload) {
      cursor: pointer;
      outline: $icon-border-width solid var(--color-border-focus);
    }
  }

  .description {
    position: absolute;
    top: 28px;
    left: 9px;

    .headline {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }

    .detail {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
    }

    color: #000;
  }

  .plusIconWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #000;

    .plusIcon {
      font-size: 34px;
      color: #3ebb6a;
    }
  }
}

.errorMessages {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-text-danger);
}

.hidden {
  display: none;
}
