.container {
  position: relative;
  display: flex;
  height: calc(100vh - var(--app-header-height));
  color: var(--color-white);
  background-color: var(--color-table-header);

  .sideBar {
    display: flex;
    flex-direction: column;
    width: 344px;
    border-right: 1px solid var(--color-black);

    .sideBarTitle {
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 10px;
      font-size: 13px;
      font-weight: 600;
      color: var(--color-white);
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .spacer {
      height: 20px;
    }

    .currencies {
      flex: 1;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-corner {
        display: none;
      }
    }
  }

  .chart {
    flex: 1;
  }

  .chartShort {
    flex: 1;
    height: calc(100vh - var(--app-header-height) - 40px);
  }

  .buttonGroup {
    display: flex;
    justify-content: center;
    height: 72px;
    padding: 16px;
    background-color: #2d2d2d;
  }
}

.chartHeader {
  display: flex;
  gap: 40px;
}

.techChartHeader {
  display: flex;
  padding-left: 10px;
}

.title {
  display: flex;
  font-size: 22px;
  font-weight: bold;
  flex-direction: column;
  text-align: center;
  padding: 3px 0;
}

.pageContainer {
  height: calc(100vh - var(--app-header-height));
}

.tabs {
  background-color: var(--color-table-header);
}
