@import '~shared-modules/styles/constants';

@mixin row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 16px;
}

@mixin cell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
}

.wrapper {
  position: relative;
  grid-area: chart;
  font-size: 13px;
  color: $webWhite;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.firstRow {
  @include row;

  justify-content: flex-start;
  padding: 0;
}

.title {
  @include cell;

  font-weight: bold;
}

.plRateContainer {
  @include cell;
}

.termContainer {
  @include cell;
}

.value {
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-family-ascii);
  font-size: 13px;
  font-weight: bold;
}

.plContainer {
  @include cell;
}

.secondRow {
  @include row;

  height: 30px;
}

.detailsRow {
  display: flex;
}

.comprehensiveEvaluation {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.tag {
  margin-left: 17px;
}

.recommendedMargin {
  display: flex;
  align-items: center;
}

.detailsValue {
  margin-left: 10px;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.warningButton {
  margin-right: 20px;
  margin-left: auto;
}

.chartWrapper {
  height: calc(100% - 81px);
}

.infoIcon {
  font-size: 17px;
  color: $webWhite;
}

.infoWrapper {
  margin-left: 6px;
}

.recentPerformanceIcon {
  height: 20px;
  margin-right: 5px;
}

.simulationDataEmptyText {
  margin: 10px 0;
  font-size: 16px;
}
