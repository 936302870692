.wrapper {
  padding: 4px 0 8px;
}

.redirectButton {
  margin: 20px auto 0;
}

.errorMessage {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text-normal);
  white-space: pre-line;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 37px;
}

.link {
  font-weight: bold;
  color: #d8d8d8;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--color-text-normal);
  }
}
