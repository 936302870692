.container {
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
}

.text {
  padding-top: 9px;
}
