@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--app-header-height));
  min-height: 643px;
  cursor: default;
  background-color: $webBackgroundBlack;
}

.loader {
  position: absolute;
  color: $webWhite;
}
