@import '~shared-modules/styles/constants';

.formatWrapper {
  display: flex;
  align-items: center;

  &.isOnNextRow {
    margin-top: 16px;
  }
}

.searchButton {
  margin-left: 24px;
}
