.container {
  display: flex;
  flex-direction: column;

  .label {
    padding: 4px 0;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-gray);
  }

  .value {
    display: flex;
    align-items: center;

    &.disabled {
      opacity: 0.6;
    }

    &.readonly {
      position: relative;
      @extend %readonly-attr;
    }
  }
}

%readonly-attr {
  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #8c8787;
    min-height: 48px;
    @extend %input;
    @extend %icon;
  }
}

%input {
  .input {
    display: flex;
    align-items: center;

    &.multi {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 6px 0 6px 10px;
      white-space: pre-line;
      line-break: anywhere;
      max-height: 85px;
      width: calc(100% - 30px);
      overflow: auto;

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-corner {
        display: none;
      }
    }
  }
}

%icon {
  .icon {
    position: absolute;
    right: 0;
    color: var(--color-text-gray);
    background-color: transparent;
    user-select: none;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus {
      color: var(--color-text-normal);
    }

    &.disabled {
      cursor: default;

      &:hover,
      &:focus {
        color: var(--color-text-gray);
      }
    }
  }
}
