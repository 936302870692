.excellentWrapper {
  background-color: var(--color-text-plus);
}

.veryGoodWrapper {
  background-color: var(--color-text-plus);
}

.goodWrapper {
  background-color: var(--color-text-normal);
}

.goodIcon {
  font-size: 20px;
}

.badWrapper {
  background-color: var(--color-text-minus);
}
