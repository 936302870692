@import '~shared-modules/styles/constants';

.wrapper {
  margin: 4px 0 8px;
  font-size: 14px;
  line-height: 1.5;
  color: $webWhite;
}

.loader {
  position: relative;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.failedMessageArea {
  max-height: 270px;
  padding: 0 4px;
  margin: 0 8px 24px;
  overflow-y: auto;
  font-size: 12px;
  line-height: 1.5;
  color: $webWhite;
  text-align: left;
  white-space: pre-line;
  background-color: $webBackgroundBlack;
}

.failedMessage {
  width: 100%;
  padding: 4px 2px;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
}
