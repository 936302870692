.container {
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;

    i {
      padding-left: 2px;
      font-size: 16px;
    }
  }
}

.tip {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-text-normal);
  white-space: pre-line;
}
