@import '~shared-modules/styles/constants';

@mixin row($margin-top: 12px) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $margin-top;
}

.wrapper {
  position: relative;
  font-size: 13px;
  color: $webWhite;
}

.emptyBlock {
  height: 300px;
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $webBackgroundBlackOpacity50;
}

.loader {
  position: absolute;
  top: calc(50% - 37px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.titleRow {
  margin-top: 6px;
  font-weight: bold;
}

.nameRow {
  @include row;
}

.nameSpan {
  display: flex;
  max-width: 80%;
  max-height: 35px;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.brandRow {
  @include row;
}

.buySellRow {
  @include row;
}

.orderTypeRow {
  @include row;
}

.orderTermsRow {
  @include row;
}

.countRow {
  @include row;
}

.priceRow {
  @include row(18px);
}

.ocoSecondValueRow {
  @include row(16px);
}

.datePickerRow {
  @include row;
}

.timePickerRow {
  @include row;
}

.expirationTypeRow {
  @include row(16px);
}

.newTransactionPriceRow {
  @include row(16px);
}

.settlementTitleRow {
  margin-top: 20px;
  font-weight: bold;
}

.settlementBuySellRow {
  @include row(14px);
}

.settlementOrderTerms {
  @include row(14px);
}

.settlementStopPrice {
  @include row(16px);
}

.settlementExpirationTypeRow {
  @include row(16px);
}

.settlementCount {
  @include row(14px);
}

.settlementLimitPrice {
  @include row(16px);
}

.limitPriceRow {
  @include row(16px);
}

.OcoOrderTermsRow {
  @include row(14px);
}

.stopPriceRow {
  @include row(14px);
}

.targetPositionHeader {
  margin-top: 18px;
  font-weight: bold;
}

.targetPositionPriceRow {
  @include row(14px);
}

.targetPositionNameRow {
  @include row(14px);
}

.targetPositionName {
  max-width: calc(100% - 70px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  position: relative;
  margin-top: 24px;
}

.number {
  font-family: var(--font-family-ascii);
}
