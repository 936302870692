.textWrapper {
  padding-top: 100px;
  text-align: center;
}

.errorMessageText {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text-normal);
  white-space: pre-line;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: var(--color-text-normal);
}

.link {
  font-weight: bold;
  color: #d8d8d8;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--color-text-normal);
  }
}
