@import '~shared-modules/styles/constants';

@mixin row($margin-top: 10px) {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: $margin-top;
  margin-bottom: 10px;
  height: 35px;
}

.wrapper {
  color: var(--color-text-lightgray);
  font-size: 13px;
  padding: 1px 16px;
  background-color: var(--color-table-header);
}

.title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buySellRow {
  @include row(10px);
}

.itemClassName {
  padding: 0 !important; // stylelint-disable-line
  font-size: 13px;
  width: 64px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.orderMethodRow {
  @include row;
}

.countRow {
  @include row;
}

.datePickerRow {
  @include row;
}

.timePickerRow {
  @include row;
}

.necessaryMarginRow {
  @include row;
}

.necessaryMargin {
  font-family: var(--font-family-ascii);
  font-weight: bold;
  letter-spacing: 0.5px;
}

.priceRow {
  @include row;
}

.expirationTypeRow {
  @include row;
}

.divider {
  background-color: $webGrey;
  height: 1px;
  margin-top: 16px;
  width: 100%;
}

.settlementOrderTitle {
  font-weight: bold;
  margin-top: 13px;
}

.settlementBuySellRow {
  @include row;
}

.settlementOrderMethodRow {
  @include row;
}

.settlementCountRow {
  @include row;
}

.settlementPriceRow {
  @include row;
}

.settlementExpirationTypeRow {
  @include row;
}

.switch {
  @include row;

  width: 100%;
}
