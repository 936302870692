%error {
  .errorMessageArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .errorMessage {
      padding-bottom: 10px;
      margin: auto;
      font-style: normal;
      font-size: 16px;
      font-weight: 600;
      color: var(--color-text-danger);
    }
  }
}
