@import '~shared-modules/styles/constants';

$info-width: 264px;

.simulatingChart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  font-size: 14px;
  background-color: var(--color-bg-chart);
  overflow: hidden;
}

.loaderWrapper {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);

  .loader {
    color: #d9d9d9;
  }
}

.warning {
  padding: 0 16px;
}

.currencyLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-size: 13px;
  font-weight: bold;
  color: $webWhite;
  box-shadow: inset -1px 0 0 0 $webPureBlack, inset 0 -1px 0 0 $webPureBlack;
}

.leftTitleBlock {
  display: flex;
}

.chartWrapper {
  width: 100%;
  flex: 1;

  &.existsInfo {
    width: calc(100% - $info-width);
  }
}

.chartBottomInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  background-color: $darkLightgrey;
}

.chartCriteriaWrapper {
  position: relative;
  padding: 10px 18px 10px 0;
}

.infoIcon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
}

.chartDate {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 13px;
  color: $webWhite;
  box-shadow: inset -1px 0 0 0 $webPureBlack, inset 0 -1px 0 0 $webPureBlack;
}

.infoIcon2 {
  font-size: 17px;
  color: $webWhite;
}

.header {
  display: flex;
  align-items: center;
  padding: 0;
  background-color: var(--color-table-header);
  height: 40px;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    border-right: 1px solid #000;

    // box-shadow: inset -1px 0 0 0 $webPureBlack, inset 0 -1px 0 0 $webPureBlack;
    color: var(--color-text-normal);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }
}

.termSelect {
  background-color: var(--color-black);
  min-height: 40px;
}

.termSelectTitle {
  min-width: 150px;
}

.toolBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  background-color: #000;

  > div {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ccc;

    &:first-child {
      padding-left: 20px;
    }

    &:not(:first-child) {
      padding-left: 50px;
    }

    .active {
      height: 18px;
      width: 52px;
      background-color: #ccc;
      border-radius: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #000;
    }

    .nonActive {
      height: 18px;
      width: 52px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }

    &.menu {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.label {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      color: var(--color-text-gray);
    }
  }
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  background-color: var(--color-bg-normal);
}

.infoWrapper {
  width: $info-width;
  min-width: $info-width;
  height: 100%;
  padding: 0;
  margin: 0;
}

.infoArea {
  background-color: var(--color-bg-chart);

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 10px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-bg-normal);
    }

    .label {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      color: var(--color-text-gray);

      .help {
        padding-left: 2px;
      }
    }

    .value {
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      line-height: 19px;
      color: var(--color-text-normal);

      &.plus {
        color: var(--color-text-plus);
      }

      &.minus {
        color: var(--color-text-minus);
      }
    }
  }
}
