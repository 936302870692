@import '~shared-modules/styles/constants';

.pageWrapper {
  display: grid;
  grid-template-areas:
    'title title'
    'side-menu chart'
    'side-menu table'
    'side-menu button-group';
  grid-template-rows: 69px 1fr 288px 52px;
  grid-template-columns: 376px auto;
  height: calc(100vh - var(--app-header-height));
  color: $webWhite;
  background-color: var(--color-bg-normal);
}

.titleWrapper {
  grid-area: title;
  text-align: center;
}

.title {
  display: flex;
  flex-direction: column;
  margin: 3px 0;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.sideMenuWrapper {
  display: flex;
  flex-direction: column;
  grid-area: side-menu;
  height: 100%;
  font-size: 13px;
  color: $webWhite;
  background-color: rgba(204, 204, 204, 12%);
  border-right: 1px solid $webPureBlack;
}

.sideMenuTitle {
  height: 39px;
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 $webShadowLabelColor;
  background-color: var(--color-bg-normal);
}

.sideMenuContent {
  margin: 8px 16px 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sideMenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-right: 20px;

  &.isRightAligned {
    justify-content: flex-end;
  }
}

.sideMenuBottom {
  width: 100%;
  height: 40px;
  padding: 16px;
  margin: auto auto 32px;
  box-shadow: inset 0 1px 0 0 #ffffff4c;
}

.chartWrapper {
  grid-area: chart;
}

.marginInfoRow {
  display: flex;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-normal);
  box-shadow: inset 0 -1px 0 0 $webPureBlack, inset 0 1px 0 0 $webPureBlack;
}

.recommendedMargin {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.detailsValue {
  font-family: var(--font-family-ascii);
  margin-left: 10px;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.infoIconWrapper {
  margin-left: 12px;
}

.infoWrapper {
  padding-right: 0;
}

.infoIcon {
  font-size: 17px;
  color: $webWhite;
}

.tableWrapper {
  grid-area: table;
  height: 100%;
}

.buttonGroupWrapper {
  display: flex;
  grid-area: button-group;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 52px;
  padding: 0 20px;
  background-color: #2d2d2d;
  box-shadow: 0 -2px 2px 0 #0003;
}

.buttonInnerStyle {
  margin-right: 16px;
}

.buttonInnerIcon {
  width: 20px;
  height: 24px;
  margin-right: 10px;
  font-size: 20px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 1000px;
  padding: 30px 24px;
  text-align: center;
  background-color: $webBackgroundGrey;
  border-radius: 2px;
  box-shadow: 0 2px 4px $webShadowColor;
}

.serviceButtonGroupWrapper {
  margin: 30px auto 0;
}

.serviceButtonGroup {
  display: flex;
  justify-content: space-between;
  width: 450px;
}

.alertModalBody {
  position: relative;
  font-size: 13px;
  color: $webWhite;
}

.message {
  height: 24px;
  font-size: 16px;
  text-align: center;
  white-space: pre-line;
}

.buttonRow {
  display: flex;
  justify-content: center;
  margin-top: 84px;
}

.chartContainer {
  width: 100%;
  height: calc(100% - 40px);
}
