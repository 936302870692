.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 270px;
  padding: 20px;
  margin: 0 24px;
  text-align: center;
  background-color: var(--color-bg-card);
  border-radius: var(--border-radius-default);

  .mainContainer {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 10px;
    height: 86px;
    border-bottom: 1px solid #414141;
  }

  .icon {
    width: 70px;
    height: 70px;
  }

  .titleContainer {
    width: 240px;

    .titleArea {
      padding: 4px 0;

      .title {
        float: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }
    }

    .summaryArea {
      padding: 2px 0;

      .summary {
        float: left;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
      }
    }
  }


  .detailArea {
    padding: 10px 18px 20px;

    .detail {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      color: var(--color-text-normal);
      text-align: left;
    }
  }

  .button {
    width: 310px;
  }
}
