.medium {
  width: 36px;
  height: 36px;
}

.small {
  width: 24px;
  height: 24px;
}

.littleTiny {
  width: 22px;
  height: 22px;
}

.tiny {
  width: 16px;
  height: 16px;
}

.fullSize {
  &.medium {
    width: 80px;
    height: 80px;
  }

  &.small {
    width: 48px;
    height: 48px;
  }

  &.littleTiny {
    width: 42px;
    height: 42px;
  }

  &.tiny {
    width: 32px;
    height: 32px;
  }
}
