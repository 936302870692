.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
}

.tableTabsContainer {
  display: flex;
  height: 36px;
  margin: 5px -0.5px -0.5px;
  border-bottom: 1px solid #f45266;
  width: 100%;
}

.tableTabItem {
  background-color: #313131;
  color: #dcdcdc;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  padding: 0 16px;
  line-height: 32px;
}

.calendarIcon {
  margin-left: 5px;
  font-size: 16px;
}

.calendarSeparator {
  font-size: 13px;
  color: var(--color-white);
}

%wrapperParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendarWrapper {
  @extend %wrapperParent;

  width: 300px;
  margin-right: 5px;
}

.button {
  margin-left: 8px;
  height: 24px;
  width: 100px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
}

.tabs {
  background-color: var(--color-table-header);
}

.linkButton {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

.datePicker {
  display: flex;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-top: 3px;
  height: 25px;
}

.dateTickerText {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}
