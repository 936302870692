.container {
  position: absolute;
  background-color: #2d2d2d;
  min-width: 200px;
  color: #fff;
  text-align: left;
  font-size: 12px;
  cursor: default;
  z-index: 1055;
  border-radius: 4px;
  padding: 4px 10px;

  input[type='radio'] {
    background-color: #273138;
    border-color: var(--color-text-normal);
    margin-right: 6px;
  }
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  width: 100%;
}

.filterHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.checkboxIcon {
  background-color: #273138;
  color: var(--color-text-normal) !important;
}

.radios {
  width: 100%;
}

.filterLabel {
  font-weight: 300;
  padding: 8px 0;
}

.filterOptionLabel {
  font-weight: 600;
}

.select {
  background-color: transparent;
  color: var(--color-text-normal);
  border: transparent;
  cursor: pointer;

  > option {
    background-color: var(--color-bg-card);
  }

  &:focus-visible {
    outline: none;
  }
}

.sortWrapper {
  display: flex;
  flex-direction: column;
  padding: 2px 0;
  width: 100%;
}

.datePickerContainer {
  width: fit-content;
}

.datePickerSeparator {
  transform: rotate(-90deg);
  width: fit-content;
  margin: 0 auto;
  font-size: 18px;
}

.button {
  margin: 6px auto;
  height: 30px;
  font-size: 12px;
}

.separator {
  height: 1px;
  background-color: var(--color-border-normal);
  margin: 8px 0;
}

.inShadow {
  z-index: 999;
}
