@import '~shared-modules/styles/constants';

.wrapper {
  display: grid;
  grid-template-columns: 22px 38px 1fr;
  align-items: center;
  justify-items: center;
  padding: 7px 8px 6px 0;
  color: $webWhite;
  cursor: pointer;
  user-select: none;
  background-color: $webPureBlack;
  border-bottom: 1px solid #414141;

  &:hover {
    background-color: $webBackgroundGrey;
  }
}

.draggableIcon {
  display: block;
  font-size: 20px;
  color: $webInactiveGrey;
}

.currencyBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  justify-self: flex-start;
  margin-left: 2px;

  .instrumentTitle {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: $webWhite;
  }
}

.flagContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 2px;
  align-items: center;
}

.isClosed {
  color: $webWarmGrey;
}

.currencyPairRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.isClosed {
    color: $webWarmGrey;
  }
}

.valuesInner {
  width: 100%;

  .instrumentName {
    width: 100%;
    margin-bottom: 8px;
    font-size: 11px;
    font-weight: bolder;
    text-align: left;
  }

  .valuesRow {
    display: grid;
    grid-template-columns: 1fr 54px 1fr;
    justify-items: center;
  }
}

.spread {
  font-family: var(--font-family-ascii);
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  width: 33px;
  height: 14px;
  margin-bottom: 2px;
  font-size: 11px;
  color: $webWhite;
  background-color: #313131;
  border-radius: 8px;

  &.isClosed {
    color: $webWarmGrey;
  }
}

.wrapperDisabled {
  display: grid;
  grid-template-columns: 22px 70px 1fr 54px 1fr;
  align-items: center;
  justify-items: center;
  padding: 7px 8px 6px 0;
  color: $webWhite;
  cursor: pointer;
  user-select: none;
  background-color: $webPureBlack;
  border-bottom: 1px solid #414141;

  &.isDisabled {
    cursor: default;
  }

  &:hover {
    background-color: $webBackgroundGrey;
  }
}


.currencyBlockDisabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-self: flex-start;
  margin-left: 2px;
}

.flagContainerDisabled {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 2px;
}

.currencyPairRowDisabled {
  font-size: 10px;
  font-weight: bold;
  color: $webWhite;

  &.isClosed {
    color: $webWarmGrey;
  }
}

.flagDisabled {
  width: 24px;
  height: 16px;
}
