.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.createTime {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-white);
}

.button {
  border-radius: 4px;
  height: 24px;
  width: 100px;
  font-size: 12px;
}

.card:not(:first-child) {
  padding-top: 2px;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2b2b2b;
  padding: 10px 20px;
}

.image {
  padding: 8px 8px 8px 0;
}

.row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.leftItem {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.rightItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  line-height: 40px;
  gap: 80px;
  font-size: 12px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 4px;
  width: 100%;
}

.tag:not(:last-child) {
  padding-right: 6px;
}

.summary {
  display: flex;
  align-items: center;
}

.name {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-white);
}

.label {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
  color: var(--color-white);
}

.input {
  background-color: #363636;
  border-radius: 4px;
  height: 28px;
  text-align: center;
  opacity: 0.9 !important;
}
