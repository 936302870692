@import '~shared-modules/styles/constants';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  display: grid;
  grid-template-rows: 56px 1fr;
  width: 278px;
  height: 100vh;
  overflow-y: auto;
  background-color: $webBlack;
  box-shadow: 2px 0 4px 0 $webPureBlack50Opacity;
}

.logoContainer {
  display: flex;
  align-items: center;
  align-self: center;
  padding: 0 11px;
}

.menuIcon {
  margin-right: 11px;
  font-size: 24px !important;
  color: $webWhite;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
}

.settingsContainer {
  display: grid;
  gap: 0;
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;
  color: $webWhite;
  text-decoration: none;
  cursor: pointer;

  &:focus,
  &:hover {
    text-decoration: none;
    background-color: $webSideMenuHover;
    outline: none;
  }
}

.icon {
  color: $webGrey;
}
