.bodyWrapper {
  color: var(--color-white);
}

.header {
  margin-top: 9px;
  font-size: 13px;
  font-weight: bold;
}

.text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.5;
}

.secondHeader {
  margin-top: 22px;
  font-size: 13px;
  font-weight: bold;
}

.button {
  margin: 32px auto 0;
}
