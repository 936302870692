@import '~shared-modules/styles/constants';

.bodyWrapper {
  color: $webWhite;
}

.text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.5;
}

.button {
  margin: 32px auto 0;
}
