@import '~shared-modules/styles/constants';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;
  margin-top: 16px;
  font-size: 13px;
  color: $webWhite;

  &.isCounterRow {
    justify-content: flex-end;
  }

  &.isTextRow {
    margin-top: 8px;
  }
}

.confirmButton {
  margin-top: 24px;
}

.textLabel {
  flex: 2;
}

.textValue {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 35px;
  overflow: hidden;
  text-align: end;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
}

.name {
  flex: 8;
}

.counterInput {
  margin-left: 8px;
}
