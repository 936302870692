@import '~shared-modules/styles/constants';

@mixin titleRow($margin-top: 0) {
  margin-top: $margin-top;
  font-size: 13px;
  font-weight: bold;
  color: $webWhite;
}

@mixin checkboxContainer($margin-top: 12px) {
  width: 100%;
  margin-top: $margin-top;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.newsTabItem {
  line-height: 12px; // stylelint-disable-line declaration-property-unit-whitelist
}

.wrapper {
  position: relative;
  width: 100%;
  min-height: 400px;
  padding: 24px;
  margin-right: 16px;
  background-color: $webBackgroundGrey;
}

.checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  margin-top: 8px;
  cursor: pointer;

  &:first-child {
    margin-top: unset;
  }

  &:hover {
    background-color: $webBackgroundGrey;
  }
}

.labelClassName {
  margin-left: 24px;
  font-size: 13px;
}

.mailingListHeader {
  @include titleRow;
}

.mailingListCheckboxContainer {
  @include checkboxContainer($margin-top: 8px);
}

.typeHeader {
  @include titleRow(20px);

  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
}

.typeCheckboxContainer {
  @include checkboxContainer($margin-top: 14px);

  display: flex;
}

.checkboxWrapper {
  &.etfColumn {
    margin-left: 24px;
  }
}

.serviceTitle {
  margin: 0;
  font-size: 12px;
  color: $white;
}

.button {
  margin-top: 24px;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.tabs {
  background-color: var(--color-table-header);
}
