.splitter {
  background-color: var(--color-border-normal);
  box-sizing: border-box;
  background-clip: padding-box;

  &:hover {
    background-color: var(--color-border-normal-lighten-20-per);
  }

  &.horizontal {
    cursor: row-resize;
    width: 100%;
  }

  &.vertical {
    cursor: col-resize;
    height: 100%;
  }
}
