@import '~shared-modules/styles/constants';

.wrapper {
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: max-content;
  max-width: 1000px;
  padding: 0;
  white-space: pre-line;
}

.notificationContainer {
  min-height: unset;
  padding: 0;
  margin-bottom: 24px;
  cursor: default;
  background-color: $webGrey;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 0 14px 0 17px;
  font-size: 12px;
  color: $webNotificationText;
  letter-spacing: 0.14px;
  border-left: 3px solid $webGreen;

  &.isYellow {
    border-color: $webNotificationWarning;
  }

  &.isRed {
    border-color: $webInputError;
  }
}

.messageContainer {
  display: flex;
  align-items: center;
  padding-right: 64px;
}

.messageIcon {
  margin-right: 14px;
  font-size: 20px;
  color: $webGreen;

  &.isYellow {
    color: $webNotificationWarning;
  }

  &.isRed {
    color: $webInputError;
  }
}

.closeIcon {
  font-size: 20px;
  color: $webWhite;
  cursor: pointer;
}
