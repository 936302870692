@import '~shared-modules/styles/constants';

.arrowIcon {
  animation: disappearing 2s;
  opacity: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  transform: rotate(-45deg);

  path[class='arrowIndicator'] {
    // stylelint-disable-line
    fill: $webIconWhite;
  }

  &.isRising {
    transform: rotate(45deg);
  }
}

.wrapper {
  background-color: $webDarkSkyBlue;
  color: $webWhite;
  cursor: pointer;
  outline: none;
  padding: 6px 8px;
  position: relative;
  width: 50%;
  margin: 1px;

  &.alignRight {
    text-align: right;

    .arrowIcon {
      left: 4px;
      right: unset;
    }
  }

  &.notActive {
    cursor: default;
    opacity: 1;
  }

  &:focus,
  &:hover {
    background-color: $webDarkSkyBlueHover;

    &.notActive {
      background-color: $webDarkSkyBlue;
    }
  }

  &.isBuy {
    background-color: $webPinkishOrange;

    &:focus,
    &:hover {
      background-color: $webRedHover;

      &.notActive {
        background-color: $webPinkishOrange;
      }
    }
  }

  &.grey {
    &,
    &:focus,
    &:hover {
      background-color: $webBrownGrey;
    }
  }
}

.type {
  font-size: 10px;
  letter-spacing: 0.86px;
  text-align: center;
}

.value {
  font-family: var(--font-family-ascii);
  font-size: 12px;
  font-weight: bold;
  margin-top: 2px;
  text-shadow: 0 2px 0 $webShadowLabelColor;
  text-align: center;
}

@keyframes disappearing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
