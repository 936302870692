.wrapper {
  cursor: default;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 1230px;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.container {
  width: 100%;
  height: 100%;

  .content {
    padding-top: 90px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 420px);
    gap: 60px;
    justify-content: center;
    align-items: center;
    color: var(--color-text-normal);
    background-color: var(--color-bg-normal);
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .cardHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ccc;

    .tag {
      padding-right: 8px;
    }
  }
}

.nameWrapper {
  display: flex;
  line-height: 1.5;
  padding: 0 24px 20px;
  position: relative;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.name {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.statusBadgeWrapper {
  margin: auto 0;
}

.statusBadge {
  margin-left: auto;
  text-align: center;
  background-color: #f03;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  height: 18px;
  width: 76px;

  &.isGreen {
    background-color: #3dcb89;
  }
}

.cartButton {
  padding: 0;
}

.confirmModalBody {
  color: #fff;
  font-size: 13px;
  position: relative;
}

.message {
  font-size: 16px;
  height: 24px;
  text-align: left;
}

.buttonRow {
  display: flex;
  justify-content: center;
  margin-top: 84px;
}

.buttonMargin {
  margin-right: 16px;
}

.stopLabButton {
  margin: auto;
}

.tabs {
  background-color: var(--color-table-header);
}

.tableTitle {
  font-weight: 600;
  font-size: 14px;
  margin: 10px 10px 6px;
  line-height: 130%;
  color: var(--color-text-normal);
}

.cancelButton {
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  height: 44px;
}

.blankWrapper {
  margin-top: 15px;
}

.loaderContainer {
  position: absolute;
  padding-top: 120px;
  width: 100%;
  margin: auto;
  z-index: 1;
}
