.container {
  position: relative;
  padding: 10px 20px;
  height: 100%;
  background-color: var(--color-table-header);
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.centerLabel {
  margin: 20px;
  text-align: center;
}

.marginArea {
  position: relative;
}

.rowAlignRight {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  width: 100%;
}

.forwardLink {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
