@import '~shared-modules/styles/constants';

.alt {
  color: $webBlack;
}

.description {
  margin: auto 20px auto 0;
}

.icon {
  font-size: 60px;
}

.image {
  background-color: $webWhite;
  width: 214px;
  height: 114px;
  position: relative;
  z-index: 1;
}

.imageWrapper {
  margin: 10px;
  position: relative;
  cursor: pointer;
}

.input {
  margin-left: 32px;
  margin-right: 4px;
}

.mainRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.select {
  background-color: var(--color-btn-primary-normal);
  border-radius: 4px 4px 0 0;
  font-size: 11px;
  left: -3px;
  padding: 2px 7px;
  position: absolute;
  top: -18px;
}

.selectWrapper {
  background-color: var(--color-btn-primary-normal);
  border-radius: 3px;
  height: 120px;
  left: -3px;
  position: absolute;
  top: -3px;
  width: 220px;
}

.row {
  align-items: center;
  color: $webWhite;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  margin-top: 14px;
}

.textRow {
  align-items: center;
  color: $webWhite;
  font-size: 15px;
  margin-top: 18px;
}


.flex {
  display: flex;
  margin-bottom: 20px;
}

.button {
  border-radius: 10px;
  margin: 10px 20px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  margin: 0 auto;
}

.textLink {
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}

.numberInput {
  border-radius: 2px;
  text-align: right;
}

.text {
  margin: 0 auto;
}

.termsWrapper {
  margin-top: 16px;
  height: 300px;
  background-color: $webWhite;
  overflow: scroll;
}

.termsText {
  margin: 0 10px;
  background-color: $webWhite;
  white-space: pre-line;
}

.paymentRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.validateMessage {
  color: $webInputError;

  &.isHeight {
    height: 32px;
  }
}

.yen {
  margin-right: 40px;
}

.loader {
  top: 75px;
  position: relative;
  left: calc(50% - 16px);
}

.loaderContainer {
  height: 150px;
  width: 100%;
}

::placeholder {
  text-align: left;
}
