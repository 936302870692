@import '~shared-modules/styles/constants';

.pageWrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: $webWhite;
  background-color: $webBackgroundBlack;
}

.chartWrapper {
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: calc(50% - 37px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.buttonInnerStyle {
  margin: 0 16px;
}

.buttonGroup {
  width: 100%;
  margin: 10px auto;
  justify-content: center !important;
}

.buttonInnerIcon {
  position: absolute;
  left: 16px;
  height: 24px;
  background-color: $webWhite;
  border-radius: 50%;
}

.icon {
  color: var(--color-text-primary);
}

.title {
  display: flex;
  flex-direction: column;
  padding: 3px 0;
  margin-right: 56px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.opacityHalf {
  opacity: 0.5;
}

.modal {
  position: fixed;
  top: 130px;
  width: 100%;
  display: none;
  height: 70%;
  overflow: hidden;
  outline: 0;
}

.customDialog {
  display: grid;
  grid-template-columns: repeat(auto-fit, max(20vw, 20px));
  grid-gap: 32px;
  gap: 32px;
  width: 1048px;
  padding: 0 5px;
  margin: 60px auto;
  justify-content: center;
}

.modalContent {
  padding: 10px;
  cursor: pointer;
  border-radius: 1.2rem;
  box-shadow: 8px 8px 4px 3px $webBackgroundBlack;
  background-color: #fff;

  &:hover {
    color: $webWhite !important;
    background-color: $webDarkSkyBlueHover;

    i {
      color: $webWhite !important;
      background-color: $webDarkSkyBlueHover;
    }
  }
}

.recommendSelected {
  color: $webWhite !important;
  background-color: $webDarkSkyBlue;
}

.recommendTitle {
  color: #333;
  font-size: 18px;
}

.recommendSubTitle {
  color: #222;
  font-size: 18px;
  font-weight: bold;
}

.recommendImage {
  width: 100%;
}

.infoIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: $webWarmGrey;
}

.recommendInfoModal {
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 100;
  width: 60%;
  min-width: 576px;
  color: $webWhite;
  background-color: #3d3d3d;
}

.recommendInfoModalCloseIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 16px;
  font-weight: 600;
  color: $webWhite;
  cursor: pointer;
  outline: none;
}

.demoWrapper {
  display: flex;
  justify-content: center;
  padding: 30px 15px;
}

.demoTextWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 24px;
}

.demoImage {
  width: 60%;
  max-width: 525px;
}

.demoTitle {
  font-size: 13px;
  font-weight: bold;
}

.demoDesc {
  width: auto;
  margin-top: 13px;
  font-size: 14px;
}
