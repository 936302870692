.container {
  width: 258px;
  background-color: var(--color-bg-chart);
  border-left: 1px solid var(--color-bg-normal);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 10px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-bg-normal);
  }
}

.label {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-text-lightgray);
}

.value {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.join {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.buySell {
  display: flex;
  align-items: center;
}

.side {
  width: fit-content;
  height: auto;
  padding: 0 2px;
}

.help {
  padding-left: 2px;
}

.evaluationIcon {
  font-size: 13px;
}

.evaluationScore {
  font-size: 12px;
}
