@import 'shared-modules/styles/constants';

.button {
  background-color: #2d2d2d;
  height: 26px;
  margin: 4px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
