@import '~shared-modules/styles/constants';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: default;
  background-color: $webBackgroundBlack;
}

.loader {
  position: absolute;
  color: $webWhite;
}
