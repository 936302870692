.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 18px;
}

.iconButton {
  margin-left: 4px;
  padding: 0;
  height: 18px;
}

.content {
  width: 100%;
  height: 100%;
  padding: 10px 20px 30px;
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-normal);
}
