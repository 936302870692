.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 3;
}

.asterisk {
  margin-right: 8px;
}

.text {
  font-size: 15px;
}
