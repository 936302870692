.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 16px;
  padding-left: 20px;
  cursor: pointer;
  background-color: var(--color-table-contents);
  outline: none;
  box-shadow: inset 0 -1px 0 0 #000;

  &:hover:not(.active),
  &:focus:not(.active) {
    background-color: var(--color-hover);
  }

  &.active {
    background-color: var(--color-hover);
  }

  &.disabled {
    cursor: auto;
    opacity: 0.3;
  }

  .firstFlag {
    width: 36px;
    height: 24px;
    margin-right: 4px;
  }

  .secondFlag {
    width: 36px;
    height: 24px;
    margin-right: 17px;
  }

  .currency {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--color-text-normal);
  }
}
