@import '~shared-modules/styles/constants';

@mixin disabled {
  cursor: default;
  opacity: 0.6;

  &:hover,
  &:focus {
    background-color: #313131;

    &.isLighter:not(.isSelected) {
      background-color: $webGrey;
    }
  }
}

.selector {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 12px;
  font-size: 12px;
  color: #ccc;
  cursor: pointer;
  background-color: #313131;
  outline: none;

  &:focus,
  &:hover {
    background-color: var(--color-hover);
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.isLighter {
    background-color: #313131;

    &:focus,
    &:hover {
      background-color: var(--color-hover);
    }
  }

  &.isNotSelectable {
    @include disabled;
  }

  &.isVisuallyDisabled {
    @include disabled;

    cursor: pointer;
  }

  &.isSelected {
    color: #313131;
    background-color: $webWhite;
    border-radius: 4px;
    font-weight: 600;

    &:focus,
    &:hover {
      background-color: $webWhite;
    }

    &.isBlue {
      color: $webDarkSkyBlue;
      background-color: $webWhite;

      &:focus,
      &:hover {
        color: $webDarkSkyBlue;
        background-color: $webWhite;
      }
    }

    &.isRed {
      color: $webPinkishOrange;
      background-color: $webWhite;

      &:focus,
      &:hover {
        color: $webPinkishOrange;
        background-color: $webWhite;
      }
    }
  }
}
