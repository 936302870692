@import '~shared-modules/styles/constants';

.wrapper {
  display: grid;
  gap: 0;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  height: 48px;
}

.learnTriautoSection {
  display: flex;
  align-items: center;
  border-bottom: 0.001rem solid;
  border-bottom-color: #666;
}

.scrl_btn_area {
  display: flex;
  margin: 0 0 0 auto;
  max-height: 30px;
  align-items: center;
  margin-bottom: 15px;
  height: 30px;
}

.closeButtonArea {
  padding: 18px 0 0 10px;
}

.closeButton {
  height: 100%;
  cursor: pointer;
  padding: 0;
  color: $webWhite;
  font-size: 30px;
  transition: 0.3s;

  &:hover {
    border-color: $webInactiveGrey;
    background-color: inherit;
  }
}

.learnTriautoTitle {
  text-align: left;
  align-items: center;
  padding-top: 5px;

  h2 {
    line-height: 30px;
  }
}

.overflowContainer {
  display: flex;
  position: relative;
  min-height: 70px;
  align-items: center;

  .blankSpace {
    width: 400px;
  }
}

.itemsOverflowWrapper {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  justify-content: left !important; // stylelint-disable-line

  > div > .badge {
    top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrow {
  height: 100%;
  width: 30px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: inline-block;
  padding: 0;
  padding-top: 3px;
  color: $webWhite;
  font-size: 35px;
  transition: 0.3s;

  &:hover {
    border-color: $webInactiveGrey;
    background-color: inherit;
  }

  &.isDisabled {
    cursor: default;
    opacity: 0.5;
    outline: none;
    border-bottom: 2px none;
  }
}

.wrapperTabs {
  margin: 5px -0.5px -0.5px;
  height: 36px;
  display: flex;
}

.stepsContainer {
  min-height: 42px;
  max-height: 42px;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  display: inline-block;
  padding: 0.8rem 2rem;
  font-weight: 300;

  .textBox {
    display: flex;
  }

  .checkBox {
    margin: 0 0 0 auto;
    margin-top: 2px;
  }

  .stepsText {
    margin-top: 2px;
    float: left;
    font-size: 15px;
  }
}

.textArea {
  width: 90%;
  float: left;
  margin-top: 2px;
}

