@import '~shared-modules/styles/constants';

.wrapper {
  font-size: 14px;
  cursor: default;
  transform: unset !important; // stylelint-disable-line
  transition: unset !important; // stylelint-disable-line

  > div {
    background-color: var(--color-table-header);
    border: none;
    border-radius: var(--border-radius-default);
  }

  &.isMobile {
    margin: 0 auto;

    > div {
      border-radius: 5px;
      background-color: $mobileComponentLightGrey;
    }
  }
}

.test {
  z-index: 1000;
}

.header {
  align-items: center;
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  color: $webWhite;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: space-between;
  line-height: 1.5;
  padding: 20px 24px;
  position: relative;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-normal);
  }

  .centeredTitle {
    padding-left: 16px;
    padding-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-normal);
    margin: auto;
  }

  .close {
    color: var(--color-text-normal);
    user-select: none;
    width: 20px;
    height: 30px;
    font-size: 30px;
    margin-right: 4px;
    cursor: pointer;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  &.isMobile {
    padding: 20px 24px 12px;
    justify-content: center;
  }

  > button {
    opacity: 1;
    outline: none;
    width: 24px;

    > span {
      color: $webWhite;
      display: block;
      font-size: 20px;
      font-weight: normal;
      height: 24px;
      line-height: 1;
      text-shadow: none;
    }

    &:hover,
    &:focus {
      background-color: $webSideMenuHover;
    }
  }
}

.body {
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  padding: 0 24px 24px;

  &.withoutPadding {
    padding: 0;
  }

  &.isMobile {
    padding: 0 24px 32px;
    text-align: center;
  }
}

.isTopAligned {
  align-items: flex-start;
}

.width294 {
  max-width: 294px;
  width: 294px;
}

.width360 {
  max-width: 360px;
  width: 360px;
}

.width400 {
  max-width: 400px;
  width: 400px;
}

.width450 {
  max-width: 450px;
  width: 450px;
}

.width512 {
  max-width: 512px;
  width: 512px;
}

.width640 {
  max-width: 640px;
  width: 640px;
}

.width710 {
  max-width: 710px;
  width: 710px;
}


.width960 {
  max-width: 960px;
  width: 960px;
}

.width1024 {
  max-width: 1024px;
  width: 1024px;
}

.width1127 {
  max-width: 1127px;
  width: 1127px;
}

.width83vw_1600 {
  max-width: 1600px;
  width: 83vw;
}

.width400_600 {
  max-width: 600px;
  min-width: 400px;
  width: max-content;
}

.customModalDialog {
  width: max-content;
  max-width: 80%;
}

.backdrop {
  &.backdrop {
    opacity: 0.7;
  }
}

.overlapBd {
  z-index: 1055;

  > .dialog {
    z-index: 1060;
  }
}

.overlayModal {
  opacity: 1;
  z-index: 1055;
}


.errorNotificationBd {
  z-index: 1200;
}

.errorNotificationModal {
  z-index: 1205;
}

.linkButton {
  position: absolute;
  right: 88px;
  width: unset !important;

  &.withoutCloseButton {
    right: 24px;
  }
}
