.tagButton {
  height: 26px;
  width: 110px;
  padding: 5px 0;
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  background-color: var(--color-btn-secondary-normal);
  border: var(--color-btn-secondary-normal) solid 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  cursor: pointer;
  user-select: none;

  &:active {
    background-color: var(--color-btn-secondary-pressed);
    border: var(--color-btn-secondary-pressed) solid 1px;
  }

  &.disabled {
    color: var(--color-text-gray);
    cursor: auto;
    background-color: var(--color-btn-secondary-disabled);
    border: var(--color-btn-secondary-disabled-border) solid 1px;
  }

  &:focus-visible {
    outline: none;
  }

  &:hover:not(.disabled, :active) {
    background-color: var(--color-btn-secondary-pressed);
    border: var(--color-btn-secondary-pressed) solid 1px;
  }

  &:focus:not(.disabled, :active, :hover) {
    border: var(--color-border-focus) solid 1px;
  }
}
