@import '~shared-modules/styles/constants';

.wrapper {
  font-size: 14px;
  line-height: 1.5;
  color: $webWhite;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-text-normal);
}

.infoRow {
  margin-top: 8px;
  white-space: pre-wrap;
}

.setsRow {
  display: flex;
  margin-top: 22px;
}

.dot {
  font-weight: bold;
}

.errorMessage {
  color: $webInputError;
}

.strategiesName {
  font-weight: bold;
  overflow-wrap: break-word;
}

.resultMessage {
  overflow-wrap: break-word;
}

.buttonBlock {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.marginTop {
  margin-top: 46px;
}

.firstButton {
  margin-right: 16px;
}

.iconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  object-fit: cover;
}
