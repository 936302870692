@import '~shared-modules/styles/constants';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 13px;
  color: $webWhite;

  &:first-child {
    margin-top: 8px;
  }
}

.searchArea {
  width: 100%;
  height: 36px;

  input::placeholder {
    color: var(--color-text-normal);
  }
}

.searchButton {
  margin-top: 24px;
}
