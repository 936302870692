@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
  width: max-content;
}

.isDisabled {
  opacity: 0.5;
}

.datePickerWrapper {
  position: relative;
  width: max-content;
  height: 32px;
  padding: 0 32px 0 11px;
  font-size: 13px;
  line-height: 3;
  color: $webWhite;
  background-color: #313131;
  border: 0 none;
  border-radius: 6px;
  outline: none;

  &::placeholder {
    font-size: 11px;
    color: $webWhite;
  }

  &.isLighter {
    background-color: $webGrey;
  }

  &.isTaller {
    height: 40px;
  }

  &.isThin {
    height: 26px;
  }

  &:hover {
    background-color: $webBackgroundGreyHover;

    &.isDisabled {
      background-color: $webBackgroundGrey;
    }

    &.isLighter {
      background-color: $webGreyHover;

      &.isDisabled {
        background-color: $webGrey;
      }
    }
  }

  &:focus {
    padding: 1px 33px 1px 12px;
    background-color: $webBackgroundGrey;
    border: 1px solid $webWhite;
    border-radius: 2px;

    &.isDisabled {
      background-color: $webBackgroundGrey;
    }

    &.isLighter {
      background-color: $webGreyHover;

      &.isDisabled {
        background-color: $webGrey;
      }
    }
  }

  &.isError {
    padding: 0 32px 0 11px;
    border: 2px solid $webInputError;
  }
}

.error {
  margin-top: 4px;
  font-size: 12px;
  color: $webInputError;
}

.tooltip {
  display: block;
  width: 168px;
  word-break: break-all;
  overflow-wrap: break-word;
}

.wrapper .popper {
  z-index: 10;
  width: 288px;
  height: 340px;
  margin: 1px 0 0;
  color: $webNotificationText;
  background-color: $webBackgroundGrey;

  &.isLighter {
    background-color: $webGrey;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 14px;
  color: $webNotificationText;
  background-color: $webBackgroundGrey;

  &.isLighter {
    background-color: $webGrey;
  }
}

.calendar {
  display: flex;
  width: 288px;
  height: 340px;
  margin: 0;
  color: $webNotificationText;
  background-color: $webBackgroundGrey;
  border: none;

  &.isLighter {
    background-color: $webGrey;

    > div > div > div {
      background-color: $webGrey;
    }
  }
}

.arrowIcon {
  font-size: 16px;
  color: $webWhiteGrey;
  cursor: pointer;
}

.dayWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dayStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  font-size: 14px;
}

.selectedSquare {
  width: 4px;
  height: 0;
}

.calendarIcon {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 16px;
  color: $webNotificationText;
  pointer-events: none;

  &.isTaller {
    top: 12px;
  }

  &.isThin {
    top: 5px;
  }
}
