.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:focus-visible {
    outline: none;
  }
}
