@import '~shared-modules/styles/constants';

.outerWrapper {
  width: auto;

  &.withPips {
    display: flex;
  }
}

.wrapper {
  position: relative;
}

.label {
  color: $webDarkWhite;
  font-size: 12px;
  margin-bottom: 16px;

  &.isDisabled {
    opacity: 0.5;
  }
}

.input {
  background-color: #313131;
  border: 0 none;
  color: $webWhite;
  font-size: 12px;
  height: 40px;
  padding: 0 8px;
  position: relative;
  border-radius: 6px;

  &.isLighter {
    background-color: $webGrey;
    border: 1px solid $webGrey;
  }

  &.isNumberInput {
    border-radius: 2px;
    padding-right: 40px;
    text-align: right;

    &.withPips {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.isSearchInput {
    padding-left: 52px;
  }

  &.isDisabled {
    opacity: 0.5;
  }

  // remove default arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; // stylelint-disable-line
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; // stylelint-disable-line
  }

  &:focus {
    border: 1px solid $webWhite;
    outline: none;
    padding: 0 8px;

    &.isNumberInput {
      padding-right: 40px;
      text-align: right;
    }

    &.isSearchInput {
      padding-left: 52px;
    }
  }

  &.isError {
    border: 2px solid $webInputError;
    padding: 0 8px;

    &.isNumberInput {
      padding-right: 39px;
      text-align: right;
    }

    &.isSearchInput {
      padding-left: 51px;
    }
  }

  &::placeholder {
    color: $webInputPlaceholder;
  }

  &:invalid {
    box-shadow: none;
  }
}

.error {
  color: $webInputError;
  font-size: 12px;
  margin-top: 4px;
}

.controlBlock {
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: 0;
  user-select: none;
  width: 38px;

  &.increment {
    top: 0;

    &.withLabel {
      top: 34px;
    }
  }

  &.decrement {
    top: 20px;

    &.withLabel {
      top: 54px;
    }
  }

  &:hover {
    border-color: $webWhite;
    outline: none;
  }
}

.iconArrow {
  color: $webIconWhite;
  font-size: 19px;

  &.increment {
    margin-top: 5px;
  }

  &.decrement {
    margin-top: -5px;
  }
}

.searchIcon {
  color: $webWhiteGrey;
  font-size: 20px;
  left: 16px;
  position: absolute;
  top: 10px;

  &.withLabel {
    top: 44px;
  }

  &.isDisabled {
    opacity: 0.3;
  }
}

.pipsWrapper {
  align-items: center;
  background-color: $webBackgroundGrey;
  border-bottom-right-radius: 2px;
  border-left: 0.3px solid $webGrey;
  border-top-right-radius: 2px;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 44px;

  &.isLighter {
    background-color: $webGrey;
    border-left: 0.3px solid $webBackgroundGrey;
  }

  &.isDisabled {
    opacity: 0.5;
  }
}

.isEndAligned {
  text-align: end;
}

.tooltip {
  display: block;
  overflow-wrap: break-word;
  width: 168px;
  word-break: break-all;
}
