.leftSmallImg {
  width: 100%;
  aspect-ratio: 16/9;
}

.leftSmallItem {
  width: 100%;
  max-width: 250px;
}

