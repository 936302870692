@import '~shared-modules/styles/constants';

.wrapper {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: $webPureBlack;
  opacity: 0.5;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}
