.wrapper {
  min-height: 100%;
  color: var(--color-white);
  background-color: var(--color-table-header);
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--color-black);
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: var(--color-white);
}

.header {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1.5fr repeat(6, 1fr);
  align-items: center;
  justify-items: center;
  height: 35px;
  font-size: 12px;
  cursor: pointer;
  background-color: var(--color-table-header);
  border-bottom: 1px solid var(--color-black);
}

.headerCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  font-size: 12px;
  user-select: none;

  &.isCentered {
    justify-content: center;
  }
}

.icon {
  position: absolute;
  top: -3px;
  left: -4px;
  font-size: 20px;
  color: #bfbfbf;

  &.down {
    top: unset;
    bottom: -4px;
  }

  &.selected {
    color: #1890ff;
  }
}

.bodyContainer {
  width: 100%;
  height: calc(100vh - 35px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
