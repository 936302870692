@import '~shared-modules/styles/constants';

.pageWrapper {
  align-items: flex-start;
  color: $webWhite;
  display: flex;
  justify-content: flex-start;
  min-height: 100%;
  flex-direction: column;
  padding: 20px 40px;
}

.pageTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 3;
}

