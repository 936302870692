@import '../../common';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 $padding-horizontal;
}

.title {
  padding: 10px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-normal);
}
