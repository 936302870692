$header-height: 80px;
$footer-height: 124px;
$padding: 8px 12px;

.container {
  grid-area: side-menu;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #000;
  border-right: 1px solid #424242;
  color: var(--color-text-normal);
  font-size: 13px;
  height: calc(100vh - var(--app-header-height));
  width: 100%;

  .validationErrorMessage {
    color: var(--color-text-danger);
  }
}

.title {
  height: 41px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 12px;
}

.settings {
  padding: $padding;
  background-color: rgba(#ccc, 0.12);

  .item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 32px;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &:first-child {
      margin-top: 4px;
    }

    &:last-child {
      margin-bottom: 4px;
    }

    .label {
      display: flex;
      height: 20px;
      align-items: center;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      color: var(--color-text-gray);

      .help {
        padding-left: 4px;
      }
    }

    &.right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  padding: $padding;
  height: $header-height;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.right {
      justify-content: flex-end;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.content {
  position: relative;
  height: calc(100% - $header-height - $footer-height);

  // height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.footer {
  height: $footer-height;
  background-color: #2e2e2e;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 50%);

  .buttonGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.marginInfo {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 14px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-text-gray);
}

.price {
  text-align: right;
  min-width: 130px;
  padding: 0 4px;
}

.margin {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.symbol {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: var(--color-text-normal);
}

.helpIndicator1 {
  margin-top: 13px;
  min-height: 156px; // adjust image bottom
  height: calc(40vh - 108px - 40px); // adjust halfmodal bottom
  overflow-y: auto;

  .text {
    font-size: 14px;
  }

  .boldLine {
    font-size: 15px;
    font-weight: 600;
  }
}
