@import '~shared-modules/styles/constants';

.chart {
  position: relative;
  height: 100%;
  background-color: var(--color-bg-chart);
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}
