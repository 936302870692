@import '~shared-modules/styles/constants';

.modalComponent {
  padding: 0 20px 10px;

  .leftComponent {
    margin: 0 auto;
    width: 50%;
    float: left;

    .img {
      width: 440px;
      position: relative;
    }
  }

  .rightComponent {
    width: 50%;
    padding: 0 15px;
    float: right;

    .topTitle {
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: #dcdcdc;
    }

    .sentenceBlock {
      font-size: 16px;
      margin-top: 16px;

      .bold {
        font-weight: 600;
        line-height: 20px;
      }
    }

    .footer {
      display: flex;
      padding: 10px 0;
      justify-content: center;

      .button {
        width: auto;
      }
    }
  }
}
