.wrapper {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  height: 100%;
  background-color: var(--color-table-contents);
}

.toolbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 10px;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: #2d2d2d;
  border: none;
  border-radius: 2px;

  &:disabled {
    background-color: #2d2d2d;
    color: #fff;
    opacity: 0.5;
  }
}

.linkButton {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

.datePicker {
  display: flex;
  padding-right: 30px;
}

.datePickerText {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  line-height: 32px;
}
