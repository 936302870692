@import '~shared-modules/styles/constants';

.wrapper {
  display: flex;
}

.rules {
  margin-left: 30px;
  max-width: 800px;
}

.myPage {
  padding-right: 5px;
  border-radius: 10px;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
  gap: 100px;
}

.buttonIcon {
  margin: auto 10px;
}

.consideration {
  margin-top: 20px;
}

.colTitle {
  align-items: flex-start;
  background-color: var(--color-table-contents);
  width: 40%;
}

.colData {
  align-items: flex-start;
  width: auto;
}

.description {
  margin: auto 30px;
}

.table {
  border-collapse: collapse;
  margin: 20px 15px 20px 0;
  width: 650px;

  * {
    border: 1px solid $webBlack;
    padding: 15px 0 15px 30px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 3;
}

.titleRow {
  display: flex;
  margin: 0 40px;
}

.textRow {
  display: flex;
}

.attentionRow {
  color: $webBrownGrey;
  display: flex;
}
