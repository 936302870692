.container {
  display: flex;
  flex-direction: column;
}

.legends {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.title {
  padding: 0 0 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-text-lightgray);
}
