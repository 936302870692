@import '~shared-modules/styles/constants';

.wrapper {
  margin: 4px 0 8px;
  font-size: 14px;
  line-height: 1.5;
  color: $webWhite;
}

.loader {
  position: relative;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}
