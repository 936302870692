.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 26px;
  padding: 0 12px;
  border: 1px solid #2d2d2d;
  border-radius: 8px;
  background-color: #2d2d2d;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    border-color: var(--color-border-focus);
  }

  &.checked {
    background-color: #f452661a;
    border-color: var(--color-text-primary);

    &:hover,
    &:active,
    &:focus {
      border-color: #d30d26;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  user-select: none;
}

.label {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: var(--color-text-lightgray);

  &.checked {
    font-weight: 600;
    line-height: 16px;
    color: var(--color-text-normal);
  }
}

.checkedIcon {
  path {
    fill: var(--color-text-primary);
  }
}
