.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;

  &.buy {
    color: var(--color-text-ask);
  }

  &.sell {
    color: var(--color-text-bid);
  }
}
