.container {
  padding-top: 180px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 420px);
  gap: 60px;
  justify-content: center;
  align-items: center;
  color: var(--color-text-normal);
  background-color: var(--color-bg-normal);
}
