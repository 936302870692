@import '~shared-modules/styles/constants';

.wrapper {
  position: sticky;
  top: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 200px 1fr 0fr;
  gap: 0 2px;
  align-items: center;
  height: var(--app-header-height);
  cursor: default;
  background-color: $webBlack;
  padding-right: 12px;

  &.withoutSideBar {
    padding-left: 56px;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 140px;
}

.news {
  flex-grow: 1;
  width: auto;
  height: 36px;
  padding-right: 8px;
}
