.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.icon {
  margin: 0;
  padding: 0;
  font-size: 22px;
  color: var(--color-border-normal);
}
