.dateRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 6px;
  font-size: 12px;
  border-bottom: 1px solid var(--color-black);
  height: 40px;
}

.date {
  padding-left: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.5;
}

.buttons {
  display: flex;
}

.itemWrapper {
  position: relative;
  background-color: #2b2b2b;
  border-bottom: 1px solid var(--color-black);
  padding: 10px;
}

.itemHeader {
  position: relative;
  padding: 0 12px;
  background-color: #2b2b2b;
  border-bottom: 1px solid var(--color-black);
  height: 40px;
  align-items: center;
}

.nameRow {
  display: flex;
  align-items: center;
}

.currencyIcon {
  margin-right: 8px;
}

.image {
  margin-right: 8px;
}

.itemName {
  -webkit-box-orient: vertical;
  display: -webkit-box; // stylelint-disable-line
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  line-height: 1.5;
  max-height: 35px;
  overflow: hidden;
  width: 250px;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
}

.countRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 10px;
}

.countLabel {
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  padding-left: 50px;
}

.noMaxHeight {
  max-height: none;
}

.groupTitle {
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--color-white);
}

.addButton {
  height: 24px;
  width: 100px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  color: var(--color-white);
}

.deleteButton {
  padding-left: 6px;
}

.countSet {
  background-color: #363636;
  text-align: center;
  border-radius: 4px;
  height: 35px;
  width: 112px;
  display: flex;
}

.inputRow {
  background-color: #363636;
  height: 35px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 8px;
}

.tag:not(:last-child) {
  padding-right: 6px;
}
