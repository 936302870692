.modal {
  cursor: default;
  max-height: 640px;
  min-height: 250px;

  >div {
    background-color: var(--color-table-header);
    border: none;
    border-radius: var(--border-radius-default);
  }
}

.backdrop {
  &.backdrop {
    opacity: 0.7;
    z-index: 1100;
  }
}

.left {
  margin-left: 10vh;
  margin-right: auto;
}

.right {
  margin-left: auto;
  margin-right: 10vh;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.top {
  margin-top: 10vh;
  margin-bottom: auto;
}

.middle {
  margin-top: 33vh;
  margin-bottom: auto;
}

.step16 {
  margin-top: calc((100vh - 640px) / 2);
  margin-bottom: auto;
}

.bottom {
  margin-top: 60vh;
  margin-bottom: auto;
}

.header {
  padding: 20px 24px 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .close {
    color: var(--color-text-normal);
    user-select: none;
    width: 20px;
    height: 30px;
    font-size: 30px;
    margin-right: 4px;

    &:focus-visible {
      outline: none;
    }

    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.body {
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
  min-width: 500px;
  min-height: 200px;
  padding: 10px;
  color: var(--color-text-normal);
  overflow: hidden;

  .container {
    margin: 15px 20px;
  }

  .content {
    position: relative;

    .cropContainer {
      position: relative;
      min-height: 384px;
    }

    .zoomContainer {
      padding: 10px 0;

      >* input {
        width: 60%;
      }
    }
  }

  .wrap {
    background-color: #000;
    margin-left: 8px;
    padding: 1px;
  }

  .flag {
    width: 24px;
    height: 16px;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  .instrumentId {
    font-size: 10px;
    font-weight: 600;
  }

  .title {
    padding-bottom: 10px;
    margin-bottom: 6px;
    min-height: 30px;
    font-weight: 600;
    line-height: 20px;
    align-items: center;
    font-size: 16px;
    color: #fff;
  }

  .message {
    font-size: 13px;
    font-weight: 300;
    color: #dcdcdc;
    margin-bottom: 6px;
    min-height: 70px;
    white-space: pre-wrap;
    line-height: 20px;
  }

  .center {
    text-align: center;
  }

  .bold {
    font-weight: 400;
    line-height: 20px;
  }

  .row {
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }

  .footer {
    display: flex;
    padding: 10px 0;
    justify-content: flex-end;

    >button {
      margin: 0 8px;
    }

    .endButton {
      width: auto;
    }
  }

  .recomendImgContainer {
    display: flex;
    justify-content: center;
    margin: 12px 0;
  }

  .recomendImg {
    width: 332px;
    height: 442px;
  }

  .lastFooter {
    display: flex;
    padding: 10px 0;
    justify-content: center;

    >button {
      margin: 0 8px;
    }

    .endButton {
      width: auto;
    }
  }

  .interrupt {
    padding: 10px 0;
    position: absolute;
    bottom: -40px;
    right: 0;
    font-weight: 300;
    font-size: 12px;
    color: #949393;

    >button {
      margin: 0 8px;
    }
  }

  .step {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0 auto;

    >div {
      padding: 0 10px;
    }
  }
}
