@import '~shared-modules/styles/constants';

.wrapper {
  font-size: 13px;
  color: $webWhite;
}

.tittleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.checkboxContainer {
  width: 100%;
  height: 330px;
  margin-top: 9px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  margin-top: 8px;
  cursor: pointer;

  &:first-child {
    margin-top: unset;
  }

  &:hover {
    background-color: $webBackgroundGrey;
  }
}

.labelClassName {
  margin-left: 24px;
  font-size: 13px;
}

.button {
  margin-top: 16px;
}
