@import '~shared-modules/styles/constants';

.wrapper {
  z-index: 2;
  grid-area: table;
}

.tableWrapper {
  height: 238px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 $webShadowColor;
}

.underlineTabsWrapper {
  height: 48px;
  background-color: $webBackgroundGrey;
}

.table {
  height: 238px;
}

.divider {
  height: 2px;
  background-color: $webDarkThree;
  border-radius: 0.5px;
}
