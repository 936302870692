$footer-area-height: 60px;

.container {
  display: grid;
  grid-template-areas: 'side-menu detail';
  grid-template-columns: 400px 1fr;
  height: 100%;
  color: var(--color-text-normal);
  background-color: var(--color-bg-normal);
}

.detail {
  grid-area: detail;
  position: relative;
  height: 100%;
  width: 100%;
}

.content {
  position: relative;
  height: calc(100% - $footer-area-height);
  width: 100%;
}

.footer {
  height: $footer-area-height;
  width: 100%;
}
