@import '~shared-modules/styles/constants';

.container {
  padding: 32px 24px;
  background-color: rgba(0, 0, 0, 20%);
}

.titleRow {
  display: flex;
  height: 40px;
  margin-top: 24px;
  font-size: 11px;
  color: $webWhite;
  background-color: #313131;
}

.dateCell {
  display: flex;
  align-items: center;
  width: 200px;
  padding: 0 16px;
}

.categoriesCell {
  display: flex;
  align-items: center;
  width: 128px;
  padding: 0 16px;
}

.newsTitleCell {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 16px;
}

.accordionWrapper {
  max-height: 435px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.accordionTitle {
  display: flex;
  font-size: 12px;
  color: $webWhite;
}

.accordionDate {
  display: flex;
  align-items: center;
  width: 200px;
  line-height: 1.27;
}

.accordionCategories {
  display: flex;
  align-items: center;
  width: 128px;
  line-height: 1.27;
}

.accordionNewsTitle {
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: bold;
  line-height: 1.27;
}

.accordionBody {
  font-size: 12px;
  line-height: 2;
  color: $webWhite;
}

.emptyBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: $webWhite;
  background: $webBackgroundBlack;
}

.controlBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.refreshButton {
  padding: 0;
  margin-left: 24px;
  font-weight: normal;
  border: 1px solid #8c8787;

  .icon {
    padding-right: 5px;
    font-size: 20px;
    color: $webIconWhite;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}

.copyrightBtnWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;
}

.tabs {
  background-color: var(--color-table-header);
}
