@import '~shared-modules/styles/constants';

@mixin row($margin-top: 16px) {
  display: flex;
  align-items: center;
  min-height: 24px;
  margin-top: $margin-top;
  color: $webWhite;
}

@mixin inputColumn($margin-left: 24px) {
  >div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: $margin-left;
  }
}

.wrapper {
  font-size: 13px;
  color: $webWhite;

  &.isSettingsLoading {
    opacity: 0.5;
  }
}

%text {
  font-weight: bold;
}

.descriptionRow {
  @extend %text;

  margin-left: 12px;
}

.checkboxDescriptionRow {
  @extend %text;
  @include row;
}

.quantityRow {
  @include row(12px);
  @include inputColumn;
}

.profitRow {
  @include row(12px);
  @include inputColumn;
}

.losscutRow {
  @include row(12px);
  @include inputColumn;
}

.followRow {
  @include row(12px);
  @include inputColumn;
}

.counterRow {
  @include row(12px);
  @include inputColumn;
}

.counterFixedRow {
  @include row(12px);
  @include inputColumn(48px);
}

.columnTitle {
  display: flex;
}

.infoWrapper {
  margin-left: 6px;
}

.infoIcon {
  font-size: 17px;
  color: $webWhite;
}

.infoText {
  text-align: left;
  white-space: pre-line;
}

.inputChanged {
  background-color: $webDarkOrange;
}

.buttonRow {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 22px;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.modalBackgroundColor {
  background-color: var(--color-table-header);
}
