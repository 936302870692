@import '../../common';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 $padding-horizontal 8px $padding-horizontal;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: #000;
}

.chartArea {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100% - 45px);
  background-color: var(--color-bg-chart);
}

.chart {
  position: relative;
  overflow: hidden;
  width: calc(100% - 264px);
  height: 100%;
  padding-bottom: 10px;
}

.info {
  position: relative;
  width: 264px;
  height: 100%;
  overflow: auto;
  background-color: var(--color-bg-normal);

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: var(--color-white);
}

.termSelectTitle {
  padding-left: 8px;
  min-width: 150px;
}
