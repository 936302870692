@import '~shared-modules/styles/constants';

.table {
  position: relative;
  grid-area: table;
  height: 293px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 $webShadowColor;
}

.filterIcon {
  cursor: pointer;
}

.loader {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: $webWhite;
}

.tableContainer {
  height: 100%;
}

.tabs {
  background-color: var(--color-table-header);
}

.tableWrapper {
  width: 100%;
  height: 100%;
}
