.button {
  position: relative;
  min-width: 120px;
  height: 26px;
  padding: 0 12px;
  overflow: hidden;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #8c8787;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: #2d2d2d;
  border: #2d2d2d solid 1px;
  border-radius: 8px;
  user-select: none;

  &:focus-visible {
    outline: none;
  }

  &.disabled {
    color: var(--color-text-disabled-dark);
    cursor: auto;
    background-color: var(--color-btn-secondary-disabled-dark);
    border: var(--color-btn-secondary-disabled-border) solid 1px;
  }

  &.active:not(.disabled) {
    background-color: #585858;
    border: #585858 solid 1px;
    color: var(--color-white);

    &:hover {
      border: #585858 solid 1px;
    }
  }

  &:focus:not(.disabled),
  &:hover:not(.disabled) {
    color: var(--color-white);
    border: #2d2d2d solid 1px;
  }

  &:focus:not(.disabled, :active, :hover) {
    border: var(--color-border-focus) solid 1px;
  }
}

.badgeContainer {
  position: relative;
  height: 100%;
  user-select: none;

  .badge {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1px;
    border-radius: 8px;
    padding: 0 6px;
    height: 12px;
    background-color: var(--color-badge-background);
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    color: var(--color-text-normal);
  }
}
