.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
}

.maintenance {
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-normal);
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tagContainer {
  padding: 8px;
}

.marginArea {
  padding: 0 6px 20px;
}

.detail {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.linkArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 6px;
  width: 100%;
}

.hideMarginInfo {
  height: 100%;
}

.forwardLink {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
