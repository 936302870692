@import '~shared-modules/styles/constants';

// stylelint-disable
.switch {
  opacity: 1 !important;

  > :nth-child(2) {
    box-shadow: none !important;
  }

  > :first-child {
    background: inherit !important;
    box-shadow: 0 0 0 2px $webGrey;

    &:hover {
      box-shadow: 0 0 0 2px $webGreenHover;
    }
  }

  &.isDisabled {
    opacity: 0.6 !important;

    > :first-child:hover {
      box-shadow: 0 0 0 2px $webGrey;
    }
  }

  &.isChecked > :first-child {
    background: $webGreen !important;
    box-shadow: none;

    &:hover {
      background: $webGreenHover !important;
    }
  }

  &.isCheckedAndDisabled > :first-child {
    opacity: 1 !important;
    background: $webGreen !important;
    box-shadow: none;

    &:hover {
      background: $webGreen !important;
      box-shadow: none;
    }
  }
}
// stylelint-enable

// stylelint-disable
.switchWrapper {
  align-items: baseline;
  display: flex;
  outline: none;
  width: max-content;

  &:focus > .switch {
    > :first-child {
      box-shadow: 0 0 0 2px $webGreenHover;
    }

    &.isChecked > :first-child {
      background: $webGreenHover !important;
      box-shadow: none;
    }

    &.isDisabled > :first-child {
      background: inherit !important;
      box-shadow: 0 0 0 2px $webGrey;
    }

    &.isCheckedAndDisabled > :first-child {
      box-shadow: none;
      background: $webGreen !important;
      opacity: 1 !important;
    }
  }
}
// stylelint-enable
