@import '~shared-modules/styles/constants';

.modal {
  position: absolute;
  top: calc(100% - 10px);
  left: calc(100% - 290px);
  width: 232px;
  height: 231px;
  padding: 12px 16px;
  color: #ffff;
  text-align: left;
  font-size: 12px;
  line-height: 24px;
  cursor: default;
  border: 1px solid #424242;
  background-color: #1d1d1d;
  font-weight: 300;
  font-style: normal;
}

.dropDownIcon {
  display: flex;
  justify-content: center;
  font-size: 22px;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  border: 1px solid #ffff;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    background-color: #ffff;
    color: #273138;
  }
}

.title {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  width: fit-content;
}

.modalTitle {
  border-bottom: 1px solid $webBackgroundGrey;
  margin-bottom: 10px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 300;
}

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 7px 8px;
  align-items: center;
  justify-self: flex-end;
  font-weight: 300;
  font-size: 12px;
  color: #dcdcdc;
  width: 580px;
  height: 36px;
  line-height: 21px;
}

.pairsWrapper {
  gap: 7px 15px;
  display: grid;
  grid-template-columns: 0fr minmax(128px, 0.8fr) 1fr;
  grid-gap: 20px;
  grid-template-areas: 'currency pair-6 pair-5';
  border-right: 1px solid  #424242;
  border-left: 1px solid  #424242;
  height: 36px;
  align-items: center;
}

.currency {
  grid-area: currency;
  width: 48px;
  height: 24px;
  line-height: 2;
  text-align: center;
  text-transform: uppercase;
  background-color: #575757;
  border-radius: 8px;
  font-size: 11px;
  font-weight: bold;
}

.pair {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 0 5px;
  justify-content: space-between;
}

.pairValue {
  font-family: var(--font-family-ascii);
  font-size: 12px;
  font-weight: 800;
}
