.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  height: 36px;
  width: 234px;
}

.link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  appearance: none;
}

.text {
  font-family: var(--font-family-default);
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: var(--color-white);
  text-decoration-line: underline;
  text-underline-offset: 2px;
}
